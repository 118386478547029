export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: string; output: string; }
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: string; output: string; }
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: string | number; output: string | number; }
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any; }
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: { input: string; output: string; }
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: { input: string; output: string; }
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: { input: any; output: any; }
};

export type ApiTokenType = {
  __typename?: 'APITokenType';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  rolesCompany: Array<RoleCompanyType>;
  rolesCorporate: Array<RoleCorporateType>;
  rolesCustomer: Array<RoleCustomerType>;
};

export type AtcCodesInfo = {
  __typename?: 'ATCCodesInfo';
  atcs: Array<Maybe<AtcType>>;
  codeSystem?: Maybe<CodeSystemType>;
};

/** Value belongs to codesystem 1.2.246.537.6.32 values. */
export type AtcType = {
  __typename?: 'ATCType';
  code: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type AcknowledgmentType = {
  __typename?: 'AcknowledgmentType';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  id: Scalars['ID']['output'];
  type: AcknowledgmentsAcknowledgmentTypeChoices;
  user: UserType;
};

/** An enumeration. */
export enum AcknowledgmentTypeChoices {
  Custom = 'CUSTOM',
  EmployeeInformation = 'EMPLOYEE_INFORMATION',
  EmployeeInsurances = 'EMPLOYEE_INSURANCES',
  EmployeeList = 'EMPLOYEE_LIST',
  OrganizationInsurances = 'ORGANIZATION_INSURANCES',
  ServiceSettings = 'SERVICE_SETTINGS'
}

/** An enumeration. */
export enum AcknowledgmentsAcknowledgmentTypeChoices {
  /** Custom */
  Custom = 'CUSTOM',
  /** Employee Information */
  EmployeeInformation = 'EMPLOYEE_INFORMATION',
  /** Employee Insurances */
  EmployeeInsurances = 'EMPLOYEE_INSURANCES',
  /** Employee List */
  EmployeeList = 'EMPLOYEE_LIST',
  /** Organization Insurances */
  OrganizationInsurances = 'ORGANIZATION_INSURANCES',
  /** Service Settings */
  ServiceSettings = 'SERVICE_SETTINGS'
}

export type ActiveIngredientType = {
  __typename?: 'ActiveIngredientType';
  atcCode?: Maybe<Scalars['String']['output']>;
  ingredientNumber?: Maybe<Scalars['Int']['output']>;
  intensity?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

/** Value belongs to codesystem 1.2.246.537.5.40300 values. */
export type ActiveStatus = {
  __typename?: 'ActiveStatus';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
};

export type AddCampaignSurveyRespondent = {
  __typename?: 'AddCampaignSurveyRespondent';
  campaign?: Maybe<CampaignType>;
};

export type AddHealthCheck = {
  __typename?: 'AddHealthCheck';
  healthCheck?: Maybe<HealthCheckType>;
};

export type AddMultiSignatureValueToPrescriptions = {
  __typename?: 'AddMultiSignatureValueToPrescriptions';
  /** List of signed prescription oid codes. */
  signedPrescriptionOidCodes: Array<Maybe<Scalars['String']['output']>>;
};

export type AddNorrePackageInterface = {
  norrePackage: NorrePackageType;
};

export type AddPatient = {
  __typename?: 'AddPatient';
  email?: Maybe<Scalars['String']['output']>;
};

export type AddSignatureValueToPrescription = {
  __typename?: 'AddSignatureValueToPrescription';
  prescriptionOid: Scalars['String']['output'];
};

export type AddSurveyAnswer = {
  __typename?: 'AddSurveyAnswer';
  treeResult: TreeResultType;
};

export type AddUserEmploymentInformation = {
  __typename?: 'AddUserEmploymentInformation';
  userEmploymentInformation?: Maybe<UserEmploymentInformationType>;
};

export type AddUserInformationViewReasonLog = {
  __typename?: 'AddUserInformationViewReasonLog';
  logEntry?: Maybe<UserInformationViewLogType>;
};

export type ApproveInvoicePurchases = {
  __typename?: 'ApproveInvoicePurchases';
  invoicePurchases?: Maybe<Array<Maybe<InvoicePurchaseType>>>;
};

/** Value belongs to codesystem 1.2.246.537.5.40301 values. */
export type ArchiveStatus = {
  __typename?: 'ArchiveStatus';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
};

/** An enumeration. */
export enum AssignedAmountRepresentation {
  PackageRepresentation = 'PACKAGE_REPRESENTATION',
  TimeRepresentation = 'TIME_REPRESENTATION',
  TotalAmountRepresentation = 'TOTAL_AMOUNT_REPRESENTATION'
}

/** Value belongs to codesystem 1.2.246.537.5.40006 values. */
export type AuthorDefinerType = {
  __typename?: 'AuthorDefinerType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type AuthorGeneralInfo = {
  __typename?: 'AuthorGeneralInfo';
  familyNames: Array<Maybe<Scalars['String']['output']>>;
  firstNames: Array<Maybe<Scalars['String']['output']>>;
  svNumber?: Maybe<Scalars['String']['output']>;
};

export type AvailabilityFailure = {
  __typename?: 'AvailabilityFailure';
  fromDate: Scalars['Date']['output'];
  toDate: Scalars['Date']['output'];
};

/** An enumeration. */
export enum BranchBranchTypeChoices {
  Basic = 'BASIC',
  Condition = 'CONDITION',
  Link = 'LINK',
  Selection = 'SELECTION'
}

export type BranchType = {
  __typename?: 'BranchType';
  branchType: BranchesBranchBranchTypeChoices;
  condition?: Maybe<ConditionType>;
  conditionFormula: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  nextNode: NodeType;
  previousNode: NodeType;
  shorthand: Scalars['String']['output'];
  tag: Scalars['String']['output'];
};

/** An enumeration. */
export enum BranchesBranchBranchTypeChoices {
  /** Basic */
  Basic = 'BASIC',
  /** Condition */
  Condition = 'CONDITION',
  /** Link */
  Link = 'LINK',
  /** Selection */
  Selection = 'SELECTION'
}

export type BulkCreateUsers = {
  __typename?: 'BulkCreateUsers';
  results: Array<UserOrError>;
};

/** Value belongs to codesystem 1.2.246.537.6.12 values. */
export type CdaViewType = {
  __typename?: 'CDAViewType';
  OID?: Maybe<Scalars['String']['output']>;
  abbreviation: Scalars['String']['output'];
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
};

export type CampaignSurveyType = {
  __typename?: 'CampaignSurveyType';
  /** Users with completed answers */
  answeredBy?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  campaign: CampaignType;
  id: Scalars['ID']['output'];
  /** Users who will not have an answer to this survey */
  notAnsweredBy?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  respondents: Array<UserType>;
  survey: TreeType;
};

export type CampaignType = {
  __typename?: 'CampaignType';
  company?: Maybe<CompanyType>;
  createdAt: Scalars['DateTime']['output'];
  documentTemplates: Array<DocumentTemplateType>;
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  surveys: Array<CampaignSurveyType>;
  user?: Maybe<UserType>;
};

export type CancelLaboratoryOrder = {
  __typename?: 'CancelLaboratoryOrder';
  laboratoryOrder?: Maybe<LaboratoryOrderType>;
};

/** Value belongs to codesystem 1.2.246.537.6.13 values. */
export type CareStageType = {
  __typename?: 'CareStageType';
  OID: Scalars['String']['output'];
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Value belongs to codesystem 1.2.246.537.5.40101 values. */
export type CaringType = {
  __typename?: 'CaringType';
  OID?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type ChangeLaboratoryOrder = {
  __typename?: 'ChangeLaboratoryOrder';
  laboratoryOrder?: Maybe<LaboratoryOrderType>;
};

export type CodeSystemType = {
  __typename?: 'CodeSystemType';
  classificationIdentifier?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
  regLevel?: Maybe<Scalars['Int']['output']>;
  validFrom?: Maybe<Scalars['Date']['output']>;
  validTo?: Maybe<Scalars['Date']['output']>;
  versionNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyAddressType = {
  __typename?: 'CompanyAddressType';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: CompanyType;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  useremploymentinformationSet: Array<UserEmploymentInformationType>;
};

export type CompanyType = {
  __typename?: 'CompanyType';
  addresses: Array<CompanyAddressType>;
  businessId: Scalars['String']['output'];
  campaigns: Array<CampaignType>;
  companyRoles: Array<RoleCompanyType>;
  createdAt: Scalars['DateTime']['output'];
  customerSet: Array<CustomerType>;
  documentcategorySet: Array<DocumentCategoryType>;
  healthcareteams: Array<HealthcareTeamType>;
  id: Scalars['ID']['output'];
  invoiceinterface?: Maybe<InvoiceInterfaceType>;
  invoicepurchaseSet: Array<InvoicePurchaseType>;
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  profileSet: Array<ProfileType>;
  usersCompany: Array<UserType>;
};

export type ConditionType = {
  __typename?: 'ConditionType';
  branch?: Maybe<BranchType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  statementGroups: Array<StatementGroupType>;
};

/** Value belongs to codesystem 1.2.246.537.5.40119 values. */
export type ConsentType = {
  __typename?: 'ConsentType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type ContactPersonInputType = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactPersonType = {
  __typename?: 'ContactPersonType';
  contactType: OrganizationsContactPersonContactTypeChoices;
  contactUser?: Maybe<UserType>;
  customer: CustomerType;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type ContactPersonsInputType = {
  contract: ContactPersonInputType;
  invoicing: ContactPersonInputType;
  occupationalHealth: ContactPersonInputType;
};

export type ContractBaseInfoInputType = {
  discounts: DiscountsInputType;
  extraServices: Array<SalesExtraServiceProductChoices>;
  isLegacyContract?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfPersonsExposure?: InputMaybe<Scalars['Int']['input']>;
  numberOfPersonsNonExposure?: InputMaybe<Scalars['Int']['input']>;
  prices: ContractPricesInputType;
  receptionType?: InputMaybe<ReceptionChoices>;
  salesPackageLevel: SalesPackageLevelChoices;
  salesmanEmail: Scalars['String']['input'];
  signedAt: Scalars['DateTime']['input'];
  validFrom: Scalars['Date']['input'];
};

export type ContractPricesInputType = {
  extraPriceExposure?: InputMaybe<Scalars['Decimal']['input']>;
  firstHealthCheckPrice?: InputMaybe<Scalars['Decimal']['input']>;
  initialCostExtraServicesPerCompany?: InputMaybe<Scalars['Decimal']['input']>;
  initialCostExtraServicesPerPerson?: InputMaybe<Scalars['Decimal']['input']>;
  initialCostPerCompany?: InputMaybe<Scalars['Decimal']['input']>;
  initialCostPerOffice?: InputMaybe<Scalars['Decimal']['input']>;
  initialCostPerPerson?: InputMaybe<Scalars['Decimal']['input']>;
  numberOfOffices?: InputMaybe<Scalars['Int']['input']>;
};

export type ContractTerminationReasonType = {
  __typename?: 'ContractTerminationReasonType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  salescontractSet: Array<SalesContractType>;
};

export type CostSettingsInputType = {
  accidentSpecificDeductible?: InputMaybe<Scalars['Decimal']['input']>;
  accidentSpecificExpenseCeiling?: InputMaybe<Scalars['Decimal']['input']>;
  customerServiceSettingId?: InputMaybe<Scalars['ID']['input']>;
  yearlyExpenseCeilingPerEmployee?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CostSettingsType = {
  __typename?: 'CostSettingsType';
  accidentSpecificDeductible: Scalars['Decimal']['output'];
  accidentSpecificExpenseCeiling: Scalars['Decimal']['output'];
  customerServiceSetting: CustomerServiceSettingsType;
  yearlyExpenseCeilingPerEmployee: Scalars['Decimal']['output'];
};

export type CreateAcknowledgment = {
  __typename?: 'CreateAcknowledgment';
  acknowledgment: AcknowledgmentType;
};

export type CreateAndSendLaboratoryOrder = {
  __typename?: 'CreateAndSendLaboratoryOrder';
  laboratoryOrder?: Maybe<LaboratoryOrderType>;
};

export type CreateApiToken = {
  __typename?: 'CreateApiToken';
  token: Scalars['String']['output'];
  tokenObject: ApiTokenType;
};

export type CreateBranch = {
  __typename?: 'CreateBranch';
  branch?: Maybe<BranchType>;
};

export type CreateCampaign = {
  __typename?: 'CreateCampaign';
  campaign?: Maybe<CampaignType>;
};

export type CreateChoiceBranch = {
  __typename?: 'CreateChoiceBranch';
  branch?: Maybe<BranchType>;
};

export type CreateCompanyAndSetAddresses = {
  __typename?: 'CreateCompanyAndSetAddresses';
  company?: Maybe<CompanyType>;
};

export type CreateCustomer = {
  __typename?: 'CreateCustomer';
  customer?: Maybe<CustomerType>;
};

export type CreateCustomerAddress = {
  __typename?: 'CreateCustomerAddress';
  address: CustomerAddressType;
};

export type CreateCustomerWithContract = {
  __typename?: 'CreateCustomerWithContract';
  contract: SalesContractType;
};

export type CreateDocument = {
  __typename?: 'CreateDocument';
  document?: Maybe<DocumentType>;
};

export type CreateDocumentCategory = {
  __typename?: 'CreateDocumentCategory';
  documentCategory?: Maybe<DocumentCategoryType>;
};

export type CreateDocumentTemplate = {
  __typename?: 'CreateDocumentTemplate';
  template?: Maybe<DocumentTemplateType>;
};

export type CreateDocumentTemplateItem = {
  __typename?: 'CreateDocumentTemplateItem';
  item?: Maybe<DocumentTemplateItemType>;
};

export type CreateDocumentVersion = {
  __typename?: 'CreateDocumentVersion';
  documentVersion?: Maybe<DocumentVersionType>;
};

export type CreateEmptyLaboratoryCatalog = {
  __typename?: 'CreateEmptyLaboratoryCatalog';
  laboratoryCatalog: LaboratoryCatalogType;
};

export type CreateHealthcareTeam = {
  __typename?: 'CreateHealthcareTeam';
  healthcareTeam?: Maybe<HealthcareTeamType>;
};

export type CreateInsuranceCompany = {
  __typename?: 'CreateInsuranceCompany';
  insuranceCompany: InsuranceCompanyType;
};

export type CreateInvoiceInterface = {
  __typename?: 'CreateInvoiceInterface';
  invoiceInterface?: Maybe<InvoiceInterfaceType>;
};

export type CreateInvoicePurchaseComment = {
  __typename?: 'CreateInvoicePurchaseComment';
  invoicePurchaseComment?: Maybe<InvoicePurchaseCommentType>;
};

export type CreateInvoicesSale = {
  __typename?: 'CreateInvoicesSale';
  invoicesSale?: Maybe<Array<Maybe<InvoiceSaleType>>>;
};

export type CreateLaboratoryCatalogBasedOnExisting = {
  __typename?: 'CreateLaboratoryCatalogBasedOnExisting';
  laboratoryCatalog: LaboratoryCatalogType;
};

export type CreateLaboratorySupplier = {
  __typename?: 'CreateLaboratorySupplier';
  laboratorySupplier?: Maybe<LaboratorySupplierType>;
};

export type CreateNorrePackage = {
  __typename?: 'CreateNorrePackage';
  norrePackage?: Maybe<NorrePackageType>;
};

export type CreateOrUpdateLaboratorySalesPrice = {
  __typename?: 'CreateOrUpdateLaboratorySalesPrice';
  laboratoryCatalogSalesPrice?: Maybe<LaboratoryCatalogSalesPriceType>;
};

export type CreateOrganization = {
  __typename?: 'CreateOrganization';
  businessId?: Maybe<Scalars['String']['output']>;
};

export type CreatePartner = {
  __typename?: 'CreatePartner';
  partner?: Maybe<PartnerType>;
};

export type CreatePaymentCommitment = {
  __typename?: 'CreatePaymentCommitment';
  paymentCommitment?: Maybe<PaymentCommitmentType>;
};

export type CreatePrescription = {
  __typename?: 'CreatePrescription';
  /** Unique identifier of the created prescription. */
  prescriptionOid?: Maybe<Scalars['String']['output']>;
};

export type CreateProcedure = {
  __typename?: 'CreateProcedure';
  procedure?: Maybe<ProcedureType>;
};

export type CreateProcedurePartnerPrice = {
  __typename?: 'CreateProcedurePartnerPrice';
  procedurePartnerPrice?: Maybe<ProcedurePartnerPriceType>;
};

export type CreateProfessionalGroup = {
  __typename?: 'CreateProfessionalGroup';
  professionalGroup?: Maybe<ProfessionalGroupType>;
};

export type CreateProfile = {
  __typename?: 'CreateProfile';
  profile?: Maybe<ProfileType>;
};

export type CreateQuestion = {
  __typename?: 'CreateQuestion';
  question: QuestionType;
};

export type CreateRoleCompany = {
  __typename?: 'CreateRoleCompany';
  roleCompany?: Maybe<RoleCompanyType>;
};

export type CreateRoleCorporate = {
  __typename?: 'CreateRoleCorporate';
  roleCorporate?: Maybe<RoleCorporateType>;
};

export type CreateRoleInfoInputType = {
  orgId: Scalars['ID']['input'];
  profileId: Scalars['ID']['input'];
};

export type CreateSalesCatalog = {
  __typename?: 'CreateSalesCatalog';
  salesCatalog: SalesCatalogType;
};

export type CreateSalesContractVersion = {
  __typename?: 'CreateSalesContractVersion';
  salesContractVersion: SalesContractVersionType;
};

export type CreateServiceEvent = {
  __typename?: 'CreateServiceEvent';
  serviceEvent?: Maybe<ServiceEvent>;
};

export type CreateSickLeaveCategory = {
  __typename?: 'CreateSickLeaveCategory';
  sickLeaveCategory?: Maybe<SickLeaveCategoryType>;
};

export type CreateSickLeaveNotice = {
  __typename?: 'CreateSickLeaveNotice';
  sickLeaveNotice?: Maybe<SickLeaveNoticeType>;
};

export type CreateSickLeavePeriodSetting = {
  __typename?: 'CreateSickLeavePeriodSetting';
  sickLeavePeriodSetting?: Maybe<SickLeavePeriodSettingType>;
};

export type CreateSickLeaveSelfReportReason = {
  __typename?: 'CreateSickLeaveSelfReportReason';
  sickLeaveSelfReportReason?: Maybe<SickLeaveSelfReportReasonType>;
};

export type CreateSupplierPackage = {
  __typename?: 'CreateSupplierPackage';
  supplierPackage?: Maybe<SupplierPackageType>;
};

export type CreateTask = {
  __typename?: 'CreateTask';
  task: TaskType;
};

export type CreateTaskTopic = {
  __typename?: 'CreateTaskTopic';
  taskTopic: TaskTopicType;
};

export type CreateTitleTemplate = {
  __typename?: 'CreateTitleTemplate';
  titleTemplate?: Maybe<TitleTemplateType>;
};

export type CreateTree = {
  __typename?: 'CreateTree';
  tree: TreeType;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  user: UserType;
};

export type CreateView = {
  __typename?: 'CreateView';
  view: ViewType;
};

export type CustomerAddressType = {
  __typename?: 'CustomerAddressType';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerType;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  useremploymentinformationSet: Array<UserEmploymentInformationType>;
};

export type CustomerBaseInfoInputType = {
  accountingPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  accountingPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  address: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
  city: Scalars['String']['input'];
  contractContactPerson: ContactPersonInputType;
  invoicing: InvoicingBaseInfoInputType;
  name: Scalars['String']['input'];
  occupationalHealthContactPerson: ContactPersonInputType;
  parentCompanyBusinessId: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
};

/** Value belongs to codesystem 1.2.246.537.6.240 values. */
export type CustomerInformationViewReasonType = {
  __typename?: 'CustomerInformationViewReasonType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
  userinformationviewlogSet: Array<UserInformationViewLogType>;
};

export type CustomerServiceSettingsType = {
  __typename?: 'CustomerServiceSettingsType';
  costSettings: CostSettingsType;
  customer: CustomerType;
  partnerNetworkUsage: PartnerservicesCustomerServiceSettingsPartnerNetworkUsageChoices;
  partnerServiceSettings: Array<PartnerServiceSettingType>;
  useKelaReimbursement: Scalars['Boolean']['output'];
  voluntaryHealthCheck: Scalars['Boolean']['output'];
};

export type CustomerServicesOverview = {
  __typename?: 'CustomerServicesOverview';
  customerId: Scalars['ID']['output'];
  extraServices: Array<SalesExtraServiceType>;
  isLegacyContract?: Maybe<Scalars['Boolean']['output']>;
  legacyServicesDescription?: Maybe<Scalars['String']['output']>;
  legacyServicesName?: Maybe<Scalars['String']['output']>;
  salesPackage?: Maybe<SalesPackageType>;
};

/** An enumeration. */
export enum CustomerTaskStatusChoices {
  Alert = 'ALERT',
  Completed = 'COMPLETED',
  Warning = 'WARNING'
}

export type CustomerTaskType = {
  __typename?: 'CustomerTaskType';
  description: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
  instructionsToComplete: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: CustomerTaskStatusChoices;
};

export type CustomerTasksType = {
  __typename?: 'CustomerTasksType';
  actionPlan: CustomerTaskType;
  customerId: Scalars['ID']['output'];
  employeeInformation: CustomerTaskType;
  employeeInsurances: CustomerTaskType;
  employeeList: CustomerTaskType;
  healthChecks: CustomerTaskType;
  organizationInsurances: CustomerTaskType;
  serviceSettings: CustomerTaskType;
  workplaceAssessment: CustomerTaskType;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  accountingPeriodEnd?: Maybe<Scalars['Date']['output']>;
  accountingPeriodStart?: Maybe<Scalars['Date']['output']>;
  acknowledgments: Array<AcknowledgmentType>;
  addedToAcuteAt?: Maybe<Scalars['DateTime']['output']>;
  addresses: Array<CustomerAddressType>;
  attachmentAddressCity: Scalars['String']['output'];
  attachmentAddressEmail: Scalars['String']['output'];
  attachmentAddressPostCode: Scalars['String']['output'];
  attachmentAddressStreetAddress: Scalars['String']['output'];
  businessId: Scalars['String']['output'];
  company: CompanyType;
  contactPersons: Array<ContactPersonType>;
  contractContact?: Maybe<ContactPersonType>;
  contracts: Array<SalesContractType>;
  createdAt: Scalars['DateTime']['output'];
  customerRoles: Array<RoleCustomerType>;
  departments: Array<DepartmentType>;
  descriptionOfOperations: Scalars['String']['output'];
  descriptionOfOrganization: Scalars['String']['output'];
  descriptionOfStaff: Scalars['String']['output'];
  documentSet: Array<DocumentType>;
  dueDateActionPlan?: Maybe<Scalars['Date']['output']>;
  dueDateWorkplaceAssessment?: Maybe<Scalars['Date']['output']>;
  eInvoicingAddress: Scalars['String']['output'];
  eInvoicingOperator: Scalars['String']['output'];
  employeegroupSet: Array<EmployeeGroupType>;
  entrepreneurInsuranceType: OrganizationsCustomerEntrepreneurInsuranceTypeChoices;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idAcute: Scalars['String']['output'];
  insurances: Array<InsuranceType>;
  internalNotes: Scalars['String']['output'];
  invoicesaleSet: Array<InvoiceSaleType>;
  invoicingAddressCity: Scalars['String']['output'];
  invoicingAddressPostCode: Scalars['String']['output'];
  invoicingAddressStreetAddress: Scalars['String']['output'];
  invoicingContact?: Maybe<ContactPersonType>;
  latestKelaReportAt?: Maybe<Scalars['Date']['output']>;
  marketingName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  occupationalHealthContact?: Maybe<ContactPersonType>;
  occupationalHealthNeeds: Scalars['String']['output'];
  occupationalSafetyNeeds: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  profileSet: Array<ProfileType>;
  responsibleDoctor?: Maybe<UserType>;
  responsibleNurse?: Maybe<UserType>;
  responsibleTeam?: Maybe<HealthcareTeamType>;
  serviceSettings?: Maybe<CustomerServiceSettingsType>;
  sickLeaveSetting?: Maybe<SickLeaveSettingsCustomerType>;
  taskSet: Array<TaskType>;
  taxFreeBenefitsInstructionsGiven: Scalars['Boolean']['output'];
  usersCustomer: Array<UserType>;
};

export type CustomerUserAccidentInsuranceInfoInput = {
  insuranceCompany?: InputMaybe<Scalars['String']['input']>;
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUserAdditionalInfo = {
  closestSupervisor?: InputMaybe<Scalars['ID']['input']>;
  closestSupervisorEmail?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['ID']['input']>;
  hasExposure?: InputMaybe<Scalars['Boolean']['input']>;
  isSupervisor?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  nextCheckDate?: InputMaybe<Scalars['Date']['input']>;
  previousCheckUnknown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerUserAmountsType = {
  __typename?: 'CustomerUserAmountsType';
  amountWithExposure: Scalars['Int']['output'];
  amountWithoutExposure: Scalars['Int']['output'];
};

export type CustomerUserContactPersonInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUserHealthCheckInfo = {
  nextCheckDate?: InputMaybe<Scalars['Date']['input']>;
  previousCheckUnknown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerUserHealthcareServices = {
  benefitsCustomRestrictionReason?: InputMaybe<Scalars['String']['input']>;
  benefitsRestrictedUntil?: InputMaybe<Scalars['Date']['input']>;
  benefitsRestrictionReason?: InputMaybe<Scalars['String']['input']>;
  kelaTwoServicesInUse?: InputMaybe<Scalars['Boolean']['input']>;
  kelaTwoServicesRestrictionReason?: InputMaybe<Scalars['String']['input']>;
  personnelBenefitsInUse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerUserMedicalInsuranceInfoInput = {
  insuranceCompany?: InputMaybe<Scalars['String']['input']>;
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CustomersHealthcareSummaryType = {
  __typename?: 'CustomersHealthcareSummaryType';
  totalCustomersHealthcareTeamInitialActionsNotFinished: Scalars['Int']['output'];
  totalCustomersHealthcareTeamNotSpecified: Scalars['Int']['output'];
  totalCustomersKelaReportNotDone: Scalars['Int']['output'];
  totalCustomersTosuNotScheduled: Scalars['Int']['output'];
  totalCustomersTpsNotScheduled: Scalars['Int']['output'];
  totalUsersNextCheckupNotScheduled: Scalars['Int']['output'];
  totalUsersPreviousCheckupNotKnown: Scalars['Int']['output'];
};

export type DeleteApiToken = {
  __typename?: 'DeleteApiToken';
  id: Scalars['ID']['output'];
};

export type DeleteBranch = {
  __typename?: 'DeleteBranch';
  branchId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteCampaign = {
  __typename?: 'DeleteCampaign';
  campaignId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteCompany = {
  __typename?: 'DeleteCompany';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteCustomer = {
  __typename?: 'DeleteCustomer';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteDocument = {
  __typename?: 'DeleteDocument';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteDocumentCategory = {
  __typename?: 'DeleteDocumentCategory';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteDocumentTemplate = {
  __typename?: 'DeleteDocumentTemplate';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteDocumentTemplateItem = {
  __typename?: 'DeleteDocumentTemplateItem';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteDocumentVersion = {
  __typename?: 'DeleteDocumentVersion';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteHealthcareTeam = {
  __typename?: 'DeleteHealthcareTeam';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteInvoiceInterface = {
  __typename?: 'DeleteInvoiceInterface';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteLaboratoryCatalog = {
  __typename?: 'DeleteLaboratoryCatalog';
  id: Scalars['ID']['output'];
};

export type DeleteLaboratoryOrder = {
  __typename?: 'DeleteLaboratoryOrder';
  laboratoryOrderId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteLaboratorySalesPrice = {
  __typename?: 'DeleteLaboratorySalesPrice';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteLaboratorySupplier = {
  __typename?: 'DeleteLaboratorySupplier';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteNode = {
  __typename?: 'DeleteNode';
  id: Scalars['ID']['output'];
};

export type DeleteNorrePackage = {
  __typename?: 'DeleteNorrePackage';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeletePartner = {
  __typename?: 'DeletePartner';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeletePaymentCommitment = {
  __typename?: 'DeletePaymentCommitment';
  paymentCommitment?: Maybe<PaymentCommitmentType>;
};

export type DeleteProcedure = {
  __typename?: 'DeleteProcedure';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteProcedurePartnerPrice = {
  __typename?: 'DeleteProcedurePartnerPrice';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteProfessionalGroup = {
  __typename?: 'DeleteProfessionalGroup';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteProfile = {
  __typename?: 'DeleteProfile';
  deleted?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteSalesCatalog = {
  __typename?: 'DeleteSalesCatalog';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteSickLeaveCategory = {
  __typename?: 'DeleteSickLeaveCategory';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteSickLeavePeriodSetting = {
  __typename?: 'DeleteSickLeavePeriodSetting';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteSickLeaveSelfReportReason = {
  __typename?: 'DeleteSickLeaveSelfReportReason';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteSupplierPackage = {
  __typename?: 'DeleteSupplierPackage';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteTask = {
  __typename?: 'DeleteTask';
  id: Scalars['ID']['output'];
};

export type DeleteTitleTemplate = {
  __typename?: 'DeleteTitleTemplate';
  titleTemplateId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteTree = {
  __typename?: 'DeleteTree';
  treeId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteUser = {
  __typename?: 'DeleteUser';
  userId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteView = {
  __typename?: 'DeleteView';
  id: Scalars['ID']['output'];
};

export type DepartmentType = {
  __typename?: 'DepartmentType';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  userSet: Array<UserType>;
};

export type DiscountType = {
  __typename?: 'DiscountType';
  discountType: SalescatalogsDiscountDiscountTypeChoices;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  percent: Scalars['Decimal']['output'];
  salesContractVersionPrice: SalesContractVersionPricesType;
};

export type DiscountsInputType = {
  discountDurationBase?: InputMaybe<Scalars['Int']['input']>;
  discountDurationExtraServices?: InputMaybe<Scalars['Int']['input']>;
  discountDurationHealthCareReception?: InputMaybe<Scalars['Int']['input']>;
  discountPercentBase?: InputMaybe<Scalars['Decimal']['input']>;
  discountPercentExtraServices?: InputMaybe<Scalars['Decimal']['input']>;
  discountPercentHealthCareReception?: InputMaybe<Scalars['Decimal']['input']>;
};

/** Value belongs to codesystem 1.2.246.537.5.40123 values. */
export type DispenseStatus = {
  __typename?: 'DispenseStatus';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
};

export type DocumentCategoryType = {
  __typename?: 'DocumentCategoryType';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  documentCategoryParent?: Maybe<DocumentCategoryType>;
  documentSet: Array<DocumentType>;
  documentcategorySet: Array<DocumentCategoryType>;
  hideOldVersions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  showForCustomerUsers: Scalars['Boolean']['output'];
  showForCustomers: Scalars['Boolean']['output'];
  taskSet: Array<TaskType>;
  title: Scalars['String']['output'];
};

export type DocumentTemplateItemType = {
  __typename?: 'DocumentTemplateItemType';
  condition?: Maybe<StatementGroupType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  template?: Maybe<DocumentTemplateType>;
  type: DocumenttemplatesDocumentTemplateItemTypeChoices;
  value: Scalars['String']['output'];
};

export type DocumentTemplateType = {
  __typename?: 'DocumentTemplateType';
  campaigns: Array<CampaignType>;
  createdAt: Scalars['DateTime']['output'];
  documentTemplateItems: Array<DocumentTemplateItemType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  treeIds: Array<TreeType>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  documentCategory: DocumentCategoryType;
  documentversionSet: Array<DocumentVersionType>;
  id: Scalars['ID']['output'];
  taskSet: Array<TaskType>;
  title: Scalars['String']['output'];
  user?: Maybe<UserType>;
};

export type DocumentVersionType = {
  __typename?: 'DocumentVersionType';
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserType;
  document: DocumentType;
  file: Scalars['String']['output'];
  fileThumbnail: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  versionNumber: Scalars['Int']['output'];
};

/** An enumeration. */
export enum DocumenttemplatesDocumentTemplateItemTypeChoices {
  /** Header */
  A_1 = 'A_1',
  /** Text */
  A_2 = 'A_2',
  /** Table */
  A_3 = 'A_3'
}

/** Value belongs to codesystem 1.2.246.537.6.138 values. */
export type DoseUnit = {
  __typename?: 'DoseUnit';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type DrugContainerType = {
  __typename?: 'DrugContainerType';
  code: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Value belongs to codesystem 1.2.246.537.5.40183 values. */
export type DrugExchangeProhibitionReasonType = {
  __typename?: 'DrugExchangeProhibitionReasonType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type DrugPackageDetailsState = {
  __typename?: 'DrugPackageDetailsState';
  /** Specifies drug amount in package or package size. */
  quantity: Scalars['Float']['output'];
  /** Specifies unit for drug quantity. */
  unit: Scalars['String']['output'];
};

export type DrugPackageInfo = {
  __typename?: 'DrugPackageInfo';
  asText?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type DrugRegisterExternalPrescriptionCreationArguments = {
  /** Name of medicinal product */
  productName: Scalars['String']['input'];
  /** Type of medicinal product, the value belongs to codeserver 1.2.246.537.6.604 codes. */
  productType: Scalars['String']['input'];
};

/** An enumeration. */
export enum DrugRegisterInternalMedicineAssign {
  ByActiveIngredient = 'BY_ACTIVE_INGREDIENT',
  ByTradeName = 'BY_TRADE_NAME'
}

export type DrugRegisterInternalPrescriptionCreationArguments = {
  /** If assigned medicine is biological medicine and there is similar alternative medicines that are cheaper, doctor should/can write an argument of using not the cheapest one. */
  argumentToNotUseCheapestBiologicalMedicine?: InputMaybe<Scalars['String']['input']>;
  /** If assigned by active ingderient, no detailed drug package is defined, but instead only the active ingredient of the selected package is defined */
  assignBy: DrugRegisterInternalMedicineAssign;
  /** Specifies drug package id in drug register */
  packageId: Scalars['Int']['input'];
  /** Special clarification. Doctor should be asked for special clarification if selected medicine have one. */
  specialClarification?: InputMaybe<SpecialClarificationInput>;
};

export type DrugRelatedCodeSystems = {
  __typename?: 'DrugRelatedCodeSystems';
  ATC?: Maybe<CodeSystemType>;
  PKV?: Maybe<CodeSystemType>;
  VNR?: Maybe<CodeSystemType>;
  careStage?: Maybe<CodeSystemType>;
  caringType?: Maybe<CodeSystemType>;
  container?: Maybe<CodeSystemType>;
  drugs?: Maybe<CodeSystemType>;
  finLoinc?: Maybe<CodeSystemType>;
  gender?: Maybe<CodeSystemType>;
  header?: Maybe<CodeSystemType>;
  medicineType?: Maybe<CodeSystemType>;
  prescriptionType?: Maybe<CodeSystemType>;
  technicalStructures?: Maybe<CodeSystemType>;
  typeOfCaring?: Maybe<CodeSystemType>;
  view?: Maybe<CodeSystemType>;
};

export type DrugTextType = {
  __typename?: 'DrugTextType';
  doseMultiplier?: Maybe<Scalars['String']['output']>;
  doseNumber?: Maybe<Scalars['String']['output']>;
  doseTimePeriod?: Maybe<Scalars['String']['output']>;
  doseType?: Maybe<Scalars['String']['output']>;
  guide?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  ingredientTypeName?: Maybe<Scalars['String']['output']>;
  langCode?: Maybe<Scalars['String']['output']>;
  packageAdditionalInfo?: Maybe<Scalars['String']['output']>;
};

/** Medicine from medicine database provided by Multirec Oy. */
export type DrugType = {
  __typename?: 'DrugType';
  ALV?: Maybe<Scalars['String']['output']>;
  ATC?: Maybe<AtcType>;
  KELAId?: Maybe<Scalars['Int']['output']>;
  KELAIdType?: Maybe<Scalars['String']['output']>;
  PKV?: Maybe<PkvType>;
  VNR?: Maybe<Scalars['String']['output']>;
  activeIngredient?: Maybe<Array<Maybe<ActiveIngredientType>>>;
  availabilityFailure?: Maybe<AvailabilityFailure>;
  biologicalMedicine?: Maybe<ToBeOrNotToBe>;
  biologicalMedicineGroups: Array<Scalars['String']['output']>;
  biosimilar?: Maybe<ToBeOrNotToBe>;
  bloodAndPlasmaDerivedProduct?: Maybe<ToBeOrNotToBe>;
  codeSystems?: Maybe<DrugRelatedCodeSystems>;
  container?: Maybe<DrugContainerType>;
  containerCode?: Maybe<Scalars['String']['output']>;
  dosageUnit?: Maybe<DoseUnit>;
  drugExchange: Scalars['Boolean']['output'];
  drugKey: Scalars['Int']['output'];
  drugTexts?: Maybe<Array<Maybe<DrugTextType>>>;
  drugType?: Maybe<MedicinalProductType>;
  ingredientText?: Maybe<Scalars['String']['output']>;
  ingredientType?: Maybe<IngredientGraphQlType>;
  intensity?: Maybe<Scalars['String']['output']>;
  marketer?: Maybe<Scalars['String']['output']>;
  marketingAuthorizationName?: Maybe<Scalars['String']['output']>;
  nacrotic?: Maybe<Scalars['String']['output']>;
  officialConfirmedTradePrice?: Maybe<Scalars['String']['output']>;
  packageId: Scalars['Int']['output'];
  packageMultiplier?: Maybe<Scalars['Decimal']['output']>;
  packageSize?: Maybe<Scalars['Decimal']['output']>;
  packageText?: Maybe<Scalars['String']['output']>;
  packageUnit?: Maybe<Scalars['String']['output']>;
  pharmaceuticalForm?: Maybe<Scalars['String']['output']>;
  priceWithTaxes?: Maybe<Scalars['String']['output']>;
  priceWithoutTaxes?: Maybe<Scalars['String']['output']>;
  restrictedPrivileges?: Maybe<RestrictedPrivilege>;
  specialClarifications?: Maybe<Array<Maybe<SpecialClarificationInfo>>>;
  substituteDrugPackagesCode?: Maybe<Scalars['String']['output']>;
  substituteMedicinesCode?: Maybe<Scalars['String']['output']>;
  tradeName: Scalars['String']['output'];
  tradePrice?: Maybe<Scalars['String']['output']>;
  trafficHazard?: Maybe<Scalars['String']['output']>;
  underAdditionalMonitoring?: Maybe<ToBeOrNotToBe>;
  vaccineAbbreviation?: Maybe<Scalars['String']['output']>;
};

export type EducationOutput = {
  __typename?: 'EducationOutput';
  code: Scalars['String']['output'];
  examinationDate?: Maybe<Scalars['DateTime']['output']>;
};

/** Value belongs to codesystem 1.2.246.537.6.148 values. */
export type EducationType = {
  __typename?: 'EducationType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
  examination?: Maybe<Scalars['String']['output']>;
  public: Scalars['Boolean']['output'];
  specification?: Maybe<Scalars['String']['output']>;
};

export type EmployeeGroupType = {
  __typename?: 'EmployeeGroupType';
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  userSet: Array<UserType>;
};

export type EndCampaign = {
  __typename?: 'EndCampaign';
  campaign?: Maybe<CampaignType>;
};

/** An enumeration. */
export enum EntrepreneurInsuranceType {
  Myel = 'MYEL',
  Yel = 'YEL'
}

export type ExtemporePrescriptionCreationArguments = {
  /** Describes ingredients for prepared custom mixture. */
  ingredients: Array<InputMaybe<IngredientInput>>;
  /** Specifies custom mixture name. */
  mixtureName: Scalars['String']['input'];
  /** Describes guidance for preparing custom mixture. */
  preparationGuide: Scalars['String']['input'];
};

/** Value belongs to codesystem 1.2.246.537.6.96 values. */
export type FinLoincType = {
  __typename?: 'FinLoincType';
  OID?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEn?: Maybe<Scalars['String']['output']>;
  descriptionSwe?: Maybe<Scalars['String']['output']>;
  hierarchy?: Maybe<Scalars['String']['output']>;
  leafKnot?: Maybe<Scalars['Boolean']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  longNameEn?: Maybe<Scalars['String']['output']>;
  longNameSwe?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  physiologicalGrouping?: Maybe<Scalars['String']['output']>;
  questionkey?: Maybe<QuestionKeyOutputType>;
  replacementCode?: Maybe<Scalars['String']['output']>;
  shortName: Scalars['String']['output'];
  toSummaryScreen?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type Functionality = {
  __typename?: 'Functionality';
  companies: Array<UserRoleCompanyBaseInfo>;
  customers: Array<UserRoleCustomerBaseInfo>;
  isHealthcareWorker: Scalars['Boolean']['output'];
  isSupplier: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  perms: Array<Scalars['String']['output']>;
  primaryUi: ViewViewTypeChoices;
  showCorporateAdminViews: Scalars['Boolean']['output'];
  showCustomerAdminViews: Scalars['Boolean']['output'];
  showHealthcareWorkerViews: Scalars['Boolean']['output'];
  showRegionalAdminViews: Scalars['Boolean']['output'];
  showSupplierViews: Scalars['Boolean']['output'];
};

export enum GenderInputType {
  Female = 'FEMALE',
  Male = 'MALE',
  Undefined = 'UNDEFINED',
  Unknown = 'UNKNOWN'
}

/** Value belongs to codesystem 1.2.246.537.5.1 values. */
export type GenderType = {
  __typename?: 'GenderType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
};

export type GetDigestsForMultiSignature = {
  __typename?: 'GetDigestsForMultiSignature';
  /** Digest OID-pairs of the documents to be added to multi-signature. */
  documentDigests: Array<Maybe<OidDigestPair>>;
};

export type HandleSickLeaveNotice = {
  __typename?: 'HandleSickLeaveNotice';
  sickLeaveNotice?: Maybe<SickLeaveNoticeType>;
};

/** Value belongs to codesystem 1.2.246.537.6.14 values. */
export type HeaderType = {
  __typename?: 'HeaderType';
  OID?: Maybe<Scalars['String']['output']>;
  carestage?: Maybe<CareStageType>;
  code: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  orderNumber?: Maybe<Scalars['String']['output']>;
};

export type HealthCheckType = {
  __typename?: 'HealthCheckType';
  checkDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: UserType;
};

export type HealthcareTeamType = {
  __typename?: 'HealthcareTeamType';
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  description: Scalars['String']['output'];
  healthcareWorkers: Array<UserType>;
  id: Scalars['ID']['output'];
  responsibleTeamCustomers: Array<CustomerType>;
  taskSet: Array<TaskType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UserType>;
};

export type HealthcareWorkerAdditionalInfo = {
  healthcareTeamIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  professionalGroupId?: InputMaybe<Scalars['ID']['input']>;
};

/** Value belongs to codesystem 1.2.246.537.6.1 values. */
export type Icd10Type = {
  __typename?: 'ICD10Type';
  OID?: Maybe<Scalars['String']['output']>;
  abbreviation: Scalars['String']['output'];
  anm?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  codeFirstPart?: Maybe<Scalars['String']['output']>;
  codeSecondPart?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEn?: Maybe<Scalars['String']['output']>;
  descriptionSwe?: Maybe<Scalars['String']['output']>;
  excludes?: Maybe<Scalars['String']['output']>;
  excludesSwe?: Maybe<Scalars['String']['output']>;
  hierarchy?: Maybe<Scalars['String']['output']>;
  icpcCode?: Maybe<Scalars['String']['output']>;
  includes?: Maybe<Scalars['String']['output']>;
  includesSwe?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  joiningMark?: Maybe<Scalars['String']['output']>;
  latinName?: Maybe<Scalars['String']['output']>;
  leafKnot?: Maybe<Scalars['Boolean']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  longNameEn?: Maybe<Scalars['String']['output']>;
  longNameSwe?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  persistence?: Maybe<Scalars['String']['output']>;
  primaryIcpcMen?: Maybe<Scalars['String']['output']>;
  primaryIcpcWomen?: Maybe<Scalars['String']['output']>;
  psychiatry?: Maybe<Scalars['Boolean']['output']>;
  questionkey?: Maybe<QuestionKeyOutputType>;
  replacementCode?: Maybe<Scalars['String']['output']>;
  riskLevel?: Maybe<Scalars['String']['output']>;
  riskPersistence?: Maybe<Scalars['String']['output']>;
  riskType?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  sickLeaveCategories: Array<SickLeaveCategoryType>;
  sickLeaves: Array<SickLeaveNoticeType>;
  sickleavecategoryicd10?: Maybe<SickLeaveCategoryIcd10Type>;
};

export type IngredientGraphQlType = {
  __typename?: 'IngredientGraphQLType';
  code: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type IngredientInput = {
  atcCode?: InputMaybe<Scalars['String']['input']>;
  freeText?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
};

export type IngredientOutput = {
  __typename?: 'IngredientOutput';
  atcCode?: Maybe<Scalars['String']['output']>;
  freeText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
};

export type InsuranceCompanyType = {
  __typename?: 'InsuranceCompanyType';
  businessId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insuranceSet: Array<InsuranceType>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type InsuranceInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  insuranceCompanyId?: InputMaybe<Scalars['ID']['input']>;
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  insuranceType: InsuranceTypeChoices;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InsuranceType = {
  __typename?: 'InsuranceType';
  customer?: Maybe<CustomerType>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions: Scalars['String']['output'];
  insuranceCompany?: Maybe<InsuranceCompanyType>;
  insuranceNumber: Scalars['String']['output'];
  insuranceType: InsurancesInsuranceInsuranceTypeChoices;
  url: Scalars['String']['output'];
  user?: Maybe<UserType>;
};

/** An enumeration. */
export enum InsuranceTypeChoices {
  Accident = 'ACCIDENT',
  AccidentWork = 'ACCIDENT_WORK',
  EmploymentPension = 'EMPLOYMENT_PENSION',
  MedicalExpense = 'MEDICAL_EXPENSE'
}

/** An enumeration. */
export enum InsurancesInsuranceInsuranceTypeChoices {
  /** Accident */
  Accident = 'ACCIDENT',
  /** Accident Work */
  AccidentWork = 'ACCIDENT_WORK',
  /** Employment Pension */
  EmploymentPension = 'EMPLOYMENT_PENSION',
  /** Medical Expense */
  MedicalExpense = 'MEDICAL_EXPENSE'
}

export type InvoiceInterfaceType = {
  __typename?: 'InvoiceInterfaceType';
  apiKey: Scalars['String']['output'];
  apiUser: Scalars['String']['output'];
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type InvoicePurchaseAttachmentType = {
  __typename?: 'InvoicePurchaseAttachmentType';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  file: Scalars['String']['output'];
  filesize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  idRemote: Scalars['String']['output'];
  invoicePurchase: InvoicePurchaseType;
  mimetype: Scalars['String']['output'];
};

export type InvoicePurchaseCommentType = {
  __typename?: 'InvoicePurchaseCommentType';
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invoicePurchase: InvoicePurchaseType;
  stateNew?: Maybe<InvoicesInvoicePurchaseCommentStateNewChoices>;
  user: UserType;
  userOwner?: Maybe<UserType>;
  variant: InvoicesInvoicePurchaseCommentVariantChoices;
};

export type InvoicePurchaseRowType = {
  __typename?: 'InvoicePurchaseRowType';
  articleIdentifier: Scalars['String']['output'];
  articleName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  discountPercent?: Maybe<Scalars['Decimal']['output']>;
  discountValue?: Maybe<Scalars['Decimal']['output']>;
  freetext: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ignoredInSales: Scalars['Boolean']['output'];
  invoicePurchase: InvoicePurchaseType;
  invoiceSaleRow?: Maybe<InvoiceSaleRowType>;
  quantity: Scalars['Decimal']['output'];
  sumGross: Scalars['Decimal']['output'];
  sumGrossOverride?: Maybe<Scalars['Decimal']['output']>;
  sumNet: Scalars['Decimal']['output'];
  unit: Scalars['String']['output'];
  unitPrice: Scalars['Decimal']['output'];
  vatpercent: Scalars['Decimal']['output'];
};

/** An enumeration. */
export enum InvoicePurchaseState {
  Approved = 'APPROVED',
  Invalid = 'INVALID',
  New = 'NEW',
  Ok = 'OK',
  Rejected = 'REJECTED'
}

export type InvoicePurchaseType = {
  __typename?: 'InvoicePurchaseType';
  adjustedGrossSum: Scalars['Decimal']['output'];
  approvalIdRemote: Scalars['String']['output'];
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  bankAccount: Scalars['String']['output'];
  bankBic: Scalars['String']['output'];
  bankMessage: Scalars['String']['output'];
  bankReference: Scalars['String']['output'];
  company: CompanyType;
  costSettingsCategory?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  currencyRate: Scalars['Decimal']['output'];
  dueDate: Scalars['Date']['output'];
  entryDate: Scalars['Date']['output'];
  finvoiceAvailable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  idRemote: Scalars['String']['output'];
  information: Scalars['String']['output'];
  invoiceDate: Scalars['Date']['output'];
  invoiceNumber: Scalars['String']['output'];
  invoicepurchaseattachmentSet: Array<InvoicePurchaseAttachmentType>;
  invoicepurchasecommentSet: Array<InvoicePurchaseCommentType>;
  invoicepurchaserowSet: Array<InvoicePurchaseRowType>;
  isFirstInvoice: Scalars['Boolean']['output'];
  isReceipt: Scalars['Boolean']['output'];
  orderNumber: Scalars['String']['output'];
  ourReference: Scalars['String']['output'];
  state: InvoicesInvoicePurchaseStateChoices;
  supplierBusinessId: Scalars['String']['output'];
  supplierName: Scalars['String']['output'];
  termsOfPayment: Scalars['String']['output'];
  totalGross: Scalars['Decimal']['output'];
  totalNet: Scalars['Decimal']['output'];
  user?: Maybe<UserType>;
  yourReference: Scalars['String']['output'];
};

export type InvoiceSaleRowType = {
  __typename?: 'InvoiceSaleRowType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceSale: InvoiceSaleType;
  invoicepurchaserowSet: Array<InvoicePurchaseRowType>;
  name: Scalars['String']['output'];
  productNo: Scalars['String']['output'];
  quantity: Scalars['Decimal']['output'];
  totalPrice: Scalars['Decimal']['output'];
  unit: Scalars['String']['output'];
  unitPrice: Scalars['Decimal']['output'];
  vat: Scalars['Decimal']['output'];
};

export type InvoiceSaleType = {
  __typename?: 'InvoiceSaleType';
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerType;
  id: Scalars['ID']['output'];
  invoicesalerowSet: Array<InvoiceSaleRowType>;
  notesBefore: Scalars['String']['output'];
  remoteId: Scalars['Int']['output'];
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

/** An enumeration. */
export enum InvoicesInvoicePurchaseCommentStateNewChoices {
  /** Approved */
  Approved = 'APPROVED',
  /** Invalid */
  Invalid = 'INVALID',
  /** New */
  New = 'NEW',
  /** Ok */
  Ok = 'OK',
  /** Rejected */
  Rejected = 'REJECTED'
}

/** An enumeration. */
export enum InvoicesInvoicePurchaseCommentVariantChoices {
  /** Message */
  Message = 'MESSAGE',
  /** Owner */
  Owner = 'OWNER',
  /** State */
  State = 'STATE'
}

/** An enumeration. */
export enum InvoicesInvoicePurchaseStateChoices {
  /** Approved */
  Approved = 'APPROVED',
  /** Invalid */
  Invalid = 'INVALID',
  /** New */
  New = 'NEW',
  /** Ok */
  Ok = 'OK',
  /** Rejected */
  Rejected = 'REJECTED'
}

export type InvoicingBaseInfoInputType = {
  city?: InputMaybe<Scalars['String']['input']>;
  eInvoicingAddress?: InputMaybe<Scalars['String']['input']>;
  eInvoicingOperator?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type IterationInput = {
  intervalDays: Scalars['Int']['input'];
  repeat: Scalars['Int']['input'];
};

export type IterationOutput = {
  __typename?: 'IterationOutput';
  intervalDays: Scalars['Int']['output'];
  repeat: Scalars['Int']['output'];
};

/** An enumeration. */
export enum KantaDocumentType {
  PrescriptionAdd = 'PRESCRIPTION_ADD',
  PrescriptionReplacement = 'PRESCRIPTION_REPLACEMENT',
  PrescriptionRepudiation = 'PRESCRIPTION_REPUDIATION'
}

export type LaboratoryCatalogContentsType = {
  __typename?: 'LaboratoryCatalogContentsType';
  laboratoryTests: Array<LaboratoryTestType>;
  norrePackages: Array<NorrePackageType>;
  supplierPackages: Array<SupplierPackageType>;
};

export type LaboratoryCatalogSalesPriceType = {
  __typename?: 'LaboratoryCatalogSalesPriceType';
  id: Scalars['ID']['output'];
  isAvailable: Scalars['Boolean']['output'];
  laboratoryCatalog: LaboratoryCatalogType;
  laboratoryTest?: Maybe<LaboratoryTestType>;
  norrePackage?: Maybe<NorrePackageType>;
  price: Scalars['Decimal']['output'];
  supplierPackage?: Maybe<SupplierPackageType>;
};

export type LaboratoryCatalogType = {
  __typename?: 'LaboratoryCatalogType';
  activeFrom?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  salesPrices: Array<LaboratoryCatalogSalesPriceType>;
  state: LaboratorycatalogsLaboratoryCatalogStateChoices;
};

/** An enumeration. */
export enum LaboratoryLaboratoryOrderStateChoices {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Changed */
  Changed = 'CHANGED',
  /** Draft */
  Draft = 'DRAFT',
  /** Results received */
  ResultsReceived = 'RESULTS_RECEIVED',
  /** Results reviewed */
  ResultsReviewed = 'RESULTS_REVIEWED',
  /** Sent */
  Sent = 'SENT'
}

export type LaboratoryOrderType = {
  __typename?: 'LaboratoryOrderType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  laboratoryOid: Scalars['String']['output'];
  patient: PatientType;
  professional?: Maybe<Professional>;
  reviewedByPatientAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedByPid?: Maybe<Professional>;
  reviewedByPidAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  serviceEvent: ServiceEvent;
  state: LaboratoryLaboratoryOrderStateChoices;
};

export type LaboratoryResultType = {
  __typename?: 'LaboratoryResultType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  limits: Scalars['String']['output'];
  rawDate: Scalars['String']['output'];
  result: Scalars['String']['output'];
  testDate: Scalars['Date']['output'];
  unit: Scalars['String']['output'];
};

export enum LaboratorySalesPriceModelTypenameChoices {
  Laboratorytesttype = 'LABORATORYTESTTYPE',
  Norrepackagetype = 'NORREPACKAGETYPE',
  Supplierpackagetype = 'SUPPLIERPACKAGETYPE'
}

export type LaboratorySupplierType = {
  __typename?: 'LaboratorySupplierType';
  address: Scalars['String']['output'];
  childLaboratories: Array<LaboratorySupplierType>;
  city: Scalars['String']['output'];
  code: LaboratoryType;
  createdAt: Scalars['DateTime']['output'];
  discountPercent: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentLaboratory?: Maybe<LaboratorySupplierType>;
  phoneNumber: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  pricePerVisit?: Maybe<Scalars['Decimal']['output']>;
  supplierpackagepriceSet: Array<SupplierPackagePriceType>;
  suppliertestpriceSet: Array<SupplierTestPriceType>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<UserType>;
};

export type LaboratoryTestCodeType = {
  codeId: Scalars['Int']['input'];
};

/** Value belongs to codesystem 1.2.246.537.6.3 values. */
export type LaboratoryTestNomenclatureType = {
  __typename?: 'LaboratoryTestNomenclatureType';
  abbreviation: Scalars['String']['output'];
  codeId: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  laboratorytest?: Maybe<LaboratoryTestType>;
  longName?: Maybe<Scalars['String']['output']>;
  longNameSwedish?: Maybe<Scalars['String']['output']>;
  replacementCodeIds?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type LaboratoryTestType = {
  __typename?: 'LaboratoryTestType';
  code?: Maybe<LaboratoryTestNomenclatureType>;
  createdAt: Scalars['DateTime']['output'];
  descriptionLong: Scalars['String']['output'];
  descriptionShort: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  norrepackagetestSet: Array<NorrePackageTestType>;
  salesPrices: Array<LaboratoryCatalogSalesPriceType>;
  supplierpackagetestSet: Array<SupplierPackageTestType>;
  suppliertestpriceSet: Array<SupplierTestPriceType>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<UserType>;
};

/** Value belongs to codesystem 1.2.246.537.6.202 values. */
export type LaboratoryType = {
  __typename?: 'LaboratoryType';
  abbreviation?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  beginDate?: Maybe<Scalars['Date']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  codeId: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  hierarchyLevel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHealthCareOperationalUnit?: Maybe<Scalars['Boolean']['output']>;
  isHealthCareServiceUnit?: Maybe<Scalars['Boolean']['output']>;
  isSocialCareOperationalUnit?: Maybe<Scalars['Boolean']['output']>;
  isSocialCareServiceUnit?: Maybe<Scalars['Boolean']['output']>;
  laboratorysupplierSet: Array<LaboratorySupplierType>;
  parentId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum LaboratorycatalogsLaboratoryCatalogStateChoices {
  /** Luonnos */
  Draft = 'DRAFT',
  /** Lukittu */
  Locked = 'LOCKED'
}

export type LockLaboratoryCatalog = {
  __typename?: 'LockLaboratoryCatalog';
  laboratoryCatalog?: Maybe<LaboratoryCatalogType>;
};

export type LockSalesCatalog = {
  __typename?: 'LockSalesCatalog';
  salesCatalog?: Maybe<SalesCatalogType>;
};

export type MeType = {
  __typename?: 'MeType';
  companyId: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  primarySite: SiteType;
  uuid: Scalars['UUID']['output'];
};

export type MedicalStaffSearchReturnType = {
  __typename?: 'MedicalStaffSearchReturnType';
  customers: Array<CustomerType>;
  users: Array<UserType>;
};

export type MedicationPeriodInfo = {
  __typename?: 'MedicationPeriodInfo';
  amount?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<TimeUnit>;
};

/** Value belongs to codesystem 1.2.246.537.6.604 values. */
export type MedicinalProductType = {
  __typename?: 'MedicinalProductType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type MedicineList = {
  __typename?: 'MedicineList';
  medicines?: Maybe<Array<Maybe<PrescriptionMetadata>>>;
  patient?: Maybe<PatientGeneralInfo>;
};

export enum MedicineUsageInputType {
  Continuous = 'CONTINUOUS',
  FixedTerm = 'FIXED_TERM',
  IfNecessary = 'IF_NECESSARY'
}

export enum MedicineUsageType {
  Continuous = 'CONTINUOUS',
  FixedTerm = 'FIXED_TERM',
  IfNecessary = 'IF_NECESSARY'
}

export type Mutation = {
  __typename?: 'Mutation';
  addCampaignSurveyRespondent?: Maybe<AddCampaignSurveyRespondent>;
  addHealthCheck?: Maybe<AddHealthCheck>;
  /** Add multi-signature value to prescriptions. */
  addMultiSignatureValueToPrescriptions?: Maybe<AddMultiSignatureValueToPrescriptions>;
  addPatient?: Maybe<AddPatient>;
  addSignatureValueToPrescription?: Maybe<AddSignatureValueToPrescription>;
  addSurveyAnswer?: Maybe<AddSurveyAnswer>;
  addUserEmploymentInformation?: Maybe<AddUserEmploymentInformation>;
  addUserInformationViewReasonLog?: Maybe<AddUserInformationViewReasonLog>;
  approveInvoicePurchases?: Maybe<ApproveInvoicePurchases>;
  bulkCreateUsers: BulkCreateUsers;
  cancelLaboratoryOrder?: Maybe<CancelLaboratoryOrder>;
  changeLaboratoryOrder?: Maybe<ChangeLaboratoryOrder>;
  createAcknowledgment: CreateAcknowledgment;
  createAndSendLaboratoryOrder?: Maybe<CreateAndSendLaboratoryOrder>;
  createApiToken?: Maybe<CreateApiToken>;
  createBranch?: Maybe<CreateBranch>;
  createCampaign?: Maybe<CreateCampaign>;
  createChoiceBranch?: Maybe<CreateChoiceBranch>;
  createCompanyAndSetAddresses: CreateCompanyAndSetAddresses;
  createCustomer: CreateCustomer;
  createCustomerAddress: CreateCustomerAddress;
  createCustomerWithContract: CreateCustomerWithContract;
  createDocument?: Maybe<CreateDocument>;
  createDocumentCategory?: Maybe<CreateDocumentCategory>;
  createDocumentTemplate?: Maybe<CreateDocumentTemplate>;
  createDocumentTemplateItem?: Maybe<CreateDocumentTemplateItem>;
  createDocumentVersion?: Maybe<CreateDocumentVersion>;
  createEmptyLaboratoryCatalog?: Maybe<CreateEmptyLaboratoryCatalog>;
  createHealthcareTeam?: Maybe<CreateHealthcareTeam>;
  createInsuranceCompany?: Maybe<CreateInsuranceCompany>;
  createInvoiceInterface?: Maybe<CreateInvoiceInterface>;
  createInvoicePurchaseComment?: Maybe<CreateInvoicePurchaseComment>;
  createInvoicesSale?: Maybe<CreateInvoicesSale>;
  createLaboratoryCatalogBasedOnExisting?: Maybe<CreateLaboratoryCatalogBasedOnExisting>;
  createLaboratorySupplier?: Maybe<CreateLaboratorySupplier>;
  createNorrePackage?: Maybe<CreateNorrePackage>;
  createOrUpdateLaboratorySalesPrice?: Maybe<CreateOrUpdateLaboratorySalesPrice>;
  createOrganization?: Maybe<CreateOrganization>;
  createPartner: CreatePartner;
  createPaymentCommitment?: Maybe<CreatePaymentCommitment>;
  createPrescription?: Maybe<CreatePrescription>;
  createProcedure?: Maybe<CreateProcedure>;
  createProcedurePartnerPrice?: Maybe<CreateProcedurePartnerPrice>;
  createProfessionalGroup?: Maybe<CreateProfessionalGroup>;
  createProfile?: Maybe<CreateProfile>;
  createQuestion?: Maybe<CreateQuestion>;
  createRoleCompany?: Maybe<CreateRoleCompany>;
  createRoleCorporate?: Maybe<CreateRoleCorporate>;
  createSalesCatalog?: Maybe<CreateSalesCatalog>;
  createSalesContractVersion: CreateSalesContractVersion;
  createServiceEvent?: Maybe<CreateServiceEvent>;
  createSickLeaveCategory?: Maybe<CreateSickLeaveCategory>;
  createSickLeaveNotice?: Maybe<CreateSickLeaveNotice>;
  createSickLeavePeriodSetting?: Maybe<CreateSickLeavePeriodSetting>;
  createSickLeaveSelfReportReason?: Maybe<CreateSickLeaveSelfReportReason>;
  createSupplierPackage?: Maybe<CreateSupplierPackage>;
  createTask?: Maybe<CreateTask>;
  createTaskTopic?: Maybe<CreateTaskTopic>;
  createTitletemplate?: Maybe<CreateTitleTemplate>;
  createTree: CreateTree;
  createUser?: Maybe<CreateUser>;
  createView?: Maybe<CreateView>;
  deleteApiToken?: Maybe<DeleteApiToken>;
  deleteBranch?: Maybe<DeleteBranch>;
  deleteCampaign?: Maybe<DeleteCampaign>;
  deleteCompany: DeleteCompany;
  deleteCustomer: DeleteCustomer;
  deleteDocument?: Maybe<DeleteDocument>;
  deleteDocumentCategory?: Maybe<DeleteDocumentCategory>;
  deleteDocumentTemplate?: Maybe<DeleteDocumentTemplate>;
  deleteDocumentTemplateItem?: Maybe<DeleteDocumentTemplateItem>;
  deleteDocumentVersion?: Maybe<DeleteDocumentVersion>;
  deleteHealthcareTeam?: Maybe<DeleteHealthcareTeam>;
  deleteInvoiceInterface?: Maybe<DeleteInvoiceInterface>;
  deleteLaboratoryCatalog?: Maybe<DeleteLaboratoryCatalog>;
  deleteLaboratoryOrder?: Maybe<DeleteLaboratoryOrder>;
  deleteLaboratorySalesPrice?: Maybe<DeleteLaboratorySalesPrice>;
  deleteLaboratorySupplier?: Maybe<DeleteLaboratorySupplier>;
  deleteNode?: Maybe<DeleteNode>;
  deleteNorrePackage?: Maybe<DeleteNorrePackage>;
  deletePartner: DeletePartner;
  deletePaymentCommitment?: Maybe<DeletePaymentCommitment>;
  deleteProcedure?: Maybe<DeleteProcedure>;
  deleteProcedurePartnerPrice?: Maybe<DeleteProcedurePartnerPrice>;
  deleteProfessionalGroup?: Maybe<DeleteProfessionalGroup>;
  deleteProfile?: Maybe<DeleteProfile>;
  deleteSalesCatalog?: Maybe<DeleteSalesCatalog>;
  deleteSickLeaveCategory?: Maybe<DeleteSickLeaveCategory>;
  deleteSickLeavePeriodSetting?: Maybe<DeleteSickLeavePeriodSetting>;
  deleteSickLeaveSelfReportReason?: Maybe<DeleteSickLeaveSelfReportReason>;
  deleteSupplierPackage?: Maybe<DeleteSupplierPackage>;
  deleteTask?: Maybe<DeleteTask>;
  deleteTitletemplate?: Maybe<DeleteTitleTemplate>;
  deleteTree?: Maybe<DeleteTree>;
  deleteUser?: Maybe<DeleteUser>;
  deleteView?: Maybe<DeleteView>;
  endCampaign?: Maybe<EndCampaign>;
  /** Calculate digests of structuredBody for every document for multi-signature. */
  getDigestsForMultiSignature?: Maybe<GetDigestsForMultiSignature>;
  handleSickLeaveNotice?: Maybe<HandleSickLeaveNotice>;
  lockLaboratoryCatalog?: Maybe<LockLaboratoryCatalog>;
  lockSalesCatalog?: Maybe<LockSalesCatalog>;
  newSurveyAnswer?: Maybe<NewSurveyAnswer>;
  passwordReset?: Maybe<PasswordReset>;
  passwordSet?: Maybe<PasswordSet>;
  preparePrescriptionForSigning?: Maybe<PreparePrescriptionForSigning>;
  /** Add multi-signature element with signed info to prescriptions to be signed. Returns multi-signature digest to be signed within a smartcard reader. */
  preparePrescriptionsForMultiSigning?: Maybe<PreparePrescriptionsForMultiSigning>;
  prepareSmartCardLogin?: Maybe<PrepareSmartCardLogin>;
  questionToNode?: Maybe<QuestionToNode>;
  refreshToken?: Maybe<RefreshNorreAuthToken>;
  rejectInvoicePurchase?: Maybe<RejectInvoicePurchase>;
  removeCampaignSurveyRespondent?: Maybe<RemoveCampaignSurveyRespondent>;
  replacePrescription?: Maybe<ReplacePrescription>;
  /**
   * Mutation which creates prescription repudiation documents in medical-database.
   * Repudiation documents should still send to Kanta using sendDocumentToKanta mutation or sendPrescriptionRepudiations.
   */
  repudiatePrescriptions?: Maybe<RepudiatePrescriptions>;
  resendLaboratoryOrder?: Maybe<ResendLaboratoryOrder>;
  revokeToken?: Maybe<RevokeNorreAuthToken>;
  saveAnswers?: Maybe<SaveAnswers>;
  sendDocumentToKanta?: Maybe<SendDocumentToKanta>;
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmail>;
  /** Mutation which send prescription repudiations to Kanta. */
  sendPrescriptionRepudiations?: Maybe<SendPrescriptionRepudiations>;
  sendUserToAcute?: Maybe<SendUserToAcute>;
  setCondition?: Maybe<SetCondition>;
  setPermissionsEqual?: Maybe<SetPermissionsEqual>;
  smartCardLogin?: Maybe<SmartCardLogin>;
  smartCardLogout?: Maybe<SmartCardLogout>;
  startCampaign?: Maybe<StartCampaign>;
  terminateContract: TerminateContract;
  tokenAuth?: Maybe<NorreAuthToken>;
  updateCampaign?: Maybe<UpdateCampaign>;
  updateCompanyAndSetAddresses: UpdateCompanyAndSetAddresses;
  updateCustomer: UpdateCustomer;
  updateCustomerInsurances: UpdateCustomerInsurances;
  updateCustomerInvoicingInformation: UpdateCustomerInvoicingInformation;
  updateCustomerServiceSettings?: Maybe<UpdateCustomerServiceSettings>;
  updateDocument?: Maybe<UpdateDocument>;
  updateDocumentCategory?: Maybe<UpdateDocumentCategory>;
  updateDocumentTemplate?: Maybe<UpdateDocumentTemplate>;
  updateDocumentTemplateItem?: Maybe<UpdateDocumentTemplateItem>;
  updateHealthcareTeam?: Maybe<UpdateHealthcareTeam>;
  updateInsuranceCompany?: Maybe<UpdateInsuranceCompany>;
  updateInvoiceInterface?: Maybe<UpdateInvoiceInterface>;
  updateInvoicePurchase?: Maybe<UpdateInvoicePurchase>;
  updateInvoicePurchaseRow?: Maybe<UpdateInvoicePurchaseRow>;
  updateLaboratoryCatalog?: Maybe<UpdateLaboratoryCatalog>;
  updateLaboratorySalesPriceAvailability?: Maybe<UpdateLaboratorySalesPriceAvailability>;
  updateLaboratorySupplier?: Maybe<UpdateLaboratorySupplier>;
  updateLaboratoryTest?: Maybe<UpdateLaboratoryTest>;
  updateMyPassword?: Maybe<UpdateMyPassword>;
  updateNode?: Maybe<UpdateNode>;
  updateNorrePackage?: Maybe<UpdateNorrePackage>;
  updateOwnPersonalInformation?: Maybe<UpdateOwnPersonalInformation>;
  updatePartner: UpdatePartner;
  updatePermissions?: Maybe<UpdatePermissions>;
  updateProcedure?: Maybe<UpdateProcedure>;
  updateProcedurePartnerPrice?: Maybe<UpdateProcedurePartnerPrice>;
  updateProfessionalGroup?: Maybe<UpdateProfessionalGroup>;
  updateProfileName?: Maybe<UpdateProfileName>;
  updateQuestion?: Maybe<UpdateQuestion>;
  updateSalesCatalog?: Maybe<UpdateSalesCatalog>;
  updateSalesPrice?: Maybe<UpdateSalesPrice>;
  updateSickLeaveCategory?: Maybe<UpdateSickLeaveCategory>;
  updateSickLeaveNotice?: Maybe<UpdateSickLeaveNotice>;
  updateSickLeavePeriodSetting?: Maybe<UpdateSickLeavePeriodSetting>;
  updateSickLeaveSelfReportReason?: Maybe<UpdateSickLeaveSelfReportReason>;
  updateSickLeaveSettingsCustomer?: Maybe<UpdateSickLeaveSettingsCustomer>;
  updateSupplierPackage?: Maybe<UpdateSupplierPackage>;
  updateTask?: Maybe<UpdateTask>;
  updateTree?: Maybe<UpdateTree>;
  updateUser?: Maybe<UpdateUser>;
  updateUserInsurances?: Maybe<UpdateUserInsurances>;
  updateUserRoles?: Maybe<UpdateUserRoles>;
  updateView?: Maybe<UpdateView>;
};


export type MutationAddCampaignSurveyRespondentArgs = {
  campaignSurveyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddHealthCheckArgs = {
  checkDate: Scalars['Date']['input'];
  nextCheckDate?: InputMaybe<Scalars['Date']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationAddMultiSignatureValueToPrescriptionsArgs = {
  certificate: Scalars['String']['input'];
  prescriptionOidCodes: Array<InputMaybe<Scalars['String']['input']>>;
  signatureId: Scalars['String']['input'];
  signatureValue: Scalars['String']['input'];
};


export type MutationAddPatientArgs = {
  birthDate: Scalars['String']['input'];
  firstNames: Array<InputMaybe<Scalars['String']['input']>>;
  gender: GenderInputType;
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  patientEmail: Scalars['String']['input'];
};


export type MutationAddSignatureValueToPrescriptionArgs = {
  certificate: Scalars['String']['input'];
  prescriptionOid: Scalars['String']['input'];
  signatureId: Scalars['String']['input'];
  signatureValue: Scalars['String']['input'];
};


export type MutationAddSurveyAnswerArgs = {
  answer: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
  treeResultId: Scalars['ID']['input'];
};


export type MutationAddUserEmploymentInformationArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationAddUserInformationViewReasonLogArgs = {
  otherReason?: InputMaybe<Scalars['String']['input']>;
  viewReasonId: Scalars['ID']['input'];
  viewedUserId: Scalars['ID']['input'];
};


export type MutationApproveInvoicePurchasesArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationBulkCreateUsersArgs = {
  customerId: Scalars['ID']['input'];
  listOfUsers: Array<UserFileUploadFieldsInputType>;
};


export type MutationCancelLaboratoryOrderArgs = {
  laboratoryOrderId: Scalars['ID']['input'];
};


export type MutationChangeLaboratoryOrderArgs = {
  laboratoryOrderId: Scalars['ID']['input'];
};


export type MutationCreateAcknowledgmentArgs = {
  customerId: Scalars['ID']['input'];
  type: AcknowledgmentTypeChoices;
  userId: Scalars['ID']['input'];
};


export type MutationCreateAndSendLaboratoryOrderArgs = {
  laboratoryOid: Scalars['String']['input'];
  laboratoryTestCodes: Array<InputMaybe<LaboratoryTestCodeType>>;
  patientEmail: Scalars['String']['input'];
  professionalId: Scalars['ID']['input'];
  serviceEventId: Scalars['ID']['input'];
};


export type MutationCreateApiTokenArgs = {
  corporateProfiles: Array<Scalars['ID']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  rolesCompany: Array<CreateRoleInfoInputType>;
  rolesCustomer: Array<CreateRoleInfoInputType>;
};


export type MutationCreateBranchArgs = {
  branchType?: InputMaybe<BranchBranchTypeChoices>;
  nodeIdLink?: InputMaybe<Scalars['ID']['input']>;
  previousNodeId: Scalars['ID']['input'];
  shorthand?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCampaignArgs = {
  companyId: Scalars['ID']['input'];
  end?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  start?: InputMaybe<Scalars['Date']['input']>;
  templateId: Scalars['ID']['input'];
};


export type MutationCreateChoiceBranchArgs = {
  previousNodeId: Scalars['ID']['input'];
  shorthands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreateCompanyAndSetAddressesArgs = {
  addresses: Array<InputMaybe<OrgAddressInputType>>;
  businessId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationCreateCustomerArgs = {
  addresses: Array<InputMaybe<OrgAddressInputType>>;
  businessId: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  contactPersons: ContactPersonsInputType;
  eInvoicingAddress: Scalars['String']['input'];
  eInvoicingOperator: Scalars['String']['input'];
  invoicingAddressCity: Scalars['String']['input'];
  invoicingAddressPostCode: Scalars['String']['input'];
  invoicingAddressStreetAddress: Scalars['String']['input'];
  marketingName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationCreateCustomerAddressArgs = {
  address: OrgAddressInputType;
  customerId: Scalars['ID']['input'];
};


export type MutationCreateCustomerWithContractArgs = {
  contract: ContractBaseInfoInputType;
  customer: CustomerBaseInfoInputType;
};


export type MutationCreateDocumentArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  documentCategoryId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateDocumentCategoryArgs = {
  companyId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  documentCategoryParentId?: InputMaybe<Scalars['ID']['input']>;
  hideOldVersions: Scalars['Boolean']['input'];
  showForCustomerUsers: Scalars['Boolean']['input'];
  showForCustomers: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateDocumentTemplateArgs = {
  name: Scalars['String']['input'];
  treeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateDocumentTemplateItemArgs = {
  position?: InputMaybe<Scalars['Int']['input']>;
  templateId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDocumentVersionArgs = {
  documentId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};


export type MutationCreateEmptyLaboratoryCatalogArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateHealthcareTeamArgs = {
  companyId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  healthcareWorkerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateInsuranceCompanyArgs = {
  businessId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateInvoiceInterfaceArgs = {
  apiKey: Scalars['String']['input'];
  apiUser: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
};


export type MutationCreateInvoicePurchaseCommentArgs = {
  comment: Scalars['String']['input'];
  invoicePurchaseId: Scalars['ID']['input'];
};


export type MutationCreateInvoicesSaleArgs = {
  customerIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationCreateLaboratoryCatalogBasedOnExistingArgs = {
  basedOnCatalogId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateLaboratorySupplierArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  codeId: Scalars['ID']['input'];
  discountPercent?: InputMaybe<Scalars['Decimal']['input']>;
  name: Scalars['String']['input'];
  parentLaboratoryId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
  pricePerVisit?: InputMaybe<Scalars['Decimal']['input']>;
};


export type MutationCreateNorrePackageArgs = {
  contents: Array<InputMaybe<NorrePackageItem>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateOrUpdateLaboratorySalesPriceArgs = {
  catalogId: Scalars['ID']['input'];
  modelId: Scalars['ID']['input'];
  modelTypename: LaboratorySalesPriceModelTypenameChoices;
  price: Scalars['Decimal']['input'];
};


export type MutationCreateOrganizationArgs = {
  businessId: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  offices?: InputMaybe<Array<InputMaybe<OfficeInput>>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePartnerArgs = {
  address: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  phone: Scalars['String']['input'];
};


export type MutationCreatePaymentCommitmentArgs = {
  invoicesForwardingToCustomer: Scalars['Boolean']['input'];
  kelaActionCategory?: InputMaybe<Scalars['String']['input']>;
  nationalId: Scalars['String']['input'];
  partnerId: Scalars['ID']['input'];
  proceduresInput: Array<InputMaybe<PaymentCommitmentProcedureInputType>>;
  reimbursementClass: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  validUntil: Scalars['Date']['input'];
};


export type MutationCreatePrescriptionArgs = {
  prescriptionArguments: PrescriptionCreationArguments;
};


export type MutationCreateProcedureArgs = {
  isEmployeeBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  isKela0?: InputMaybe<Scalars['Boolean']['input']>;
  isKela1?: InputMaybe<Scalars['Boolean']['input']>;
  isKela2?: InputMaybe<Scalars['Boolean']['input']>;
  isRemote?: InputMaybe<Scalars['Boolean']['input']>;
  kelaReportCategory?: InputMaybe<ProcedureKelaReportCategoryChoices>;
  name: Scalars['String']['input'];
  partnerServiceId: Scalars['ID']['input'];
};


export type MutationCreateProcedurePartnerPriceArgs = {
  partnerId: Scalars['ID']['input'];
  price: Scalars['Decimal']['input'];
  procedureId: Scalars['ID']['input'];
};


export type MutationCreateProfessionalGroupArgs = {
  title: Scalars['String']['input'];
};


export type MutationCreateProfileArgs = {
  anyCompany?: InputMaybe<Scalars['Boolean']['input']>;
  anyCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  corporate?: InputMaybe<Scalars['Boolean']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationCreateQuestionArgs = {
  answerChoices: Array<InputMaybe<Scalars['String']['input']>>;
  answerLimitMaximum?: InputMaybe<Scalars['Int']['input']>;
  answerLimitMinimum?: InputMaybe<Scalars['Int']['input']>;
  answerMultiplier?: InputMaybe<Scalars['Decimal']['input']>;
  answerScores?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  answerType: QuestionAnswerTypeChoices;
  file?: InputMaybe<Scalars['Upload']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isSlider?: InputMaybe<Scalars['Boolean']['input']>;
  keyType?: InputMaybe<QuestionKeyTypeChoices>;
  mandatory: Scalars['Boolean']['input'];
  matrixQuestionFactors?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  matrixQuestions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  question: Scalars['String']['input'];
  settingsReady: Scalars['Boolean']['input'];
  sliderStep?: InputMaybe<Scalars['Decimal']['input']>;
  textHelp?: InputMaybe<Scalars['String']['input']>;
  textInfo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateRoleCompanyArgs = {
  companyId: Scalars['ID']['input'];
  profileId: Scalars['ID']['input'];
};


export type MutationCreateRoleCorporateArgs = {
  profileId: Scalars['ID']['input'];
};


export type MutationCreateSalesCatalogArgs = {
  basedOnCatalogId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateSalesContractVersionArgs = {
  contractContactPerson?: InputMaybe<ContactPersonInputType>;
  customerId: Scalars['ID']['input'];
  customerReferenceAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  discounts: DiscountsInputType;
  extraServiceIds: Array<InputMaybe<Scalars['ID']['input']>>;
  isLegacyContract?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfPersonsExposure?: InputMaybe<Scalars['Int']['input']>;
  numberOfPersonsNonExposure?: InputMaybe<Scalars['Int']['input']>;
  prices: ContractPricesInputType;
  receptionChoice?: InputMaybe<ReceptionChoices>;
  salesContractId: Scalars['ID']['input'];
  salesPackageId: Scalars['ID']['input'];
  salesmanUserId: Scalars['ID']['input'];
  urlToPdf?: InputMaybe<Scalars['String']['input']>;
  validFrom: Scalars['Date']['input'];
};


export type MutationCreateServiceEventArgs = {
  businessId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  officeNumber: Scalars['Int']['input'];
  patientEmail: Scalars['String']['input'];
  status?: InputMaybe<ServiceEventStatusInputType>;
};


export type MutationCreateSickLeaveCategoryArgs = {
  icd10Codes: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  overwriteExistingCategoriesCodes?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateSickLeaveNoticeArgs = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  acceptedById?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['Date']['input'];
  icd10Code?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['Date']['input'];
  typeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCreateSickLeavePeriodSettingArgs = {
  allowedSelfReportPeriodMonths: Scalars['Int']['input'];
  allowedSelfReportTimesPerPeriod: Scalars['Int']['input'];
  customerId: Scalars['ID']['input'];
};


export type MutationCreateSickLeaveSelfReportReasonArgs = {
  name: Scalars['String']['input'];
  sickLeaveCategoryId: Scalars['ID']['input'];
};


export type MutationCreateSupplierPackageArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  priceSupplierAndMainTestList?: InputMaybe<Array<InputMaybe<PriceSupplierAndMainTest>>>;
  tests: Array<InputMaybe<TestAndOrder>>;
};


export type MutationCreateTaskArgs = {
  customerId: Scalars['ID']['input'];
  dateEnd?: InputMaybe<Scalars['Date']['input']>;
  dateStart: Scalars['Date']['input'];
  descriptionExternal?: InputMaybe<Scalars['String']['input']>;
  descriptionInternal?: InputMaybe<Scalars['String']['input']>;
  documentCategoryIds: Array<InputMaybe<Scalars['ID']['input']>>;
  documentIds: Array<InputMaybe<Scalars['ID']['input']>>;
  durationCompleted?: InputMaybe<Scalars['Int']['input']>;
  durationEstimate?: InputMaybe<Scalars['Int']['input']>;
  healthcareTeamId?: InputMaybe<Scalars['ID']['input']>;
  isRecurring: Scalars['Boolean']['input'];
  professionalGroupId?: InputMaybe<Scalars['ID']['input']>;
  recurrence: Scalars['Int']['input'];
  recurrenceInterval: TaskRecurrenceInterval;
  salesTaskId?: InputMaybe<Scalars['ID']['input']>;
  scheduling: TaskScheduling;
  state: TaskState;
  taskTopicIds: Array<InputMaybe<Scalars['ID']['input']>>;
  timeEnd?: InputMaybe<Scalars['Time']['input']>;
  timeStart?: InputMaybe<Scalars['Time']['input']>;
  title: Scalars['String']['input'];
  userOwnerId?: InputMaybe<Scalars['ID']['input']>;
  userPatientId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateTaskTopicArgs = {
  title: Scalars['String']['input'];
};


export type MutationCreateTitletemplateArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  template?: InputMaybe<Array<InputMaybe<TitleInputType>>>;
};


export type MutationCreateTreeArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  additionalInfo?: InputMaybe<CustomerUserAdditionalInfo>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  contactInfo?: InputMaybe<UserContactInfoInput>;
  contactPerson?: InputMaybe<CustomerUserContactPersonInput>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  employmentInfo?: InputMaybe<UserEmploymentInformationInput>;
  healthCheckInfo?: InputMaybe<CustomerUserHealthCheckInfo>;
  healthcareServices?: InputMaybe<CustomerUserHealthcareServices>;
  healthcareWorkerAdditionalInfo?: InputMaybe<HealthcareWorkerAdditionalInfo>;
  isHealthcareWorker?: InputMaybe<Scalars['Boolean']['input']>;
  personalInfo?: InputMaybe<UserPersonalInfoInput>;
  profileInfo?: InputMaybe<ProfileInfoInput>;
};


export type MutationCreateViewArgs = {
  title: Scalars['String']['input'];
  viewType: ViewViewTypeChoices;
};


export type MutationDeleteApiTokenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBranchArgs = {
  branchId: Scalars['ID']['input'];
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentCategoryArgs = {
  documentCategoryIdForFiles?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentTemplateItemArgs = {
  id: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};


export type MutationDeleteDocumentVersionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteHealthcareTeamArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvoiceInterfaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLaboratoryCatalogArgs = {
  catalogId: Scalars['ID']['input'];
};


export type MutationDeleteLaboratoryOrderArgs = {
  laboratoryOrderId: Scalars['ID']['input'];
};


export type MutationDeleteLaboratorySalesPriceArgs = {
  catalogId: Scalars['ID']['input'];
  modelId: Scalars['ID']['input'];
  modelTypename: LaboratorySalesPriceModelTypenameChoices;
};


export type MutationDeleteLaboratorySupplierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNodeArgs = {
  deleteFollowing?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationDeleteNorrePackageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePartnerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePaymentCommitmentArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteProcedureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProcedurePartnerPriceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProfessionalGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProfileArgs = {
  profileId: Scalars['ID']['input'];
};


export type MutationDeleteSalesCatalogArgs = {
  catalogId: Scalars['ID']['input'];
};


export type MutationDeleteSickLeaveCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSickLeavePeriodSettingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSickLeaveSelfReportReasonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierPackageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTitletemplateArgs = {
  titleTemplateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteTreeArgs = {
  treeId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeleteViewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEndCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetDigestsForMultiSignatureArgs = {
  documentOidCodes: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationHandleSickLeaveNoticeArgs = {
  accepted: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationLockLaboratoryCatalogArgs = {
  catalogId: Scalars['ID']['input'];
};


export type MutationLockSalesCatalogArgs = {
  catalogId: Scalars['ID']['input'];
};


export type MutationNewSurveyAnswerArgs = {
  treeId: Scalars['ID']['input'];
};


export type MutationPasswordResetArgs = {
  newPassword1?: InputMaybe<Scalars['String']['input']>;
  newPassword2?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPasswordSetArgs = {
  newPassword1?: InputMaybe<Scalars['String']['input']>;
  newPassword2?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPreparePrescriptionForSigningArgs = {
  prescriptionOid: Scalars['String']['input'];
  signatureId: Scalars['String']['input'];
  signatureType?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPreparePrescriptionsForMultiSigningArgs = {
  digestOidPairs: Array<InputMaybe<OidDigestPairInput>>;
  signatureId: Scalars['String']['input'];
};


export type MutationQuestionToNodeArgs = {
  nodeId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};


export type MutationRejectInvoicePurchaseArgs = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationRemoveCampaignSurveyRespondentArgs = {
  campaignSurveyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReplacePrescriptionArgs = {
  creationArguments: PrescriptionCreationArguments;
  replacingArguments: PrescriptionReplacingArguments;
};


export type MutationRepudiatePrescriptionsArgs = {
  consentType?: InputMaybe<Scalars['String']['input']>;
  exportToFile?: InputMaybe<Scalars['String']['input']>;
  prescriptionOidCodes: Array<InputMaybe<Scalars['String']['input']>>;
  repudiationExplanation?: InputMaybe<Scalars['String']['input']>;
  repudiationReason: Scalars['String']['input'];
  repudiationType: Scalars['String']['input'];
  serviceEventOid: Scalars['String']['input'];
};


export type MutationResendLaboratoryOrderArgs = {
  laboratoryOrderId: Scalars['ID']['input'];
};


export type MutationSaveAnswersArgs = {
  answers: Scalars['JSONString']['input'];
  treeId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSendDocumentToKantaArgs = {
  documentOid: Scalars['String']['input'];
  documentType: KantaDocumentType;
};


export type MutationSendPasswordResetEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendPrescriptionRepudiationsArgs = {
  repudiationOidCodes: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationSendUserToAcuteArgs = {
  nationalId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetConditionArgs = {
  branchId: Scalars['ID']['input'];
  condition?: InputMaybe<Array<InputMaybe<StatementGroupInput>>>;
  formula?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  shorthand?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetPermissionsEqualArgs = {
  anyCompany?: InputMaybe<Scalars['Boolean']['input']>;
  anyCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  corporate?: InputMaybe<Scalars['Boolean']['input']>;
  permissionIds: Array<InputMaybe<Scalars['ID']['input']>>;
  profileId: Scalars['ID']['input'];
};


export type MutationSmartCardLoginArgs = {
  cert: Scalars['String']['input'];
  digestMethod: Scalars['String']['input'];
  signatureValue: Scalars['String']['input'];
};


export type MutationStartCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTerminateContractArgs = {
  changeReasonExtraInfo: Scalars['String']['input'];
  changeReasonId: Scalars['ID']['input'];
  contractEndDate: Scalars['Date']['input'];
  customerId: Scalars['ID']['input'];
};


export type MutationTokenAuthArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateCampaignArgs = {
  end?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  start?: InputMaybe<Scalars['Date']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateCompanyAndSetAddressesArgs = {
  addresses: Array<InputMaybe<OrgAddressInputType>>;
  businessId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCustomerArgs = {
  accountingPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  accountingPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  addedToAcuteAt?: InputMaybe<Scalars['DateTime']['input']>;
  addresses?: InputMaybe<Array<InputMaybe<OrgAddressInputType>>>;
  attachmentAddressCity?: InputMaybe<Scalars['String']['input']>;
  attachmentAddressEmail?: InputMaybe<Scalars['String']['input']>;
  attachmentAddressPostCode?: InputMaybe<Scalars['String']['input']>;
  attachmentAddressStreetAddress?: InputMaybe<Scalars['String']['input']>;
  businessId?: InputMaybe<Scalars['String']['input']>;
  contactPersons?: InputMaybe<ContactPersonsInputType>;
  descriptionOfOperations?: InputMaybe<Scalars['String']['input']>;
  descriptionOfOrganization?: InputMaybe<Scalars['String']['input']>;
  descriptionOfStaff?: InputMaybe<Scalars['String']['input']>;
  dueDateActionPlan?: InputMaybe<Scalars['Date']['input']>;
  dueDateWorkplaceAssessment?: InputMaybe<Scalars['Date']['input']>;
  eInvoicingAddress?: InputMaybe<Scalars['String']['input']>;
  eInvoicingOperator?: InputMaybe<Scalars['String']['input']>;
  entrepreneurInsuranceType?: InputMaybe<EntrepreneurInsuranceType>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  insurances?: InputMaybe<Array<InsuranceInputType>>;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressCity?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressPostCode?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressStreetAddress?: InputMaybe<Scalars['String']['input']>;
  latestKelaReportAt?: InputMaybe<Scalars['Date']['input']>;
  marketingName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  occupationalHealthNeeds?: InputMaybe<Scalars['String']['input']>;
  occupationalSafetyNeeds?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  responsibleDoctorId?: InputMaybe<Scalars['ID']['input']>;
  responsibleNurseId?: InputMaybe<Scalars['ID']['input']>;
  responsibleTeamId?: InputMaybe<Scalars['ID']['input']>;
  taxFreeBenefitsInstructionsGiven?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateCustomerInsurancesArgs = {
  entrepreneurInsuranceType?: InputMaybe<EntrepreneurInsuranceType>;
  id: Scalars['ID']['input'];
  insurances?: InputMaybe<Array<InsuranceInputType>>;
  processed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateCustomerInvoicingInformationArgs = {
  contactPerson?: InputMaybe<ContactPersonInputType>;
  eInvoicingAddress?: InputMaybe<Scalars['String']['input']>;
  eInvoicingOperator?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  invoicingAddressCity?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressPostCode?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressStreetAddress?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCustomerServiceSettingsArgs = {
  costSettings: CostSettingsInputType;
  customerId: Scalars['ID']['input'];
  partnerNetworkUsage: Scalars['String']['input'];
  partnerServiceSettings: Array<InputMaybe<PartnerServiceSettingInputType>>;
  useKelaReimbursement: Scalars['Boolean']['input'];
  voluntaryHealthCheck: Scalars['Boolean']['input'];
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDocumentCategoryArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  documentCategoryParentId?: InputMaybe<Scalars['ID']['input']>;
  hideOldVersions?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  showForCustomerUsers?: InputMaybe<Scalars['Boolean']['input']>;
  showForCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDocumentTemplateArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  treeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationUpdateDocumentTemplateItemArgs = {
  condition?: InputMaybe<Array<InputMaybe<StatementGroupInput>>>;
  id: Scalars['ID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  templateId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateHealthcareTeamArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  healthcareWorkerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateInsuranceCompanyArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateInvoiceInterfaceArgs = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiUser?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateInvoicePurchaseArgs = {
  id: Scalars['ID']['input'];
  paymentCommitmentId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateInvoicePurchaseRowArgs = {
  id: Scalars['ID']['input'];
  kelaOneServiceId?: InputMaybe<Scalars['ID']['input']>;
  kelaTwoServiceId?: InputMaybe<Scalars['ID']['input']>;
  serviceId?: InputMaybe<Scalars['ID']['input']>;
  sumGrossOverride?: InputMaybe<Scalars['Decimal']['input']>;
};


export type MutationUpdateLaboratoryCatalogArgs = {
  activeFrom?: InputMaybe<Scalars['Date']['input']>;
  catalogId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateLaboratorySalesPriceAvailabilityArgs = {
  catalogId: Scalars['ID']['input'];
  isAvailable: Scalars['Boolean']['input'];
  modelId: Scalars['ID']['input'];
  modelTypename: LaboratorySalesPriceModelTypenameChoices;
};


export type MutationUpdateLaboratorySupplierArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  discountPercent?: InputMaybe<Scalars['Decimal']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  pricePerVisit?: InputMaybe<Scalars['Decimal']['input']>;
  supplierId: Scalars['ID']['input'];
};


export type MutationUpdateLaboratoryTestArgs = {
  codeId: Scalars['ID']['input'];
  descriptionLong?: InputMaybe<Scalars['String']['input']>;
  descriptionShort?: InputMaybe<Scalars['String']['input']>;
  pricesAndSuppliers: Array<InputMaybe<SupplierAndPrice>>;
};


export type MutationUpdateMyPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationUpdateNodeArgs = {
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupLabelsToCreate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  nodeIdLink?: InputMaybe<Scalars['ID']['input']>;
  questionOverride?: InputMaybe<Scalars['String']['input']>;
  shorthand?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateNorrePackageArgs = {
  contents: Array<InputMaybe<NorrePackageItem>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateOwnPersonalInformationArgs = {
  ownPersonalInformation?: InputMaybe<UserOwnPersonalInformationInputType>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdatePartnerArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  businessId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePermissionsArgs = {
  deniedPermissionIds: Array<InputMaybe<Scalars['ID']['input']>>;
  grantedPermissionIds: Array<InputMaybe<Scalars['ID']['input']>>;
  profileId: Scalars['ID']['input'];
};


export type MutationUpdateProcedureArgs = {
  id: Scalars['ID']['input'];
  isEmployeeBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  isKela0?: InputMaybe<Scalars['Boolean']['input']>;
  isKela1?: InputMaybe<Scalars['Boolean']['input']>;
  isKela2?: InputMaybe<Scalars['Boolean']['input']>;
  isRemote?: InputMaybe<Scalars['Boolean']['input']>;
  kelaReportCategory?: InputMaybe<ProcedureKelaReportCategoryChoices>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateProcedurePartnerPriceArgs = {
  id: Scalars['ID']['input'];
  price?: InputMaybe<Scalars['Decimal']['input']>;
};


export type MutationUpdateProfessionalGroupArgs = {
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateProfileNameArgs = {
  name: Scalars['String']['input'];
  profileId: Scalars['ID']['input'];
};


export type MutationUpdateQuestionArgs = {
  answerChoices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  answerLimitMaximum?: InputMaybe<Scalars['Int']['input']>;
  answerLimitMinimum?: InputMaybe<Scalars['Int']['input']>;
  answerMultiplier?: InputMaybe<Scalars['Decimal']['input']>;
  answerScores?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  answerType?: InputMaybe<QuestionAnswerTypeChoices>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['ID']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  isSlider?: InputMaybe<Scalars['Boolean']['input']>;
  keyType?: InputMaybe<QuestionKeyTypeChoices>;
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  matrixQuestionFactors?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  matrixQuestions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  settingsReady?: InputMaybe<Scalars['Boolean']['input']>;
  sliderStep?: InputMaybe<Scalars['Decimal']['input']>;
  textHelp?: InputMaybe<Scalars['String']['input']>;
  textInfo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSalesCatalogArgs = {
  activeFrom?: InputMaybe<Scalars['Date']['input']>;
  catalogId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateSalesPriceArgs = {
  customerPricePerMonth?: InputMaybe<Scalars['Decimal']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  salesPriceId: Scalars['ID']['input'];
  userPricePerMonth?: InputMaybe<Scalars['Decimal']['input']>;
};


export type MutationUpdateSickLeaveCategoryArgs = {
  icd10Codes: Array<InputMaybe<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  overwriteExistingCategoriesCodes?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateSickLeaveNoticeArgs = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  acceptedById?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['Date']['input'];
  icd10Code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['Date']['input'];
  typeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateSickLeavePeriodSettingArgs = {
  allowedSelfReportPeriodMonths?: InputMaybe<Scalars['Int']['input']>;
  allowedSelfReportTimesPerPeriod?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateSickLeaveSelfReportReasonArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sickLeaveCategoryId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateSickLeaveSettingsCustomerArgs = {
  allowedSelfReportDurationContinuously?: InputMaybe<Scalars['Int']['input']>;
  allowedSelfReportDurationPerNotification?: InputMaybe<Scalars['Int']['input']>;
  allowedSelfReportReasonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  customerId: Scalars['ID']['input'];
};


export type MutationUpdateSupplierPackageArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  priceSupplierAndMainTestList: Array<InputMaybe<PriceSupplierAndMainTest>>;
  tests: Array<InputMaybe<TestAndOrder>>;
};


export type MutationUpdateTaskArgs = {
  dateEnd?: InputMaybe<Scalars['Date']['input']>;
  dateStart?: InputMaybe<Scalars['Date']['input']>;
  descriptionExternal?: InputMaybe<Scalars['String']['input']>;
  descriptionInternal?: InputMaybe<Scalars['String']['input']>;
  documentCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  durationCompleted?: InputMaybe<Scalars['Int']['input']>;
  durationEstimate?: InputMaybe<Scalars['Int']['input']>;
  healthcareTeamId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  professionalGroupId?: InputMaybe<Scalars['ID']['input']>;
  recurrence?: InputMaybe<Scalars['Int']['input']>;
  recurrenceInterval?: InputMaybe<TaskRecurrenceInterval>;
  scheduling?: InputMaybe<TaskScheduling>;
  state?: InputMaybe<TaskState>;
  taskTopicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  timeEnd?: InputMaybe<Scalars['Time']['input']>;
  timeStart?: InputMaybe<Scalars['Time']['input']>;
  updateRecurringFutureTasks?: InputMaybe<Scalars['Boolean']['input']>;
  userOwnerId?: InputMaybe<Scalars['ID']['input']>;
  userPatientId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateTreeArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  endText?: InputMaybe<Scalars['String']['input']>;
  endTitle?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  welcomeText?: InputMaybe<Scalars['String']['input']>;
  welcomeTitle?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  additionalInfo?: InputMaybe<CustomerUserAdditionalInfo>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  contactInfo?: InputMaybe<UserContactInfoInput>;
  contactPerson?: InputMaybe<CustomerUserContactPersonInput>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  employmentInfo?: InputMaybe<UserEmploymentInformationInput>;
  healthCheckInfo?: InputMaybe<CustomerUserHealthCheckInfo>;
  healthcareServices?: InputMaybe<CustomerUserHealthcareServices>;
  healthcareWorkerAdditionalInfo?: InputMaybe<HealthcareWorkerAdditionalInfo>;
  isCorporateUser?: InputMaybe<Scalars['Boolean']['input']>;
  isHealthcareWorker?: InputMaybe<Scalars['Boolean']['input']>;
  personalInfo?: InputMaybe<UserPersonalInfoInput>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateUserInsurancesArgs = {
  insurances?: InputMaybe<Array<InsuranceInputType>>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateUserRolesArgs = {
  corporateProfileIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roleCompanyIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roleCorporateIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roleCustomerIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  rolesCompanyToAdd?: InputMaybe<Array<InputMaybe<CreateRoleInfoInputType>>>;
  rolesCustomerToAdd?: InputMaybe<Array<InputMaybe<CreateRoleInfoInputType>>>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateViewArgs = {
  id: Scalars['ID']['input'];
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewViewTypeChoices>;
};

export type NewSurveyAnswer = {
  __typename?: 'NewSurveyAnswer';
  treeResult?: Maybe<TreeResultType>;
};

export type NodeGroupType = {
  __typename?: 'NodeGroupType';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  nodes: Array<NodeType>;
  tag: Scalars['String']['output'];
};

export type NodeType = {
  __typename?: 'NodeType';
  groups: Array<NodeGroupType>;
  id: Scalars['ID']['output'];
  link?: Maybe<NodeType>;
  links: Array<NodeType>;
  nextBranches: Array<BranchType>;
  previousBranch?: Maybe<BranchType>;
  question?: Maybe<QuestionType>;
  questionOverride: Scalars['String']['output'];
  rootOfTree?: Maybe<TreeType>;
  shorthand: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NorreAuthToken = {
  __typename?: 'NorreAuthToken';
  accessTokenExpiresAt: Scalars['DateTime']['output'];
  refreshTokenExpiresAt: Scalars['DateTime']['output'];
};

export type NorrePackageItem = {
  codeId?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
  supplierPackageId?: InputMaybe<Scalars['ID']['input']>;
};

export type NorrePackagePrice = {
  __typename?: 'NorrePackagePrice';
  price?: Maybe<Scalars['Decimal']['output']>;
  supplier: LaboratorySupplierType;
};

export type NorrePackagePriceWithPackage = AddNorrePackageInterface & {
  __typename?: 'NorrePackagePriceWithPackage';
  norrePackage: NorrePackageType;
  prices: Array<NorrePackagePrice>;
};

export type NorrePackageTestType = {
  __typename?: 'NorrePackageTestType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  laboratoryTest?: Maybe<LaboratoryTestType>;
  norrePackage: NorrePackageType;
  order: Scalars['Int']['output'];
  supplierPackage?: Maybe<SupplierPackageType>;
};

export type NorrePackageType = {
  __typename?: 'NorrePackageType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  norrepackagetestSet: Array<NorrePackageTestType>;
  salesPrices: Array<LaboratoryCatalogSalesPriceType>;
};

export type OidDigestPair = {
  __typename?: 'OIDDigestPair';
  /** Digest of the document. */
  digest: Scalars['String']['output'];
  /** Unique identifier of the document. */
  oid: Scalars['String']['output'];
};

export type OidDigestPairInput = {
  /** Digest of the document. */
  digest: Scalars['String']['input'];
  /** Unique identifier of the document. */
  oid: Scalars['String']['input'];
};

export type OfficeInput = {
  city: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orderNumber: Scalars['Int']['input'];
  postalCode: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
};

export type OfficeOutput = {
  __typename?: 'OfficeOutput';
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderNumber: Scalars['Int']['output'];
  postalCode: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
};

export type OrgAddressInputType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  phone: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  businessId: Scalars['String']['output'];
  businessName: Scalars['String']['output'];
  city: Scalars['String']['output'];
  offices?: Maybe<Array<Maybe<OfficeOutput>>>;
  postalCode: Scalars['String']['output'];
  professionals: Array<Professional>;
  serviceeventSet: Array<ServiceEvent>;
  streetAddress: Scalars['String']['output'];
};

/** An enumeration. */
export enum OrganizationsContactPersonContactTypeChoices {
  /** Contract */
  Contract = 'CONTRACT',
  /** Invoicing */
  Invoicing = 'INVOICING',
  /** Occupational Health */
  OccupationalHealth = 'OCCUPATIONAL_HEALTH'
}

/** An enumeration. */
export enum OrganizationsCustomerEntrepreneurInsuranceTypeChoices {
  /** Myel */
  Myel = 'MYEL',
  /** Yel */
  Yel = 'YEL'
}

/** Value belongs to codesystem 1.2.246.537.5.40118 values. */
export type PkvType = {
  __typename?: 'PKVType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type PackageAmountArguments = {
  /** Specifies assigned drug packages amount. */
  packageAmount: Scalars['Int']['input'];
};

export type PackageDetailsArguments = {
  /** Specifies drug amount in package or package size. */
  quantity: Scalars['Float']['input'];
  /** Specifies unit for drug quantity. */
  unit: Scalars['String']['input'];
};

export type PartnerServiceSettingInputType = {
  customerServiceSettingId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  maximumLengthTreatmentSeries?: InputMaybe<Scalars['Int']['input']>;
  maximumUsagePerYear?: InputMaybe<Scalars['Int']['input']>;
  partnerServiceId?: InputMaybe<Scalars['ID']['input']>;
  procedureId?: InputMaybe<Scalars['ID']['input']>;
  reimbursementClass?: InputMaybe<Scalars['String']['input']>;
  subjectToExpenseRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  usageAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartnerServiceSettingType = {
  __typename?: 'PartnerServiceSettingType';
  createdAt: Scalars['DateTime']['output'];
  customerServiceSetting: CustomerServiceSettingsType;
  id: Scalars['ID']['output'];
  maximumLengthTreatmentSeries?: Maybe<Scalars['Int']['output']>;
  maximumUsagePerYear?: Maybe<Scalars['Int']['output']>;
  partnerService?: Maybe<PartnerServiceType>;
  procedure?: Maybe<ProcedureType>;
  reimbursementClass: PartnerservicesPartnerServiceSettingReimbursementClassChoices;
  subjectToExpenseRestrictions: Scalars['Boolean']['output'];
  usageAllowed: Scalars['Boolean']['output'];
};

export type PartnerServiceType = {
  __typename?: 'PartnerServiceType';
  id: Scalars['ID']['output'];
  partnerServiceSettings: Array<PartnerServiceSettingType>;
  procedureSet: Array<ProcedureType>;
  serviceType: PartnerservicesPartnerServiceServiceTypeChoices;
};

export type PartnerType = {
  __typename?: 'PartnerType';
  address: Scalars['String']['output'];
  businessId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partnerChildren: Array<PartnerType>;
  partnerParent?: Maybe<PartnerType>;
  paymentCommitments: Array<PaymentCommitmentType>;
  phone: Scalars['String']['output'];
  procedurepartnerpriceSet: Array<ProcedurePartnerPriceType>;
};

/** An enumeration. */
export enum PartnerservicesCustomerServiceSettingsPartnerNetworkUsageChoices {
  /** Full */
  Full = 'FULL',
  /** Limited */
  Limited = 'LIMITED'
}

/** An enumeration. */
export enum PartnerservicesPartnerServiceServiceTypeChoices {
  /** Dental */
  Dental = 'DENTAL',
  /** Examinations Consultations */
  ExaminationsConsultations = 'EXAMINATIONS_CONSULTATIONS',
  /** Other */
  Other = 'OTHER',
  /** Physiotherapist */
  Physiotherapist = 'PHYSIOTHERAPIST',
  /** Procedure */
  Procedure = 'PROCEDURE',
  /** Psychologist */
  Psychologist = 'PSYCHOLOGIST',
  /** Psychotherapy */
  Psychotherapy = 'PSYCHOTHERAPY'
}

/** An enumeration. */
export enum PartnerservicesPartnerServiceSettingReimbursementClassChoices {
  /** Kela One */
  KelaOne = 'KELA_ONE',
  /** Kela Two */
  KelaTwo = 'KELA_TWO',
  /** Kela Zero */
  KelaZero = 'KELA_ZERO',
  /** Staff Benefit */
  StaffBenefit = 'STAFF_BENEFIT'
}

/** An enumeration. */
export enum PartnerservicesProcedureKelaReportCategoryChoices {
  /** Agriculture */
  Agriculture = 'AGRICULTURE',
  /** Doctor */
  Doctor = 'DOCTOR',
  /** Ergonomics */
  Ergonomics = 'ERGONOMICS',
  /** Exercise */
  Exercise = 'EXERCISE',
  /** Hygiene */
  Hygiene = 'HYGIENE',
  /** Imaging */
  Imaging = 'IMAGING',
  /** Laboratory */
  Laboratory = 'LABORATORY',
  /** Nurse */
  Nurse = 'NURSE',
  /** Nutrition */
  Nutrition = 'NUTRITION',
  /** Physiotherapist */
  Physiotherapist = 'PHYSIOTHERAPIST',
  /** Psychologist */
  Psychologist = 'PSYCHOLOGIST',
  /** Social */
  Social = 'SOCIAL',
  /** Specialist */
  Specialist = 'SPECIALIST',
  /** Speech Therapy */
  SpeechTherapy = 'SPEECH_THERAPY',
  /** Technical */
  Technical = 'TECHNICAL',
  /** Vision */
  Vision = 'VISION'
}

export type PasswordReset = {
  __typename?: 'PasswordReset';
  errors?: Maybe<Scalars['JSONString']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PasswordSet = {
  __typename?: 'PasswordSet';
  errors?: Maybe<Scalars['JSONString']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PatientGeneralInfo = {
  __typename?: 'PatientGeneralInfo';
  birthDate?: Maybe<Scalars['Date']['output']>;
  familyName: Scalars['String']['output'];
  firstNames: Array<Maybe<Scalars['String']['output']>>;
};

export type PatientIdentificationInput = {
  /** Gives a free explanation of the identification method. Required if method code is 9(=custom method) */
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Identification method, the value belongs to codeserver 1.2.246.537.5.40109 codes. */
  methodCode: Scalars['String']['input'];
};

export type PatientIdentificationOutput = {
  __typename?: 'PatientIdentificationOutput';
  /** Gives a free explanation of the identification method. Required if method code is 9(=custom method) */
  explanation?: Maybe<Scalars['String']['output']>;
  /** Identification method, the value belongs to codeserver 1.2.246.537.5.40109 codes. */
  methodCode: Scalars['String']['output'];
};

/** Value belongs to codesystem 1.2.246.537.5.40109 values. */
export type PatientIdentificationType = {
  __typename?: 'PatientIdentificationType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

/** An enumeration. */
export enum PatientPatientGenderChoices {
  /** male */
  A_1 = 'A_1',
  /** female */
  A_2 = 'A_2',
  /** unknown */
  A_3 = 'A_3',
  /** undefined */
  A_9 = 'A_9'
}

export type PatientType = {
  __typename?: 'PatientType';
  birthDate: Scalars['String']['output'];
  calledByName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstNames: Array<Scalars['String']['output']>;
  gender: PatientPatientGenderChoices;
  laboratoryorderSet: Array<LaboratoryOrderType>;
  lastName: Scalars['String']['output'];
  personalId: Scalars['String']['output'];
  serviceeventSet: Array<ServiceEvent>;
};

export type PaymentCommitmentActionLogType = {
  __typename?: 'PaymentCommitmentActionLogType';
  action: PaymentcommitmentsPaymentCommitmentActionLogActionChoices;
  id: Scalars['ID']['output'];
  paymentCommitment: PaymentCommitmentType;
  reason: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  user: UserType;
};

export type PaymentCommitmentProcedureInputType = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  procedureId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentCommitmentProcedureType = {
  __typename?: 'PaymentCommitmentProcedureType';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  paymentCommitment: PaymentCommitmentType;
  procedure: ProcedureType;
};

export type PaymentCommitmentType = {
  __typename?: 'PaymentCommitmentType';
  costLimit: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extendedProcedures: Array<PaymentCommitmentProcedureType>;
  id: Scalars['ID']['output'];
  invoicesForwardingToCustomer: Scalars['Boolean']['output'];
  kelaActionCategory?: Maybe<PaymentcommitmentsPaymentCommitmentKelaActionCategoryChoices>;
  parent?: Maybe<PaymentCommitmentType>;
  partner: PartnerType;
  paymentcommitmentSet: Array<PaymentCommitmentType>;
  paymentcommitmentactionlogSet: Array<PaymentCommitmentActionLogType>;
  paymentcommitmentprocedureSet: Array<PaymentCommitmentProcedureType>;
  procedures: Array<ProcedureType>;
  referenceNumber: Scalars['String']['output'];
  reimbursementClass: PaymentcommitmentsPaymentCommitmentReimbursementClassChoices;
  user: UserType;
  validUntil: Scalars['Date']['output'];
};

/** An enumeration. */
export enum PaymentcommitmentsPaymentCommitmentActionLogActionChoices {
  /** Copy Text */
  CopyText = 'COPY_TEXT',
  /** Create Pdf */
  CreatePdf = 'CREATE_PDF',
  /** Edit */
  Edit = 'EDIT',
  /** Send Email */
  SendEmail = 'SEND_EMAIL'
}

/** An enumeration. */
export enum PaymentcommitmentsPaymentCommitmentKelaActionCategoryChoices {
  /** Custom Health Check */
  CustomHealthCheck = 'CUSTOM_HEALTH_CHECK',
  /** Exposure Health Check */
  ExposureHealthCheck = 'EXPOSURE_HEALTH_CHECK',
  /** Group Advice And Guidance */
  GroupAdviceAndGuidance = 'GROUP_ADVICE_AND_GUIDANCE',
  /** Individual Advice And Guidance */
  IndividualAdviceAndGuidance = 'INDIVIDUAL_ADVICE_AND_GUIDANCE',
  /** Medical Care */
  MedicalCare = 'MEDICAL_CARE',
  /** Other Healthcare */
  OtherHealthcare = 'OTHER_HEALTHCARE',
  /** Workplace Assessment */
  WorkplaceAssessment = 'WORKPLACE_ASSESSMENT'
}

/** An enumeration. */
export enum PaymentcommitmentsPaymentCommitmentReimbursementClassChoices {
  /** Kela One */
  KelaOne = 'KELA_ONE',
  /** Kela Two */
  KelaTwo = 'KELA_TWO',
  /** Kela Zero */
  KelaZero = 'KELA_ZERO',
  /** Staff Benefit */
  StaffBenefit = 'STAFF_BENEFIT'
}

/** An enumeration. */
export enum PermissionChoices {
  AddApitoken = 'add_apitoken',
  AddCampaign = 'add_campaign',
  AddCdadocument = 'add_cdadocument',
  AddCompany = 'add_company',
  AddContenttype = 'add_contenttype',
  AddCustomer = 'add_customer',
  AddDocument = 'add_document',
  AddDocumenttemplate = 'add_documenttemplate',
  AddEmployeegroup = 'add_employeegroup',
  AddGroup = 'add_group',
  AddHealthcareteam = 'add_healthcareteam',
  AddHealthcheck = 'add_healthcheck',
  AddInsurance = 'add_insurance',
  AddInvoiceinterface = 'add_invoiceinterface',
  AddInvoicelogpurchase = 'add_invoicelogpurchase',
  AddInvoicelogsale = 'add_invoicelogsale',
  AddInvoicepurchase = 'add_invoicepurchase',
  AddInvoicesale = 'add_invoicesale',
  AddLaboratorycatalog = 'add_laboratorycatalog',
  AddLaboratorysupplier = 'add_laboratorysupplier',
  AddLaboratorytest = 'add_laboratorytest',
  AddLogentry = 'add_logentry',
  AddPartner = 'add_partner',
  AddPaymentcommitment = 'add_paymentcommitment',
  AddPermission = 'add_permission',
  AddProcedure = 'add_procedure',
  AddProfile = 'add_profile',
  AddSalescatalog = 'add_salescatalog',
  AddSalescontract = 'add_salescontract',
  AddSession = 'add_session',
  AddSickleavecategory = 'add_sickleavecategory',
  AddSickleavenotice = 'add_sickleavenotice',
  AddSickleavesettingscustomer = 'add_sickleavesettingscustomer',
  AddSickleavetype = 'add_sickleavetype',
  AddTask = 'add_task',
  AddTitletemplate = 'add_titletemplate',
  AddTree = 'add_tree',
  AddTreeresult = 'add_treeresult',
  AddUser = 'add_user',
  AddView = 'add_view',
  ChangeCampaign = 'change_campaign',
  ChangeCdadocument = 'change_cdadocument',
  ChangeCompany = 'change_company',
  ChangeContenttype = 'change_contenttype',
  ChangeCustomer = 'change_customer',
  ChangeCustomerservicesettings = 'change_customerservicesettings',
  ChangeDocument = 'change_document',
  ChangeDocumenttemplate = 'change_documenttemplate',
  ChangeEmployeegroup = 'change_employeegroup',
  ChangeGroup = 'change_group',
  ChangeHealthcareteam = 'change_healthcareteam',
  ChangeHealthcheck = 'change_healthcheck',
  ChangeInsurance = 'change_insurance',
  ChangeInsurancecompany = 'change_insurancecompany',
  ChangeInvoicePurchaseRowSumOverride = 'change_invoice_purchase_row_sum_override',
  ChangeInvoiceinterface = 'change_invoiceinterface',
  ChangeInvoicelogpurchase = 'change_invoicelogpurchase',
  ChangeInvoicelogsale = 'change_invoicelogsale',
  ChangeInvoicepurchase = 'change_invoicepurchase',
  ChangeInvoicesale = 'change_invoicesale',
  ChangeLaboratorycatalog = 'change_laboratorycatalog',
  ChangeLaboratorysupplier = 'change_laboratorysupplier',
  ChangeLaboratorytest = 'change_laboratorytest',
  ChangeLogentry = 'change_logentry',
  ChangePartner = 'change_partner',
  ChangePaymentcommitment = 'change_paymentcommitment',
  ChangePermission = 'change_permission',
  ChangeProcedure = 'change_procedure',
  ChangeProfile = 'change_profile',
  ChangeSalescatalog = 'change_salescatalog',
  ChangeSalescontract = 'change_salescontract',
  ChangeSession = 'change_session',
  ChangeSickleavecategory = 'change_sickleavecategory',
  ChangeSickleavenotice = 'change_sickleavenotice',
  ChangeSickleavesettingscustomer = 'change_sickleavesettingscustomer',
  ChangeSickleavetype = 'change_sickleavetype',
  ChangeTask = 'change_task',
  ChangeTitletemplate = 'change_titletemplate',
  ChangeTree = 'change_tree',
  ChangeTreeresult = 'change_treeresult',
  ChangeUser = 'change_user',
  ChangeUserhealthcheckinformation = 'change_userhealthcheckinformation',
  ChangeView = 'change_view',
  DeleteApitoken = 'delete_apitoken',
  DeleteCampaign = 'delete_campaign',
  DeleteCdadocument = 'delete_cdadocument',
  DeleteCompany = 'delete_company',
  DeleteContenttype = 'delete_contenttype',
  DeleteCustomer = 'delete_customer',
  DeleteDocument = 'delete_document',
  DeleteDocumenttemplate = 'delete_documenttemplate',
  DeleteEmployeegroup = 'delete_employeegroup',
  DeleteGroup = 'delete_group',
  DeleteHealthcareteam = 'delete_healthcareteam',
  DeleteHealthcheck = 'delete_healthcheck',
  DeleteInsurance = 'delete_insurance',
  DeleteInvoiceinterface = 'delete_invoiceinterface',
  DeleteInvoicelogpurchase = 'delete_invoicelogpurchase',
  DeleteInvoicelogsale = 'delete_invoicelogsale',
  DeleteInvoicepurchase = 'delete_invoicepurchase',
  DeleteInvoicesale = 'delete_invoicesale',
  DeleteLaboratorycatalog = 'delete_laboratorycatalog',
  DeleteLaboratorysupplier = 'delete_laboratorysupplier',
  DeleteLaboratorytest = 'delete_laboratorytest',
  DeleteLogentry = 'delete_logentry',
  DeletePartner = 'delete_partner',
  DeletePaymentcommitment = 'delete_paymentcommitment',
  DeletePermission = 'delete_permission',
  DeleteProcedure = 'delete_procedure',
  DeleteProfile = 'delete_profile',
  DeleteSalescatalog = 'delete_salescatalog',
  DeleteSalescontract = 'delete_salescontract',
  DeleteSession = 'delete_session',
  DeleteSickleavecategory = 'delete_sickleavecategory',
  DeleteSickleavenotice = 'delete_sickleavenotice',
  DeleteSickleavesettingscustomer = 'delete_sickleavesettingscustomer',
  DeleteSickleavetype = 'delete_sickleavetype',
  DeleteTask = 'delete_task',
  DeleteTitletemplate = 'delete_titletemplate',
  DeleteTree = 'delete_tree',
  DeleteTreeresult = 'delete_treeresult',
  DeleteUser = 'delete_user',
  DeleteView = 'delete_view',
  ManageUserRoles = 'manage_user_roles',
  ViewApitoken = 'view_apitoken',
  ViewCampaign = 'view_campaign',
  ViewCdadocument = 'view_cdadocument',
  ViewCompany = 'view_company',
  ViewContenttype = 'view_contenttype',
  ViewCustomer = 'view_customer',
  ViewCustomerservicesettings = 'view_customerservicesettings',
  ViewDocument = 'view_document',
  ViewDocumenttemplate = 'view_documenttemplate',
  ViewEmployeegroup = 'view_employeegroup',
  ViewGroup = 'view_group',
  ViewHealthcareteam = 'view_healthcareteam',
  ViewHealthcheck = 'view_healthcheck',
  ViewInsurance = 'view_insurance',
  ViewInvoiceinterface = 'view_invoiceinterface',
  ViewInvoicelogpurchase = 'view_invoicelogpurchase',
  ViewInvoicelogsale = 'view_invoicelogsale',
  ViewInvoicepurchase = 'view_invoicepurchase',
  ViewInvoicesale = 'view_invoicesale',
  ViewLaboratorycatalog = 'view_laboratorycatalog',
  ViewLaboratorysupplier = 'view_laboratorysupplier',
  ViewLaboratorytest = 'view_laboratorytest',
  ViewLogentry = 'view_logentry',
  ViewPartner = 'view_partner',
  ViewPaymentcommitment = 'view_paymentcommitment',
  ViewPermission = 'view_permission',
  ViewProcedure = 'view_procedure',
  ViewProfile = 'view_profile',
  ViewRolecompany = 'view_rolecompany',
  ViewRolecorporate = 'view_rolecorporate',
  ViewRolecustomer = 'view_rolecustomer',
  ViewSalescatalog = 'view_salescatalog',
  ViewSalescontract = 'view_salescontract',
  ViewSalespackage = 'view_salespackage',
  ViewSession = 'view_session',
  ViewSickleavenotice = 'view_sickleavenotice',
  ViewSickleavesettingscustomer = 'view_sickleavesettingscustomer',
  ViewTask = 'view_task',
  ViewTitletemplate = 'view_titletemplate',
  ViewTree = 'view_tree',
  ViewTreeresult = 'view_treeresult',
  ViewUser = 'view_user',
  ViewUserhealthcheckinformation = 'view_userhealthcheckinformation',
  ViewUserinformationviewlog = 'view_userinformationviewlog',
  ViewUsersynclog = 'view_usersynclog',
  ViewView = 'view_view'
}

export type PermissionType = {
  __typename?: 'PermissionType';
  codename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profiles: Array<ProfileType>;
  views: Array<ViewType>;
};

export type PreparePrescriptionForSigning = {
  __typename?: 'PreparePrescriptionForSigning';
  /** Digest to be signed with a smartcard */
  digest: Scalars['String']['output'];
  /** Prepared prescription OID-code */
  oid: Scalars['String']['output'];
};

export type PreparePrescriptionsForMultiSigning = {
  __typename?: 'PreparePrescriptionsForMultiSigning';
  /** Digest of multisignature to be send to smartcard reader. */
  digest: Scalars['String']['output'];
  /** Prepared documents where signature value should added after signing digest in smartcard reader. */
  preparedDocumentOidCodes: Array<Maybe<Scalars['String']['output']>>;
};

export type PrepareSmartCardLogin = {
  __typename?: 'PrepareSmartCardLogin';
  challenge: Scalars['String']['output'];
};

/** An enumeration. */
export enum PrescriptionClassification {
  DrugRegisterExternal = 'DRUG_REGISTER_EXTERNAL',
  DrugRegisterInternal = 'DRUG_REGISTER_INTERNAL',
  Extempore = 'EXTEMPORE'
}

export type PrescriptionCreationArguments = {
  /** Arguments specified for each type of prescription. */
  commonArguments: PrescriptionCreationCommonArguments;
  /** Arguments that should be defined or undefined depending on prescription classification and assigned amount representation type. */
  specificArguments: PrescriptionCreationTypeSpecificArguments;
};

export type PrescriptionCreationCommonArguments = {
  /** Whether medicine is assigned by determining drug package amount, medicine total amount or for time period. */
  assignedAmountRepresentation: AssignedAmountRepresentation;
  /** The professional who is doing current replacing operation */
  authorId: Scalars['String']['input'];
  /** Whether medicine is dosed using dose distribution. */
  doseDistribution: Scalars['Boolean']['input'];
  /** Not related to kanta, specifies shorthand of dosing which is displayed in medicinelist to help professionals get good overview of medication quickly.. */
  dosingShorthand?: InputMaybe<Scalars['String']['input']>;
  /** Describes how should medicine being used/dosed */
  dosingText: Scalars['String']['input'];
  /** Whether is allowed to use different but similar drug instead of the assigned medicine. */
  drugExchangeProhibition: ProhibitionInput;
  drugIsUnderInfectiousDiseaseLaw: Scalars['Boolean']['input'];
  /** Expiration date of the new prescription */
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Specifies filename where prescription is exported in CDAR2 format (as xml file). */
  exportToFile?: InputMaybe<Scalars['String']['input']>;
  /** Describes the desirable effect of medicine. */
  indication: Scalars['String']['input'];
  /** Specifies medication repetition details if repetition wanted. */
  iteration?: InputMaybe<IterationInput>;
  /** Free text for pharmacy. */
  messageToPharmacy?: InputMaybe<Scalars['String']['input']>;
  /** Patient identification method. Required if drug is narcotic(if is_narcotic param is true or drug register internal medicine is marked as narcotic in medicine database). */
  patientIdentification?: InputMaybe<PatientIdentificationInput>;
  /** Whether medicine is planned to take permanently */
  permanentMedicine: Scalars['Boolean']['input'];
  /** Whether medicine is in drug register, not in drug register or custom mixture. */
  prescriptionClassification: PrescriptionClassification;
  /** Whether medicine can be renewed or not. */
  prohibitionOfRenewal: ProhibitionInput;
  /** The service event the current replacing is related to. */
  serviceEventOid: Scalars['String']['input'];
  /** If the dosing of the medicine is different for normal usage. */
  sic: Scalars['Boolean']['input'];
  startingOfUsage: Scalars['Boolean']['input'];
  /** Classifies a general reason of current caring operation, the value belongs to codeserver 1.2.246.537.5.40101 codes. */
  typeOfCaring: Scalars['String']['input'];
  /** Not related to kanta. Specifies if medicine is used continuously, fixed term or only if necessary. */
  usageType?: InputMaybe<MedicineUsageInputType>;
};

export type PrescriptionCreationTypeSpecificArguments = {
  /** Not necessary for drug register internal medicines, the value belongs to codeserver 1.2.246.537.5.40118 codes */
  PKV?: InputMaybe<Scalars['String']['input']>;
  /** Only necessary for drug register external products */
  drugRegisterExternal?: InputMaybe<DrugRegisterExternalPrescriptionCreationArguments>;
  /** Only necessary if prescription classification is DRUG_REGISTER_INTERNAL */
  drugRegisterInternal?: InputMaybe<DrugRegisterInternalPrescriptionCreationArguments>;
  /** Only necessary for extempore prescriptions */
  extempore?: InputMaybe<ExtemporePrescriptionCreationArguments>;
  /** Not necessary for drug register internal medicines. */
  isNarcotic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only necessary if assigned amount representation is PACKAGES. */
  packageAmount?: InputMaybe<PackageAmountArguments>;
  /** Only necessary if assigned amount representation is PACKAGES and prescription classification is not DRUG_REGISTER_EXTERNAL */
  packageDetails?: InputMaybe<PackageDetailsArguments>;
  /** Only necessary if assigned amount representation is TIME. */
  timePeriod?: InputMaybe<TimePeriodArguments>;
  /** Only necessary if assigned amount representation is TOTAL_AMOUNT. */
  totalAmount?: InputMaybe<TotalAmountArguments>;
};

/** Value belongs to codesystem 1.2.246.537.5.40105 values. */
export type PrescriptionMessageType = {
  __typename?: 'PrescriptionMessageType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type PrescriptionMetadata = {
  __typename?: 'PrescriptionMetadata';
  /** VNR code of the assigned medicine. Only defined if specific drug package is assigned from drug register. */
  VNR?: Maybe<Scalars['String']['output']>;
  /** Medicine active status, the value belongs to codeserver 1.2.246.537.5.40300 values */
  activeStatus?: Maybe<Scalars['String']['output']>;
  /** The value belongs to codeserver 1.2.246.537.5.40301 values. */
  archiveStatus?: Maybe<Scalars['String']['output']>;
  assignedAmount?: Maybe<Scalars['String']['output']>;
  /** Whether prescription assigned amount is defined by total amount, time period of drug package amount. */
  assignedAmountRepresentation?: Maybe<AssignedAmountRepresentation>;
  /** General info of the author who has created the prescription. */
  assignedBy: AuthorGeneralInfo;
  /** The date prescription was created. */
  assignedOn?: Maybe<Scalars['DateTime']['output']>;
  /** ATC code of the assigned medicine main active ingredient. */
  atcCode?: Maybe<Scalars['String']['output']>;
  /** Current status of medicine delivery, the value belongs to codeserver 1.2.246.537.5.40123 codes */
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  doseDistribution: Scalars['Boolean']['output'];
  /** Shorthand of the dosing text, for example 1x2 = One dose twice per day */
  dosingShorthand?: Maybe<Scalars['String']['output']>;
  dosingText?: Maybe<Scalars['String']['output']>;
  /** If assigned amount is defined by drug package, tells package size, unit etc. */
  drugPackage?: Maybe<DrugPackageInfo>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  /** If the assigned medicine is prepared in pharmacy(=extempore prescription) */
  extemporePrescription: Scalars['Boolean']['output'];
  /** Name of the assigned medicine main active ingredient. */
  ingredientName?: Maybe<Scalars['String']['output']>;
  /** Intensity of the assigned medicine. */
  intensity?: Maybe<Scalars['String']['output']>;
  /** If prescription iterated, defines iteration unit, iteration times and iteration width. */
  iteration?: Maybe<IterationOutput>;
  /** Date of the last dispense of the medicine. */
  lastDispensed?: Maybe<Scalars['Date']['output']>;
  /** Assigned medicine name. */
  medicineName?: Maybe<Scalars['String']['output']>;
  /** Prescription message type, the value belongs to codeserver 1.2.246.537.5.40105 codes */
  messageType: Scalars['String']['output'];
  /** OID code of the document. */
  oid: Scalars['String']['output'];
  /** Original document OID code. Also called set id. Tells the oid code of the first version of the prescription this document is related to. */
  originalDocument: Scalars['String']['output'];
  /** If assigned amount is defined by drug package, tells amount of assigned packages. */
  packageAmount?: Maybe<Scalars['Int']['output']>;
  /** If medication assigned by this prescription is meant to be permanent. */
  permanentMedicine: Scalars['Boolean']['output'];
  /** Pharmaceutical form of the assigned medicine. */
  pharmaceuticalForm?: Maybe<Scalars['String']['output']>;
  /** Information of the previous dispense. */
  previousDispense?: Maybe<Scalars['String']['output']>;
  /** Tells what for medicine is assigned. */
  purposeOfUsage?: Maybe<Scalars['String']['output']>;
  /** Unused amount of the prescription not given to patient. */
  remainingQuantity?: Maybe<Scalars['String']['output']>;
  /** Whether drug renewal is prohibited. */
  renewalProhibition: Scalars['Boolean']['output'];
  /** Value belongs to codeserver 1.2.246.537.5.40106 values. */
  renewalStatus?: Maybe<Scalars['String']['output']>;
  /** SIC should be set to true if drug dosing is unusual compared to normal way use of assigned medicine. */
  sic: Scalars['Boolean']['output'];
  /** If assigned amount is defined by time period, tells time period length and unit. */
  timePeriod?: Maybe<MedicationPeriodInfo>;
  /** If assigned amount is defined by total amount, tells assigned amount quantity and unit */
  totalAmount?: Maybe<TotalAmountInfo>;
  /** Tells whether medicine is assigned to use continuously, if necessary or fixed term */
  usageType?: Maybe<MedicineUsageType>;
  /** Version number of the document. */
  versionNumber: Scalars['String']['output'];
};

/** Value belongs to codesystem 1.2.246.537.6.600 values. */
export type PrescriptionRenewalProhibitionReasonType = {
  __typename?: 'PrescriptionRenewalProhibitionReasonType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type PrescriptionReplacingArguments = {
  /** Replaced prescription id. */
  replacedPrescriptionOid: Scalars['String']['input'];
  /** Specify reason of presccription replaced, the value belongs to codeserver 1.2.246.537.6.600 codes. */
  replacementReasonCode: Scalars['String']['input'];
  /** Free explanation of the replacement reason. Should be decined if replacement reason code is 5(=custom reason) */
  replacementReasonExplanation?: InputMaybe<Scalars['String']['input']>;
};

/** Value belongs to codesystem 1.2.246.537.5.40103 values. */
export type PrescriptionRepudiationType = {
  __typename?: 'PrescriptionRepudiationType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type PrescriptionState = {
  __typename?: 'PrescriptionState';
  PKV?: Maybe<Scalars['String']['output']>;
  argumentToNotUseCheapestBiologicalMedicine?: Maybe<Scalars['String']['output']>;
  assignedAmountRepresentation: AssignedAmountRepresentation;
  assignedBy?: Maybe<AuthorGeneralInfo>;
  doseDistribution: Scalars['Boolean']['output'];
  dosingShorthand?: Maybe<Scalars['String']['output']>;
  dosingText: Scalars['String']['output'];
  drugExchangeProhibition: Scalars['Boolean']['output'];
  drugIsUnderInfectiousDiseaseLaw: Scalars['Boolean']['output'];
  drugPackageDetails?: Maybe<DrugPackageDetailsState>;
  drugRegisterInternalMedicineAssignedType?: Maybe<DrugRegisterInternalMedicineAssign>;
  indication: Scalars['String']['output'];
  ingredients?: Maybe<Array<Maybe<IngredientOutput>>>;
  isNarcotic?: Maybe<Scalars['Boolean']['output']>;
  iteration?: Maybe<IterationOutput>;
  messageToPharmacy?: Maybe<Scalars['String']['output']>;
  messageType: Scalars['String']['output'];
  mixtureName?: Maybe<Scalars['String']['output']>;
  oid: Scalars['String']['output'];
  originalDocument: Scalars['String']['output'];
  packageAmount?: Maybe<Scalars['Int']['output']>;
  packageId?: Maybe<Scalars['Int']['output']>;
  patientIdentification?: Maybe<PatientIdentificationOutput>;
  permanentMedicine: Scalars['Boolean']['output'];
  preparationGuide?: Maybe<Scalars['String']['output']>;
  prescriptionClassification: PrescriptionClassification;
  productName?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  prohibitionOfRenewal: ProhibitionOutput;
  sic: Scalars['Boolean']['output'];
  specialClarificationCode?: Maybe<Scalars['String']['output']>;
  specialClarificationDate?: Maybe<Scalars['Date']['output']>;
  startingOfUsage: Scalars['Boolean']['output'];
  timePeriod?: Maybe<TimePeriodState>;
  totalAmount?: Maybe<TotalAmountState>;
  typeOfCaring: Scalars['String']['output'];
  usageType?: Maybe<MedicineUsageType>;
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
  versionNumber: Scalars['String']['output'];
};

/** Value belongs to codesystem 1.2.246.537.6.605 values. */
export type PrescriptionTypeDefinition = {
  __typename?: 'PrescriptionTypeDefinition';
  OID?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type PriceSupplierAndMainTest = {
  mainTest: Scalars['Int']['input'];
  price?: InputMaybe<Scalars['Decimal']['input']>;
  supplierId: Scalars['ID']['input'];
};

/** An enumeration. */
export enum ProcedureKelaReportCategoryChoices {
  Agriculture = 'AGRICULTURE',
  Doctor = 'DOCTOR',
  Ergonomics = 'ERGONOMICS',
  Exercise = 'EXERCISE',
  Hygiene = 'HYGIENE',
  Imaging = 'IMAGING',
  Laboratory = 'LABORATORY',
  Nurse = 'NURSE',
  Nutrition = 'NUTRITION',
  Physiotherapist = 'PHYSIOTHERAPIST',
  Psychologist = 'PSYCHOLOGIST',
  Social = 'SOCIAL',
  Specialist = 'SPECIALIST',
  SpeechTherapy = 'SPEECH_THERAPY',
  Technical = 'TECHNICAL',
  Vision = 'VISION'
}

export type ProcedurePartnerPriceType = {
  __typename?: 'ProcedurePartnerPriceType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  partner: PartnerType;
  price: Scalars['Decimal']['output'];
  procedure: ProcedureType;
};

export type ProcedureType = {
  __typename?: 'ProcedureType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isEmployeeBenefit: Scalars['Boolean']['output'];
  isKela0: Scalars['Boolean']['output'];
  isKela1: Scalars['Boolean']['output'];
  isKela2: Scalars['Boolean']['output'];
  isRemote: Scalars['Boolean']['output'];
  kelaReportCategory?: Maybe<PartnerservicesProcedureKelaReportCategoryChoices>;
  name: Scalars['String']['output'];
  partnerService: PartnerServiceType;
  partnerServiceSettings: Array<PartnerServiceSettingType>;
  paymentCommitments: Array<PaymentCommitmentType>;
  paymentcommitmentprocedureSet: Array<PaymentCommitmentProcedureType>;
  procedurepartnerpriceSet: Array<ProcedurePartnerPriceType>;
};

export type Professional = {
  __typename?: 'Professional';
  education?: Maybe<Array<Maybe<EducationOutput>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstNames: Array<Scalars['String']['output']>;
  laboratoryorderSet: Array<LaboratoryOrderType>;
  lastName: Scalars['String']['output'];
  organizations: Array<Organization>;
  professionalId: Scalars['String']['output'];
  suffix: Scalars['String']['output'];
  svCode: Scalars['String']['output'];
  termsOfUseAgreement: Scalars['Boolean']['output'];
};

export type ProfessionalGroupType = {
  __typename?: 'ProfessionalGroupType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  taskSet: Array<TaskType>;
  title: Scalars['String']['output'];
  userSet: Array<UserType>;
};

/** Value belongs to codesystem 1.2.246.537.6.140 values. */
export type ProfessionalRightType = {
  __typename?: 'ProfessionalRightType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type ProfileInfoInput = {
  companyProfileId?: InputMaybe<Scalars['ID']['input']>;
  corporateProfileId?: InputMaybe<Scalars['ID']['input']>;
  customerProfileId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProfileType = {
  __typename?: 'ProfileType';
  anyCompany: Scalars['Boolean']['output'];
  anyCustomer: Scalars['Boolean']['output'];
  company?: Maybe<CompanyType>;
  corporate: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<PermissionType>;
  rolecompanySet: Array<RoleCompanyType>;
  rolecorporateSet: Array<RoleCorporateType>;
  rolecustomerSet: Array<RoleCustomerType>;
};

export type ProhibitionInput = {
  isProhibited: Scalars['Boolean']['input'];
  reasonCode?: InputMaybe<Scalars['String']['input']>;
  reasonText?: InputMaybe<Scalars['String']['input']>;
};

export type ProhibitionOutput = {
  __typename?: 'ProhibitionOutput';
  isProhibited: Scalars['Boolean']['output'];
  reasonCode?: Maybe<Scalars['String']['output']>;
  reasonText?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Return all codes of codesystem 1.2.246.537.5.40118. */
  PKVs: Array<PkvType>;
  activeLaboratoryCatalog?: Maybe<LaboratoryCatalogType>;
  activeSalesCatalog?: Maybe<SalesCatalogType>;
  /** Return all codes of codesystem 1.2.246.537.5.40300. */
  activeStatuses: Array<ActiveStatus>;
  /** Returns all the different package choices of the original medicine. Trade name and active ingredient do not change but package size can be varied. */
  alternativePackages: Array<DrugType>;
  apiTokens: Array<ApiTokenType>;
  /** Return all codes of codesystem 1.2.246.537.5.40301. */
  archiveStatuses: Array<ArchiveStatus>;
  availableBalanceForUser: Scalars['Decimal']['output'];
  /** Return drug packages which belong to given medicinal groups. */
  biosimilars: Array<DrugType>;
  /** Return all codes of codesystem 1.2.246.537.5.40101. */
  caringtypes: Array<CaringType>;
  checkIcd10CodesUsedInOtherSickLeaveCategories: Scalars['Boolean']['output'];
  companiesForCorporate: Array<CompanyType>;
  company: CompanyType;
  companyAddresses: Array<CompanyAddressType>;
  companyProfiles: Array<ProfileType>;
  /** Return all codes of codesystem 1.2.246.537.5.40119. */
  consentTypes: Array<ConsentType>;
  contractTerminationReasons?: Maybe<Array<ContractTerminationReasonType>>;
  corporateAdminViews: Array<ViewType>;
  corporateProfiles: Array<ProfileType>;
  customer: CustomerType;
  customerAddresses: Array<CustomerAddressType>;
  customerAdminViews: Array<ViewType>;
  customerContactPersonOfType?: Maybe<ContactPersonType>;
  customerDepartments: Array<DepartmentType>;
  /** Return all codes of codesystem 1.2.246.537.6.240. */
  customerInformationViewReasons: Array<CustomerInformationViewReasonType>;
  customerProfiles: Array<ProfileType>;
  customerSalesPackage?: Maybe<SalesPackageType>;
  customerServiceSettings: CustomerServiceSettingsType;
  customerServicesOverview: CustomerServicesOverview;
  customerSupervisorUsers: Array<UserType>;
  customerTasks: CustomerTasksType;
  customerUserAmounts: CustomerUserAmountsType;
  customersForCorporate: Array<CustomerType>;
  customersHealthcareSummary: CustomersHealthcareSummaryType;
  customersHealthcareTeamInitialActionsNotFinished: Array<CustomerType>;
  customersHealthcareTeamNotSpecified: Array<CustomerType>;
  customersKelaReportNotDone: Array<CustomerType>;
  customersTosuNotScheduled: Array<CustomerType>;
  customersTosuScheduled: Array<CustomerType>;
  customersTpsNotScheduled: Array<CustomerType>;
  customersTpsScheduled: Array<CustomerType>;
  /** Return all codes of codesystem 1.2.246.537.5.40123. */
  dispenseStatuses: Array<DispenseStatus>;
  documentCategories: Array<DocumentCategoryType>;
  documentTemplate: DocumentTemplateType;
  documentTemplates: Array<DocumentTemplateType>;
  documentsForCustomer: Array<DocumentType>;
  documentsForRegional: Array<DocumentType>;
  /** Return all codes of codesystem 1.2.246.537.6.138. */
  doseUnits: Array<DoseUnit>;
  /** Return all codes of codesystem 1.2.246.537.5.40183. */
  drugExchangeProhibitionReasons: Array<DrugExchangeProhibitionReasonType>;
  fetchPrescriptions?: Maybe<Array<Maybe<PrescriptionState>>>;
  filterCustomers: Array<CustomerType>;
  functionality: Functionality;
  /** Return all codes of codesystem 1.2.246.537.5.1. */
  genders: Array<GenderType>;
  /** Return details of matching code in codesystem 1.2.246.537.5.40300. */
  getActiveStatusByCode: ActiveStatus;
  /** Return details of matching code in codesystem 1.2.246.537.5.40301. */
  getArchiveStatusByCode: ArchiveStatus;
  /** Return details of matching code in codesystem 1.2.246.537.6.32. */
  getAtcsByCodes: AtcCodesInfo;
  /** Return details of matching code in codesystem 1.2.246.537.5.40006. */
  getAuthorDefinerByCode: AuthorDefinerType;
  getCampaignResults?: Maybe<Scalars['String']['output']>;
  getCampaigns: Array<CampaignType>;
  /** Return details of matching code in codesystem 1.2.246.537.6.13. */
  getCarestageByCode: CareStageType;
  /** Return details of matching code in codesystem 1.2.246.537.5.40101. */
  getCaringTypeByCode: CaringType;
  /** Return details of matching code in codesystem 1.2.246.537.6.12. */
  getCdaViewByCode: CdaViewType;
  /** Returns closest ATC codes matching with provided test string. ATC codes belongs to codesystem 1.2.246.537.6.32. */
  getClosestAtcs: Array<AtcType>;
  getClosestCustomQuestionKeys?: Maybe<Array<QuestionKeyOutputType>>;
  /** Return closest medicines which active ingredient best match with provided test string. */
  getClosestDrugIngredients: Array<DrugType>;
  /** Return closest medicines which medicine name best match with provided test string. */
  getClosestDrugNames: Array<DrugType>;
  /** Return closest FinLoinc codesystem codes which name best match with provided test string. */
  getClosestFinloincNames: Array<FinLoincType>;
  /** Return closest ICD10 codes which name best match with provided test string. */
  getClosestIcd10Names: Array<Icd10Type>;
  /** Returns details of codesystem with matching name. */
  getCodeSystemByName: CodeSystemType;
  /** Return details of matching code in codesystem 1.2.246.537.5.40119. */
  getConsentTypeByCode: ConsentType;
  /** Return details of matching code in codesystem 1.2.246.537.6.240. */
  getCustomerInformationViewReasonByCode: CustomerInformationViewReasonType;
  /** Return details of matching code in codesystem 1.2.246.537.6.1. */
  getDiagnosisByCode: Icd10Type;
  /** Return details of matching code in codesystem 1.2.246.537.5.40123. */
  getDispenseStatusByCode: DispenseStatus;
  /** Return details of matching code in codesystem 1.2.246.537.6.138. */
  getDoseUnitByCode: DoseUnit;
  /** Returns details of medicine with matching package id. */
  getDrugByPackageId: DrugType;
  /** Return details of matching code in codesystem 1.2.246.537.5.40183. */
  getDrugExchangeProhibitionReasonByCode: DrugExchangeProhibitionReasonType;
  /** Return medicine with given VNR. If multiple medicines found return error. */
  getDrugPackageByVnr?: Maybe<DrugType>;
  /** Return details of matching code in codesystem 1.2.246.537.6.148. */
  getEducationByCode: EducationType;
  /** Return details of matching code in codesystem 1.2.246.537.5.1. */
  getGenderByCode: GenderType;
  /** Return details of matching code in codesystem 1.2.246.537.6.14. */
  getHeaderByCode: HeaderType;
  getLaboratoryByCodeId: LaboratoryType;
  getLaboratoryOrder?: Maybe<LaboratoryOrderType>;
  getLaboratoryOrderResults?: Maybe<Array<Maybe<LaboratoryResultType>>>;
  getLaboratoryOrders?: Maybe<Array<Maybe<LaboratoryOrderType>>>;
  getLaboratoryTestByCodeId: LaboratoryTestNomenclatureType;
  /** Return details of matching code in codesystem 1.2.246.537.6.604. */
  getMedicinalProductByCode: MedicinalProductType;
  getNorrePackagePricesAll: Array<NorrePackagePriceWithPackage>;
  getOrganizations?: Maybe<Array<Maybe<Organization>>>;
  getPackagePrices: Array<NorrePackagePrice>;
  getPackagePricesByPackageId: Array<NorrePackagePrice>;
  /** Return details of matching code in codesystem 1.2.246.537.5.40109. */
  getPatientIdentificationByCode: PatientIdentificationType;
  getPatients?: Maybe<Array<Maybe<PatientType>>>;
  /** Return details of matching code in codesystem 1.2.246.537.5.40118. */
  getPkvByCode: PkvType;
  /** Return details of matching code in codesystem 1.2.246.537.5.40105. */
  getPrescriptionMessageTypeByCode: PrescriptionMessageType;
  /** Return details of matching code in codesystem 1.2.246.537.6.600. */
  getPrescriptionRenewalProhibitionByCode: PrescriptionRenewalProhibitionReasonType;
  /** Return details of matching code in codesystem 1.2.246.537.5.40103. */
  getPrescriptionRepudiationTypeByCode: PrescriptionRepudiationType;
  /** Return details of matching code in codesystem 1.2.246.537.6.605. */
  getPrescriptionTypeByCode: PrescriptionTypeDefinition;
  getProfessional?: Maybe<Professional>;
  /** Return details of matching code in codesystem 1.2.246.537.6.140. */
  getProfessionalRightByCode: ProfessionalRightType;
  getProfessionals?: Maybe<Array<Maybe<Professional>>>;
  /** Return details of matching code in codesystem 1.2.246.537.5.40106. */
  getRenewalStatusByCode: RenewalStatus;
  /** Return details of matching code in codesystem 1.2.246.537.6.147. */
  getRestrictionByCode: RestrictionType;
  getServiceEventWithOid?: Maybe<ServiceEvent>;
  getServiceEvents?: Maybe<Array<Maybe<ServiceEvent>>>;
  /** Return details of matching code in codesystem 1.2.246.537.6.850. */
  getSpecialClarificationByCode: SpecialClarification;
  getSurveyAnswers: Array<TreeResultType>;
  getSurveyAnswersForTree: Array<TreeResultType>;
  /** Return details of matching code in codesystem 1.2.246.537.6.12.999. */
  getTechnicalStructureByCode: TechnicalStructureType;
  /** Return details of matching code in codesystem 1.2.246.537.6.112. */
  getToBeOrNotToBeByCode: ToBeOrNotToBe;
  getTrees: Array<TreeInfoDataType>;
  getTreesByName: Array<TreeInfoDataType>;
  healthcareTeams: Array<HealthcareTeamType>;
  icd10sForSickLeaveSettings: Array<Icd10Type>;
  insuranceCompanies: Array<InsuranceCompanyType>;
  /** Returns a list of drug package id:s filtered by the given parameters. */
  intelligentDrugPackageSearch: Array<Scalars['Int']['output']>;
  invoiceInterfaces: Array<InvoiceInterfaceType>;
  invoicePurchase: InvoicePurchaseType;
  invoicePurchaseAttachments: Array<InvoicePurchaseAttachmentType>;
  invoicePurchaseRowsForSales: Array<InvoicePurchaseRowType>;
  invoicePurchases: Array<InvoicePurchaseType>;
  invoiceSales: Array<InvoiceSaleType>;
  laboratories: Array<LaboratoryType>;
  laboratoriesWithFilters: Array<LaboratoryType>;
  laboratoryCatalog: LaboratoryCatalogType;
  laboratoryCatalogContents: LaboratoryCatalogContentsType;
  laboratoryCatalogs: Array<LaboratoryCatalogType>;
  laboratoryNomenclature: Array<LaboratoryTestNomenclatureType>;
  laboratorySuppliers: Array<LaboratorySupplierType>;
  laboratoryTestByCodeId: LaboratoryTestType;
  me: MeType;
  medicalStaffForRegional: Array<UserType>;
  medicalStaffSearch: MedicalStaffSearchReturnType;
  /** Return all codes of codesystem 1.2.246.537.6.604. */
  medicinalProducts: Array<MedicinalProductType>;
  medicineList?: Maybe<MedicineList>;
  multipleTreesRelatedData: Array<TreeDataType>;
  node: NodeType;
  nodeGroups: Array<NodeGroupType>;
  nodes: Array<NodeType>;
  nonNorreBackendUser: UserType;
  norreBackendUser: UserType;
  norrePackages: Array<NorrePackageType>;
  partner: PartnerType;
  partnerProcedures: Array<ProcedureType>;
  partnerServices: Array<PartnerServiceType>;
  partners: Array<PartnerType>;
  /** Return all codes of codesystem 1.2.246.537.5.40109. */
  patientIdentifications: Array<PatientIdentificationType>;
  patientServiceEventsInOrganization?: Maybe<Array<Maybe<ServiceEvent>>>;
  paymentCommitment: PaymentCommitmentType;
  paymentCommitmentPdf: Scalars['String']['output'];
  paymentCommitments: Array<PaymentCommitmentType>;
  paymentCommitmentsForUser: Array<PaymentCommitmentType>;
  permissions: Array<PermissionType>;
  /** Return all codes of codesystem 1.2.246.537.5.40105. */
  prescriptionMessageTypes: Array<PrescriptionMessageType>;
  /** Return all codes of codesystem 1.2.246.537.6.600. */
  prescriptionRenewalProhibitions: Array<PrescriptionRenewalProhibitionReasonType>;
  /** Return all codes of codesystem 1.2.246.537.5.40103. */
  prescriptionRepudiations: Array<PrescriptionRepudiationType>;
  prescriptionState?: Maybe<PrescriptionState>;
  prescriptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  procedure: ProcedureType;
  procedurePartnerPrices: Array<ProcedurePartnerPriceType>;
  procedureSearch: Array<ProcedureType>;
  professionalGroups: Array<ProfessionalGroupType>;
  profile: ProfileType;
  question?: Maybe<QuestionType>;
  questions: Array<QuestionType>;
  regionalAdminViews: Array<ViewType>;
  /** Return all codes of codesystem 1.2.246.537.5.40106. */
  renewalStatuses: Array<RenewalStatus>;
  rolesCompany?: Maybe<Array<RoleCompanyType>>;
  rolesCorporate?: Maybe<Array<RoleCorporateType>>;
  rolesCustomer?: Maybe<Array<RoleCustomerType>>;
  rolesForCustomer?: Maybe<Array<RoleCustomerType>>;
  salesCatalog?: Maybe<SalesCatalogType>;
  salesCatalogs: Array<SalesCatalogType>;
  salesContract: SalesContractType;
  salesContracts: Array<SalesContractType>;
  salesExtraServices: Array<SalesExtraServiceType>;
  salesPackages: Array<SalesPackageType>;
  salesPricesForSalesCatalog: Array<SalesPriceType>;
  searchIcd10sForSickLeaveForm: Array<Icd10Type>;
  searchPatients?: Maybe<Array<Maybe<PatientType>>>;
  searchQuestion?: Maybe<Array<QuestionType>>;
  sickLeaveCategories: Array<SickLeaveCategoryType>;
  sickLeaveNotice: SickLeaveNoticeType;
  sickLeaveNoticeTypesAndReasonsForUser: Array<Maybe<SickLeaveNoticeTypeAndReasonType>>;
  sickLeaveNoticesForCustomer: Array<SickLeaveNoticeType>;
  sickLeaveNoticesForSupervisor: Array<SickLeaveNoticeType>;
  sickLeaveNoticesForUser: Array<SickLeaveNoticeType>;
  sickLeaveSelfReportReasonStatusForUser: Scalars['Boolean']['output'];
  sickLeaveSelfReportReasons: Array<SickLeaveSelfReportReasonType>;
  sickLeaveSelfReportReasonsAllowed: Array<SickLeaveSelfReportReasonType>;
  sickLeaveSelfReportReasonsWithoutCategory: Array<SickLeaveSelfReportReasonType>;
  sickLeaveSettingsCustomer: SickLeaveSettingsCustomerType;
  sickLeaveTypes: Array<SickLeaveTypeType>;
  /** Return all codes of codesystem 1.2.246.537.6.850. */
  specialClarifications: Array<SpecialClarification>;
  statutoryInsuranceTypes: Array<InsuranceTypeChoices>;
  /** Returns substitute packages. */
  substituteMedicines: Array<DrugType>;
  subtitles?: Maybe<Array<SubtitleType>>;
  supplierPackages: Array<SupplierPackageType>;
  task: TaskType;
  taskTopics: Array<TaskTopicType>;
  tasks: Array<TaskType>;
  tasksForCustomer: Array<TaskType>;
  tasksForMe: Array<TaskType>;
  titles?: Maybe<Array<TitleType>>;
  titletemplates?: Maybe<Array<TitleTemplateType>>;
  /** Return all codes of codesystem 1.2.246.537.6.112. */
  toBeOrNotToBeCodes: Array<ToBeOrNotToBe>;
  treeRelatedData: TreeDataType;
  user: UserType;
  userCompanyRoles: Array<RoleCompanyType>;
  userCorporateRoles: Array<RoleCorporateType>;
  userCustomerRoles: Array<RoleCustomerType>;
  userEmailsAndPhoneNumbers: UserEmailPhoneNumberReturnType;
  userEmploymentHistory: Array<UserEmploymentInformationType>;
  userHealthCheckHistory: Array<HealthCheckType>;
  userOwnPersonalInformation: UserType;
  userSyncLog: Array<UserSyncLogType>;
  usersForCorporate: Array<UserType>;
  usersForCustomer: Array<UserType>;
  usersForRegional: Array<UserType>;
  usersNextHealthCheckNotScheduled: Array<UserType>;
  usersNextHealthCheckScheduled: Array<UserType>;
  usersPreviousHealthCheckNotKnownOrClarified: Array<UserType>;
  usersWithRoleOnlyInCompany: Array<UserType>;
  usersWithRoleOnlyInCustomer: Array<UserType>;
  validSalesContractVersionsForCustomer?: Maybe<Array<SalesContractVersionType>>;
  view?: Maybe<ViewType>;
  views: Array<ViewType>;
  voluntaryInsuranceTypes: Array<InsuranceTypeChoices>;
};


export type QueryAlternativePackagesArgs = {
  drugKey: Scalars['Int']['input'];
};


export type QueryAvailableBalanceForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBiosimilarsArgs = {
  biologicalMedicineGroups: Array<Scalars['String']['input']>;
};


export type QueryCheckIcd10CodesUsedInOtherSickLeaveCategoriesArgs = {
  icd10Codes: Array<InputMaybe<Scalars['String']['input']>>;
  sickLeaveCategoryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCompanyAddressesArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCompanyProfilesArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  includeCommon?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCorporateProfilesArgs = {
  includeAllCommon?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerAddressesArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerContactPersonOfTypeArgs = {
  contactType: Scalars['String']['input'];
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerDepartmentsArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerProfilesArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  includeCommon?: InputMaybe<Scalars['Boolean']['input']>;
  limitCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCustomerSalesPackageArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerServiceSettingsArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerServicesOverviewArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerSupervisorUsersArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerTasksArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerUserAmountsArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomersHealthcareSummaryArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersHealthcareTeamInitialActionsNotFinishedArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersHealthcareTeamNotSpecifiedArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersKelaReportNotDoneArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersTosuNotScheduledArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersTosuScheduledArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersTpsNotScheduledArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCustomersTpsScheduledArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryDocumentCategoriesArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  showForCustomerUsers?: InputMaybe<Scalars['Boolean']['input']>;
  showForCustomers?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDocumentTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentsForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryDocumentsForRegionalArgs = {
  companyId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFetchPrescriptionsArgs = {
  oidCodes: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryFilterCustomersArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryFunctionalityArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetActiveStatusByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetArchiveStatusByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetAtcsByCodesArgs = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetAuthorDefinerByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetCampaignResultsArgs = {
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetCampaignsArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryGetCarestageByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetCaringTypeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetCdaViewByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetClosestAtcsArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClosestCustomQuestionKeysArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClosestDrugIngredientsArgs = {
  drugExchangeRequiredLast?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  notAvailableLast?: InputMaybe<Scalars['Boolean']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClosestDrugNamesArgs = {
  drugExchangeRequiredLast?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  notAvailableLast?: InputMaybe<Scalars['Boolean']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClosestFinloincNamesArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClosestIcd10NamesArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCodeSystemByNameArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetConsentTypeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetCustomerInformationViewReasonByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetDiagnosisByCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDispenseStatusByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetDoseUnitByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetDrugByPackageIdArgs = {
  packageId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetDrugExchangeProhibitionReasonByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetDrugPackageByVnrArgs = {
  VNR: Scalars['String']['input'];
};


export type QueryGetEducationByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetGenderByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetHeaderByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetLaboratoryByCodeIdArgs = {
  codeId: Scalars['String']['input'];
};


export type QueryGetLaboratoryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLaboratoryOrderResultsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLaboratoryTestByCodeIdArgs = {
  codeId: Scalars['String']['input'];
};


export type QueryGetMedicinalProductByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetPackagePricesArgs = {
  laboratoryTests?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  supplierPackages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryGetPackagePricesByPackageIdArgs = {
  norrePackageId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetPatientIdentificationByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetPkvByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetPrescriptionMessageTypeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetPrescriptionRenewalProhibitionByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetPrescriptionRepudiationTypeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetPrescriptionTypeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetProfessionalRightByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetRenewalStatusByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetRestrictionByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetServiceEventWithOidArgs = {
  oid: Scalars['String']['input'];
};


export type QueryGetSpecialClarificationByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetSurveyAnswersForTreeArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetTechnicalStructureByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetToBeOrNotToBeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetTreesByNameArgs = {
  nameSearch?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHealthcareTeamsArgs = {
  companyId: Scalars['ID']['input'];
  professionalGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryIntelligentDrugPackageSearchArgs = {
  VNR?: InputMaybe<Scalars['String']['input']>;
  atcCode?: InputMaybe<Scalars['String']['input']>;
  intensity?: InputMaybe<Scalars['String']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  packageAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  packageSizeText?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvoicePurchaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicePurchaseAttachmentsArgs = {
  invoicePurchaseId: Scalars['ID']['input'];
};


export type QueryInvoicePurchaseRowsForSalesArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryInvoicePurchasesArgs = {
  companyId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<InputMaybe<InvoicePurchaseState>>>;
};


export type QueryInvoiceSalesArgs = {
  companyId: Scalars['ID']['input'];
  sent: Scalars['Boolean']['input'];
  sentAfter: Scalars['Date']['input'];
};


export type QueryLaboratoriesWithFiltersArgs = {
  businessId?: InputMaybe<Scalars['String']['input']>;
  hideExpired?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLaboratoryCatalogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLaboratoryCatalogContentsArgs = {
  catalogId: Scalars['ID']['input'];
};


export type QueryLaboratoryTestByCodeIdArgs = {
  codeId: Scalars['ID']['input'];
};


export type QueryMedicalStaffForRegionalArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryMedicalStaffSearchArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QueryMedicineListArgs = {
  patientEmail: Scalars['String']['input'];
  professionalId: Scalars['String']['input'];
};


export type QueryMultipleTreesRelatedDataArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartnerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartnersArgs = {
  onlyParents?: InputMaybe<Scalars['Boolean']['input']>;
  partnerParentId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPatientServiceEventsInOrganizationArgs = {
  businessId: Scalars['String']['input'];
  patientEmail: Scalars['String']['input'];
};


export type QueryPaymentCommitmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentCommitmentPdfArgs = {
  paymentCommitmentId: Scalars['ID']['input'];
};


export type QueryPaymentCommitmentsForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryPrescriptionStateArgs = {
  oid: Scalars['String']['input'];
};


export type QueryProcedureArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcedurePartnerPricesArgs = {
  procedureId: Scalars['ID']['input'];
};


export type QueryProcedureSearchArgs = {
  partnerId?: InputMaybe<Scalars['ID']['input']>;
  reimbursementClass?: InputMaybe<Scalars['String']['input']>;
  searchTerm: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolesForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QuerySalesCatalogArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySalesContractArgs = {
  customerId: Scalars['ID']['input'];
};


export type QuerySalesPricesForSalesCatalogArgs = {
  salesCatalogId: Scalars['ID']['input'];
};


export type QuerySearchIcd10sForSickLeaveFormArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QuerySearchPatientsArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchQuestionArgs = {
  max?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySickLeaveNoticeArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySickLeaveNoticeTypesAndReasonsForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySickLeaveNoticesForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QuerySickLeaveNoticesForSupervisorArgs = {
  handled: Scalars['Boolean']['input'];
  supervisorId: Scalars['ID']['input'];
};


export type QuerySickLeaveNoticesForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySickLeaveSelfReportReasonStatusForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySickLeaveSelfReportReasonsAllowedArgs = {
  customerId: Scalars['ID']['input'];
};


export type QuerySickLeaveSettingsCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QuerySubstituteMedicinesArgs = {
  matchPackageSize?: InputMaybe<Scalars['Boolean']['input']>;
  substituteDrugPackagesCode: Scalars['String']['input'];
  substituteMedicinesCode: Scalars['String']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTasksArgs = {
  companyId: Scalars['ID']['input'];
  dateEnd?: InputMaybe<Scalars['Date']['input']>;
  dateStart?: InputMaybe<Scalars['Date']['input']>;
  scheduleChoices?: InputMaybe<Array<InputMaybe<TaskScheduling>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<InputMaybe<TaskState>>>;
  taskTopicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userCreatedByIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userOwnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryTasksForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryTasksForMeArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  dateEnd?: InputMaybe<Scalars['Date']['input']>;
  dateStart?: InputMaybe<Scalars['Date']['input']>;
  scheduleChoices?: InputMaybe<Array<InputMaybe<TaskScheduling>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<InputMaybe<TaskState>>>;
  taskTopicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userCreatedByIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userOwnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryTreeRelatedDataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserCompanyRolesArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userId: Scalars['ID']['input'];
};


export type QueryUserCorporateRolesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserCustomerRolesArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userId: Scalars['ID']['input'];
};


export type QueryUserEmploymentHistoryArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserHealthCheckHistoryArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserOwnPersonalInformationArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserSyncLogArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryUsersForRegionalArgs = {
  companyId: Scalars['ID']['input'];
  healthcareTeamId?: InputMaybe<Scalars['ID']['input']>;
  isHealthcareWorker?: InputMaybe<Scalars['Boolean']['input']>;
  professionalGroupId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersNextHealthCheckNotScheduledArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryUsersNextHealthCheckScheduledArgs = {
  companyId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  healthcareTeamId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersPreviousHealthCheckNotKnownOrClarifiedArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryUsersWithRoleOnlyInCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryUsersWithRoleOnlyInCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryValidSalesContractVersionsForCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryViewArgs = {
  id: Scalars['ID']['input'];
};

/** An enumeration. */
export enum QuestionAnswerTypeChoices {
  LongText = 'LONG_TEXT',
  Matrix = 'MATRIX',
  Multiscored = 'MULTISCORED',
  Multiselect = 'MULTISELECT',
  Number = 'NUMBER',
  Scored = 'SCORED',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type QuestionKeyOutputType = {
  __typename?: 'QuestionKeyOutputType';
  createdAt: Scalars['DateTime']['output'];
  finloinc?: Maybe<FinLoincType>;
  freeString?: Maybe<Scalars['String']['output']>;
  icd10?: Maybe<Icd10Type>;
  id: Scalars['ID']['output'];
  questions: Array<QuestionType>;
};

/** An enumeration. */
export enum QuestionKeyTypeChoices {
  Custom = 'CUSTOM',
  Finloinc = 'FINLOINC',
  Icd10 = 'ICD10'
}

export type QuestionToNode = {
  __typename?: 'QuestionToNode';
  node: NodeType;
};

export type QuestionType = {
  __typename?: 'QuestionType';
  answerChoices: Array<Scalars['String']['output']>;
  answerLimitMaximum?: Maybe<Scalars['Int']['output']>;
  answerLimitMinimum?: Maybe<Scalars['Int']['output']>;
  answerMultiplier: Scalars['Decimal']['output'];
  answerScores: Array<Scalars['Int']['output']>;
  answerType: QuestionsQuestionAnswerTypeChoices;
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSlider: Scalars['Boolean']['output'];
  key?: Maybe<QuestionKeyOutputType>;
  keyName?: Maybe<Scalars['String']['output']>;
  mandatory: Scalars['Boolean']['output'];
  matrixQuestionFactors: Array<Scalars['Int']['output']>;
  matrixQuestions: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nodes: Array<NodeType>;
  question: Scalars['String']['output'];
  settingsReady: Scalars['Boolean']['output'];
  sliderStep: Scalars['Decimal']['output'];
  textHelp: Scalars['String']['output'];
  textInfo: Scalars['String']['output'];
};

/** An enumeration. */
export enum QuestionsQuestionAnswerTypeChoices {
  /** Long text */
  LongText = 'LONG_TEXT',
  /** Matrix */
  Matrix = 'MATRIX',
  /** Scored multi */
  Multiscored = 'MULTISCORED',
  /** Multiselect */
  Multiselect = 'MULTISELECT',
  /** Number */
  Number = 'NUMBER',
  /** Scored */
  Scored = 'SCORED',
  /** Select */
  Select = 'SELECT',
  /** Text */
  Text = 'TEXT'
}

/** An enumeration. */
export enum ReceptionChoices {
  LocalAndRemote = 'LOCAL_AND_REMOTE',
  RemoteOnly = 'REMOTE_ONLY'
}

export type RefreshNorreAuthToken = {
  __typename?: 'RefreshNorreAuthToken';
  accessTokenExpiresAt: Scalars['DateTime']['output'];
};

export type RejectInvoicePurchase = {
  __typename?: 'RejectInvoicePurchase';
  invoicePurchase?: Maybe<InvoicePurchaseType>;
};

export type RemoveCampaignSurveyRespondent = {
  __typename?: 'RemoveCampaignSurveyRespondent';
  campaign?: Maybe<CampaignType>;
};

/** Value belongs to codesystem 1.2.246.537.5.40106 values. */
export type RenewalStatus = {
  __typename?: 'RenewalStatus';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type ReplacePrescription = {
  __typename?: 'ReplacePrescription';
  prescriptionOid?: Maybe<Scalars['String']['output']>;
};

/**
 * Mutation which creates prescription repudiation documents in medical-database.
 * Repudiation documents should still send to Kanta using sendDocumentToKanta mutation or sendPrescriptionRepudiations.
 */
export type RepudiatePrescriptions = {
  __typename?: 'RepudiatePrescriptions';
  /** Oid codes of the documents which repudiation failed. */
  failedToRepudiate?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Failure reasons for each failed repudiation. */
  failureReasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Succesfully repudiated document oid codes */
  repudiatedDocumentOids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Repudiating document oid codes for each succesfully repudiated prescription. */
  repudiatingDocumentOids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ResendLaboratoryOrder = {
  __typename?: 'ResendLaboratoryOrder';
  laboratoryOrder?: Maybe<LaboratoryOrderType>;
};

export type RestrictedPrivilege = {
  __typename?: 'RestrictedPrivilege';
  intendedUse?: Maybe<Scalars['String']['output']>;
  prescriptionType?: Maybe<Scalars['String']['output']>;
  professionalGroup?: Maybe<Scalars['String']['output']>;
};

/** Value belongs to codesystem 1.2.246.537.6.147 values. */
export type RestrictionType = {
  __typename?: 'RestrictionType';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type RevokeNorreAuthToken = {
  __typename?: 'RevokeNorreAuthToken';
  success: Scalars['Boolean']['output'];
};

export type RoleCompanyType = {
  __typename?: 'RoleCompanyType';
  apiToken?: Maybe<ApiTokenType>;
  company: CompanyType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  profile: ProfileType;
  user?: Maybe<UserType>;
};

export type RoleCorporateType = {
  __typename?: 'RoleCorporateType';
  apiToken?: Maybe<ApiTokenType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  profile: ProfileType;
  user?: Maybe<UserType>;
};

export type RoleCustomerType = {
  __typename?: 'RoleCustomerType';
  apiToken?: Maybe<ApiTokenType>;
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerType;
  id: Scalars['ID']['output'];
  profile: ProfileType;
  user?: Maybe<UserType>;
};

export type SalesCatalogType = {
  __typename?: 'SalesCatalogType';
  activeFrom?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  salesPrices: Array<SalesPriceType>;
  state: SalescatalogsSalesCatalogStateChoices;
};

export type SalesContractDocumentType = {
  __typename?: 'SalesContractDocumentType';
  changeReasonExtraInfo: Scalars['String']['output'];
  changeType: SalescontractsSalesContractDocumentChangeTypeChoices;
  contractVersion: SalesContractVersionType;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID']['output'];
};

/** An enumeration. */
export enum SalesContractState {
  Contract = 'CONTRACT',
  Finished = 'FINISHED',
  Offer = 'OFFER',
  SentForSignature = 'SENT_FOR_SIGNATURE',
  TerminationInProgress = 'TERMINATION_IN_PROGRESS'
}

export type SalesContractType = {
  __typename?: 'SalesContractType';
  contractContactPerson?: Maybe<ContactPersonType>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<CustomerType>;
  id: Scalars['ID']['output'];
  salesContractVersions: Array<SalesContractVersionType>;
  signatureRequestSentAt?: Maybe<Scalars['DateTime']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  state: SalesContractState;
  terminatedAt?: Maybe<Scalars['DateTime']['output']>;
  terminatedBy?: Maybe<UserType>;
  terminationPeriod: Scalars['Int']['output'];
  terminationReason?: Maybe<ContractTerminationReasonType>;
};

export type SalesContractVersionPricesType = {
  __typename?: 'SalesContractVersionPricesType';
  costPerOffice: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  discounts: Array<DiscountType>;
  extraPriceExposure: Scalars['Decimal']['output'];
  firstHealthCheckPrice: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  initialCostExtraServicesPerCompany: Scalars['Decimal']['output'];
  initialCostExtraServicesPerPerson: Scalars['Decimal']['output'];
  initialCostPerCompany: Scalars['Decimal']['output'];
  initialCostPerPerson: Scalars['Decimal']['output'];
  numberOfOffices: Scalars['Int']['output'];
  salesContractVersion: SalesContractVersionType;
};

export type SalesContractVersionType = {
  __typename?: 'SalesContractVersionType';
  contract: SalesContractType;
  contractDocuments: Array<SalesContractDocumentType>;
  createdAt: Scalars['DateTime']['output'];
  customerReferenceAllowed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isLegacyContract: Scalars['Boolean']['output'];
  legacyServicesDescription?: Maybe<Scalars['String']['output']>;
  legacyServicesName?: Maybe<Scalars['String']['output']>;
  numberOfPersons: Scalars['Int']['output'];
  numberOfPersonsExposure: Scalars['Int']['output'];
  numberOfPersonsNonExposure: Scalars['Int']['output'];
  prices?: Maybe<SalesContractVersionPricesType>;
  receptionChoice?: Maybe<SalescontractsSalesContractVersionReceptionChoiceChoices>;
  salesExtraServices: Array<SalesExtraServiceType>;
  salesPackage: SalesPackageType;
  urlToPdf: Scalars['String']['output'];
  userSeller: UserType;
  validFrom: Scalars['Date']['output'];
};

/** An enumeration. */
export enum SalesExtraServiceProductChoices {
  ExtraServicePackage = 'EXTRA_SERVICE_PACKAGE',
  ExtraServicePackageWithCoaching = 'EXTRA_SERVICE_PACKAGE_WITH_COACHING',
  MentalWellnessCoverage = 'MENTAL_WELLNESS_COVERAGE',
  OtherMedicalCareServicesNoSickLeavesSelfDeclaration = 'OTHER_MEDICAL_CARE_SERVICES_NO_SICK_LEAVES_SELF_DECLARATION',
  OtherMedicalCareServicesWithSickLeavesSelfDeclaration = 'OTHER_MEDICAL_CARE_SERVICES_WITH_SICK_LEAVES_SELF_DECLARATION',
  SeriousIllnessCoverage = 'SERIOUS_ILLNESS_COVERAGE'
}

export type SalesExtraServiceType = {
  __typename?: 'SalesExtraServiceType';
  description: Scalars['String']['output'];
  group?: Maybe<SalescatalogsSalesExtraServiceGroupChoices>;
  id: Scalars['ID']['output'];
  locallyAvailable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  product: SalescatalogsSalesExtraServiceProductChoices;
  remotelyAvailable: Scalars['Boolean']['output'];
  salesPackages: Array<SalesPackageType>;
  salesPrices: Array<SalesPriceType>;
  salescontractversionSet: Array<SalesContractVersionType>;
};

/** An enumeration. */
export enum SalesPackageLevelChoices {
  Level1 = 'LEVEL1'
}

export type SalesPackageType = {
  __typename?: 'SalesPackageType';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: SalescatalogsSalesPackageLevelChoices;
  name: Scalars['String']['output'];
  salesPrices: Array<SalesPriceType>;
  salescontractversionSet: Array<SalesContractVersionType>;
  salesextraserviceSet: Array<SalesExtraServiceType>;
};

export type SalesPriceType = {
  __typename?: 'SalesPriceType';
  customerPricePerMonth: Scalars['Decimal']['output'];
  discountType?: Maybe<SalescatalogsSalesPriceDiscountTypeChoices>;
  id: Scalars['ID']['output'];
  salesCatalog: SalesCatalogType;
  salesExtraService?: Maybe<SalesExtraServiceType>;
  salesPackage?: Maybe<SalesPackageType>;
  userPricePerMonth: Scalars['Decimal']['output'];
};

/** An enumeration. */
export enum SalescatalogsDiscountDiscountTypeChoices {
  /** Extra Services */
  ExtraServices = 'EXTRA_SERVICES',
  /** Health Care Reception */
  HealthCareReception = 'HEALTH_CARE_RECEPTION',
  /** Sales Package */
  SalesPackage = 'SALES_PACKAGE'
}

/** An enumeration. */
export enum SalescatalogsSalesCatalogStateChoices {
  /** Luonnos */
  Draft = 'DRAFT',
  /** Lukittu */
  Locked = 'LOCKED'
}

/** An enumeration. */
export enum SalescatalogsSalesExtraServiceGroupChoices {
  /** Extra services */
  ExtraServices = 'EXTRA_SERVICES',
  /** Other medical care services */
  OtherMedicalCareServices = 'OTHER_MEDICAL_CARE_SERVICES'
}

/** An enumeration. */
export enum SalescatalogsSalesExtraServiceProductChoices {
  /** Extra service package */
  ExtraServicePackage = 'EXTRA_SERVICE_PACKAGE',
  /** Extra service package with coaching */
  ExtraServicePackageWithCoaching = 'EXTRA_SERVICE_PACKAGE_WITH_COACHING',
  /** Mental wellness coverage */
  MentalWellnessCoverage = 'MENTAL_WELLNESS_COVERAGE',
  /** Other medical care services - no sick leaves self declaration */
  OtherMedicalCareServicesNoSickLeavesSelfDeclaration = 'OTHER_MEDICAL_CARE_SERVICES_NO_SICK_LEAVES_SELF_DECLARATION',
  /** Other medical care services with sick leaves self declaration */
  OtherMedicalCareServicesWithSickLeavesSelfDeclaration = 'OTHER_MEDICAL_CARE_SERVICES_WITH_SICK_LEAVES_SELF_DECLARATION',
  /** Serious illness coverage */
  SeriousIllnessCoverage = 'SERIOUS_ILLNESS_COVERAGE'
}

/** An enumeration. */
export enum SalescatalogsSalesPackageLevelChoices {
  /** Service level 1 */
  Level1 = 'LEVEL1'
}

/** An enumeration. */
export enum SalescatalogsSalesPriceDiscountTypeChoices {
  /** Extra Services */
  ExtraServices = 'EXTRA_SERVICES',
  /** Health Care Reception */
  HealthCareReception = 'HEALTH_CARE_RECEPTION',
  /** Sales Package */
  SalesPackage = 'SALES_PACKAGE'
}

/** An enumeration. */
export enum SalescontractsSalesContractDocumentChangeTypeChoices {
  /** Sopimus allekirjoitettu */
  ContractSigned = 'CONTRACT_SIGNED',
  /** Sopimus irtisanottu */
  ContractTerminated = 'CONTRACT_TERMINATED',
  /** Ihmisen tekemä muutos */
  HumanMadeChange = 'HUMAN_MADE_CHANGE',
  /** Tarjous luotu */
  OfferCreated = 'OFFER_CREATED',
  /** Lähetetty allekirjoitettavaksi */
  SentForSignature = 'SENT_FOR_SIGNATURE',
  /** Järjestelmän tekemä muutos */
  SystemMadeChange = 'SYSTEM_MADE_CHANGE'
}

/** An enumeration. */
export enum SalescontractsSalesContractVersionReceptionChoiceChoices {
  /** Local and remote reception */
  LocalAndRemote = 'LOCAL_AND_REMOTE',
  /** Only remote reception */
  RemoteOnly = 'REMOTE_ONLY'
}

export type SaveAnswers = {
  __typename?: 'SaveAnswers';
  treeResult?: Maybe<TreeResultType>;
};

export type SendDocumentToKanta = {
  __typename?: 'SendDocumentToKanta';
  documentSent?: Maybe<Scalars['String']['output']>;
};

export type SendPasswordResetEmail = {
  __typename?: 'SendPasswordResetEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Mutation which send prescription repudiations to Kanta. */
export type SendPrescriptionRepudiations = {
  __typename?: 'SendPrescriptionRepudiations';
  /** Oid codes of the documents which repudiation failed. */
  failedToSendOids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Failure reasons for each failed document. */
  failureReasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Succesfully sent document oid codes */
  succesfullySentOids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SendUserToAcute = {
  __typename?: 'SendUserToAcute';
  user: UserType;
};

export type ServiceEvent = {
  __typename?: 'ServiceEvent';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  laboratoryorderSet: Array<LaboratoryOrderType>;
  officeOrderNumber: Scalars['Int']['output'];
  oid: Scalars['String']['output'];
  organization: Organization;
  patient: PatientType;
  status: ServiceeventsServiceEventStatusChoices;
};

export enum ServiceEventStatusInputType {
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

/** An enumeration. */
export enum ServiceeventsServiceEventStatusChoices {
  /** Closed */
  Closed = 'CLOSED',
  /** In progress */
  InProgress = 'IN_PROGRESS',
  /** Not started */
  NotStarted = 'NOT_STARTED'
}

export type SetCondition = {
  __typename?: 'SetCondition';
  branch?: Maybe<BranchType>;
};

export type SetPermissionsEqual = {
  __typename?: 'SetPermissionsEqual';
  profile?: Maybe<ProfileType>;
};

export type SickLeaveCategoryIcd10Type = {
  __typename?: 'SickLeaveCategoryIcd10Type';
  icd10: Icd10Type;
  id: Scalars['ID']['output'];
  sickLeaveCategory: SickLeaveCategoryType;
};

export type SickLeaveCategoryType = {
  __typename?: 'SickLeaveCategoryType';
  icd10s: Array<Icd10Type>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  selfReportReasons: Array<SickLeaveSelfReportReasonType>;
  sickleavecategoryicd10Set: Array<SickLeaveCategoryIcd10Type>;
};

export type SickLeaveNoticeType = {
  __typename?: 'SickLeaveNoticeType';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  acceptedBy?: Maybe<UserType>;
  categoryIcd10?: Maybe<Icd10Type>;
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  reason?: Maybe<SickLeaveSelfReportReasonType>;
  startDate: Scalars['Date']['output'];
  type: SickLeaveTypeType;
  user: UserType;
};

export type SickLeaveNoticeTypeAndReasonType = {
  __typename?: 'SickLeaveNoticeTypeAndReasonType';
  icd10Code?: Maybe<Icd10Type>;
  sickLeaveReason?: Maybe<SickLeaveSelfReportReasonType>;
  sickLeaveType: SickLeaveTypeType;
};

export type SickLeavePeriodSettingType = {
  __typename?: 'SickLeavePeriodSettingType';
  allowedSelfReportPeriodMonths: Scalars['Int']['output'];
  allowedSelfReportTimesPerPeriod: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  sickLeaveSetting: SickLeaveSettingsCustomerType;
};

export type SickLeaveSelfReportReasonType = {
  __typename?: 'SickLeaveSelfReportReasonType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sickLeaveCategory?: Maybe<SickLeaveCategoryType>;
  sickLeaveSettingsCustomers: Array<SickLeaveSettingsCustomerType>;
  sickLeaves: Array<SickLeaveNoticeType>;
};

export type SickLeaveSettingsCustomerType = {
  __typename?: 'SickLeaveSettingsCustomerType';
  allowedSelfReportDurationContinuously: Scalars['Int']['output'];
  allowedSelfReportDurationPerNotification: Scalars['Int']['output'];
  allowedSelfReportPeriodSettings: Array<SickLeavePeriodSettingType>;
  allowedSelfReportReasons: Array<SickLeaveSelfReportReasonType>;
  customer: CustomerType;
};

export type SickLeaveTypeType = {
  __typename?: 'SickLeaveTypeType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reason: SickleavesSickLeaveTypeReasonChoices;
  sickLeaves: Array<SickLeaveNoticeType>;
};

/** An enumeration. */
export enum SickleavesSickLeaveTypeReasonChoices {
  /** Icd10 */
  Icd10 = 'ICD10',
  /** None */
  None = 'NONE',
  /** Personal */
  Personal = 'PERSONAL'
}

export enum SiteType {
  DoctorBackend = 'DOCTOR_BACKEND',
  MainBackend = 'MAIN_BACKEND'
}

export type SmartCardLogin = {
  __typename?: 'SmartCardLogin';
  loggedIn: Scalars['Boolean']['output'];
};

export type SmartCardLogout = {
  __typename?: 'SmartCardLogout';
  loggedOut: Scalars['Boolean']['output'];
};

/** Value belongs to codesystem 1.2.246.537.6.850 values. */
export type SpecialClarification = {
  __typename?: 'SpecialClarification';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  dateExplanation?: Maybe<Scalars['String']['output']>;
  dateRequired: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  guidance?: Maybe<Scalars['String']['output']>;
  kelaGuidanceUrl?: Maybe<Scalars['String']['output']>;
};

export type SpecialClarificationInfo = {
  __typename?: 'SpecialClarificationInfo';
  /** Codesystem 1.2.246.537.6.850 code. */
  clarification: SpecialClarification;
  compensationClass: Scalars['String']['output'];
  disease?: Maybe<Scalars['String']['output']>;
};

export type SpecialClarificationInput = {
  /** Value should exist in codeserver 1.2.246.537.6.850 codes. Selected value should be allowed for the assigned drug. */
  code: Scalars['String']['input'];
  /** Date related to special clarification code. Should be defined if selected code requires date. (Required status can be determined from codeserver) */
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type StartCampaign = {
  __typename?: 'StartCampaign';
  campaign?: Maybe<CampaignType>;
};

/** An enumeration. */
export enum StatementGroupGroupTypeChoices {
  And = 'AND',
  Or = 'OR'
}

export type StatementGroupInput = {
  groupType: StatementGroupGroupTypeChoices;
  statements: Array<InputMaybe<StatementInput>>;
};

export type StatementGroupType = {
  __typename?: 'StatementGroupType';
  condition?: Maybe<ConditionType>;
  createdAt: Scalars['DateTime']['output'];
  documenttemplateitem?: Maybe<DocumentTemplateItemType>;
  groupType: StatementsStatementGroupGroupTypeChoices;
  id: Scalars['ID']['output'];
  statements: Array<StatementType>;
};

export type StatementInput = {
  compare: Scalars['String']['input'];
  operator: StatementOperatorChoices;
  variableKey: Scalars['String']['input'];
};

/** An enumeration. */
export enum StatementOperatorChoices {
  Equal = 'EQUAL',
  Greater = 'GREATER',
  Greaterorequal = 'GREATEROREQUAL',
  Less = 'LESS',
  Lessorequal = 'LESSOREQUAL',
  Nonequal = 'NONEQUAL'
}

export type StatementType = {
  __typename?: 'StatementType';
  compare: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  group?: Maybe<StatementGroupType>;
  id: Scalars['ID']['output'];
  operator: StatementsStatementOperatorChoices;
  variableKey: Scalars['String']['output'];
};

/** An enumeration. */
export enum StatementsStatementGroupGroupTypeChoices {
  /** And */
  And = 'AND',
  /** Or */
  Or = 'OR'
}

/** An enumeration. */
export enum StatementsStatementOperatorChoices {
  /** Equal with */
  Equal = 'EQUAL',
  /** Greater than */
  Greater = 'GREATER',
  /** Greater or equal with */
  Greaterorequal = 'GREATEROREQUAL',
  /** Less than */
  Less = 'LESS',
  /** Less or equal with */
  Lessorequal = 'LESSOREQUAL',
  /** Non-equal with */
  Nonequal = 'NONEQUAL'
}

export type SubtitleInputType = {
  show?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SubtitleType = {
  __typename?: 'SubtitleType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  show?: Maybe<Scalars['Boolean']['output']>;
  title: TitleType;
  value: Scalars['String']['output'];
};

export type SupplierAndPrice = {
  price: Scalars['Decimal']['input'];
  supplierId: Scalars['ID']['input'];
};

export type SupplierPackagePriceType = {
  __typename?: 'SupplierPackagePriceType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mainTest: Scalars['Int']['output'];
  price: Scalars['Decimal']['output'];
  supplier: LaboratorySupplierType;
  supplierPackage: SupplierPackageType;
};

export type SupplierPackageTestType = {
  __typename?: 'SupplierPackageTestType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  laboratoryTest: LaboratoryTestType;
  order: Scalars['Int']['output'];
  supplierPackage: SupplierPackageType;
};

export type SupplierPackageType = {
  __typename?: 'SupplierPackageType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  norrepackagetestSet: Array<NorrePackageTestType>;
  salesPrices: Array<LaboratoryCatalogSalesPriceType>;
  supplierpackagepriceSet: Array<SupplierPackagePriceType>;
  supplierpackagetestSet: Array<SupplierPackageTestType>;
};

export type SupplierTestPriceType = {
  __typename?: 'SupplierTestPriceType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  laboratoryTest: LaboratoryTestType;
  price: Scalars['Decimal']['output'];
  supplier: LaboratorySupplierType;
};

/** An enumeration. */
export enum TaskRecurrenceInterval {
  EveryXYear = 'EVERY_X_YEAR',
  TimesAYear = 'TIMES_A_YEAR'
}

/** An enumeration. */
export enum TaskScheduling {
  Due = 'DUE',
  Range = 'RANGE'
}

/** An enumeration. */
export enum TaskState {
  Done = 'DONE',
  New = 'NEW',
  Ongoing = 'ONGOING'
}

export type TaskTopicType = {
  __typename?: 'TaskTopicType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  taskSet: Array<TaskType>;
  title: Scalars['String']['output'];
};

export type TaskType = {
  __typename?: 'TaskType';
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerType;
  dateEnd?: Maybe<Scalars['Date']['output']>;
  dateStart: Scalars['Date']['output'];
  descriptionExternal: Scalars['String']['output'];
  descriptionInternal: Scalars['String']['output'];
  documentCategories: Array<DocumentCategoryType>;
  documents: Array<DocumentType>;
  /** Duration in minutes */
  durationCompleted?: Maybe<Scalars['Int']['output']>;
  /** Duration in minutes */
  durationEstimate?: Maybe<Scalars['Int']['output']>;
  healthcareTeam?: Maybe<HealthcareTeamType>;
  id: Scalars['ID']['output'];
  isRecurring: Scalars['Boolean']['output'];
  professionalGroup?: Maybe<ProfessionalGroupType>;
  recurrence: Scalars['Int']['output'];
  recurrenceInterval: TasksTaskRecurrenceIntervalChoices;
  scheduling: TasksTaskSchedulingChoices;
  state: TasksTaskStateChoices;
  taskParent?: Maybe<TaskType>;
  taskTopics: Array<TaskTopicType>;
  tasksChildren: Array<TaskType>;
  timeEnd?: Maybe<Scalars['Time']['output']>;
  timeStart?: Maybe<Scalars['Time']['output']>;
  title: Scalars['String']['output'];
  userCreatedBy: UserType;
  userOwner?: Maybe<UserType>;
  userPatient?: Maybe<UserType>;
};

/** An enumeration. */
export enum TasksTaskRecurrenceIntervalChoices {
  /** Every X Year */
  EveryXYear = 'EVERY_X_YEAR',
  /** Times A Year */
  TimesAYear = 'TIMES_A_YEAR'
}

/** An enumeration. */
export enum TasksTaskSchedulingChoices {
  /** Due */
  Due = 'DUE',
  /** Range */
  Range = 'RANGE'
}

/** An enumeration. */
export enum TasksTaskStateChoices {
  /** Done */
  Done = 'DONE',
  /** New */
  New = 'NEW',
  /** Ongoing */
  Ongoing = 'ONGOING'
}

/** Value belongs to codesystem 1.2.246.537.6.12.999 values. */
export type TechnicalStructureType = {
  __typename?: 'TechnicalStructureType';
  OID?: Maybe<Scalars['String']['output']>;
  abbreviation: Scalars['String']['output'];
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
};

export type TerminateContract = {
  __typename?: 'TerminateContract';
  salesContractDocument: SalesContractDocumentType;
};

export type TestAndOrder = {
  codeId: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
};

export type TimePeriodArguments = {
  /** Specifies unit of time period. For example MONTH. */
  durationUnit: TimeUnit;
  /** Specifies value of time period. */
  durationValue: Scalars['Float']['input'];
};

export type TimePeriodState = {
  __typename?: 'TimePeriodState';
  /** Specifies unit of time period. For example MONTH. */
  durationUnit: TimeUnit;
  /** Specifies value of time period. */
  durationValue: Scalars['Float']['output'];
};

/** An enumeration. */
export enum TimeUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type TitleInputType = {
  careReasonChoices?: InputMaybe<Array<InputMaybe<SubtitleInputType>>>;
  findingsAndStudies?: InputMaybe<Array<InputMaybe<SubtitleInputType>>>;
  treatmentEvaluation?: InputMaybe<Array<InputMaybe<SubtitleInputType>>>;
  treatmentImplementation?: InputMaybe<Array<InputMaybe<SubtitleInputType>>>;
  treatmentPlanning?: InputMaybe<Array<InputMaybe<SubtitleInputType>>>;
  unclassifiedTitles?: InputMaybe<Array<InputMaybe<SubtitleInputType>>>;
};

export type TitleTemplateType = {
  __typename?: 'TitleTemplateType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  public?: Maybe<Scalars['Boolean']['output']>;
  titles: Array<TitleType>;
  user?: Maybe<UserType>;
};

export type TitleType = {
  __typename?: 'TitleType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subtitles: Array<SubtitleType>;
  template: TitleTemplateType;
};

/** Value belongs to codesystem 1.2.246.537.6.112 values. */
export type ToBeOrNotToBe = {
  __typename?: 'ToBeOrNotToBe';
  code: Scalars['String']['output'];
  codeSystem?: Maybe<CodeSystemType>;
  displayName: Scalars['String']['output'];
};

export type TotalAmountArguments = {
  /** Specifies assigned amount value */
  quantity: Scalars['Float']['input'];
  /** Specifies assigned amount unit */
  unit: Scalars['String']['input'];
};

export type TotalAmountInfo = {
  __typename?: 'TotalAmountInfo';
  amount?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type TotalAmountState = {
  __typename?: 'TotalAmountState';
  /** Specifies assigned amount value */
  quantity: Scalars['Float']['output'];
  /** Specifies assigned amount unit */
  unit: Scalars['String']['output'];
};

export type TreeDataType = {
  __typename?: 'TreeDataType';
  branches: Array<BranchType>;
  id: Scalars['ID']['output'];
  nodes: Array<NodeType>;
  tree: TreeType;
};

export type TreeInfoDataType = {
  __typename?: 'TreeInfoDataType';
  treeId: Scalars['ID']['output'];
  treeName: Scalars['String']['output'];
};

export type TreeResultType = {
  __typename?: 'TreeResultType';
  answers?: Maybe<Scalars['String']['output']>;
  campaignResult?: Maybe<CampaignType>;
  createdAt: Scalars['DateTime']['output'];
  expires: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastAnswerAt?: Maybe<Scalars['DateTime']['output']>;
  nextNode?: Maybe<NodeType>;
  open: Scalars['Boolean']['output'];
  survey: TreeType;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
};

export type TreeType = {
  __typename?: 'TreeType';
  answerSources: Array<Scalars['String']['output']>;
  answers: Array<TreeResultType>;
  campaignSurveys: Array<CampaignSurveyType>;
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  documents: Array<DocumentTemplateType>;
  endText: Scalars['String']['output'];
  endTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalDescription: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rootNode: NodeType;
  welcomeText: Scalars['String']['output'];
  welcomeTitle: Scalars['String']['output'];
};

export type UpdateCampaign = {
  __typename?: 'UpdateCampaign';
  campaign?: Maybe<CampaignType>;
};

export type UpdateCompanyAndSetAddresses = {
  __typename?: 'UpdateCompanyAndSetAddresses';
  company?: Maybe<CompanyType>;
};

export type UpdateCustomer = {
  __typename?: 'UpdateCustomer';
  customer?: Maybe<CustomerType>;
};

export type UpdateCustomerInsurances = {
  __typename?: 'UpdateCustomerInsurances';
  customer: CustomerType;
};

export type UpdateCustomerInvoicingInformation = {
  __typename?: 'UpdateCustomerInvoicingInformation';
  customer: CustomerType;
};

export type UpdateCustomerServiceSettings = {
  __typename?: 'UpdateCustomerServiceSettings';
  customerServiceSettings?: Maybe<CustomerServiceSettingsType>;
};

export type UpdateDocument = {
  __typename?: 'UpdateDocument';
  document?: Maybe<DocumentType>;
};

export type UpdateDocumentCategory = {
  __typename?: 'UpdateDocumentCategory';
  documentCategory?: Maybe<DocumentCategoryType>;
};

export type UpdateDocumentTemplate = {
  __typename?: 'UpdateDocumentTemplate';
  template?: Maybe<DocumentTemplateType>;
};

export type UpdateDocumentTemplateItem = {
  __typename?: 'UpdateDocumentTemplateItem';
  item?: Maybe<DocumentTemplateItemType>;
};

export type UpdateHealthcareTeam = {
  __typename?: 'UpdateHealthcareTeam';
  healthcareTeam?: Maybe<HealthcareTeamType>;
};

export type UpdateInsuranceCompany = {
  __typename?: 'UpdateInsuranceCompany';
  insuranceCompany: InsuranceCompanyType;
};

export type UpdateInvoiceInterface = {
  __typename?: 'UpdateInvoiceInterface';
  invoiceInterface?: Maybe<InvoiceInterfaceType>;
};

export type UpdateInvoicePurchase = {
  __typename?: 'UpdateInvoicePurchase';
  invoicePurchase?: Maybe<InvoicePurchaseType>;
};

export type UpdateInvoicePurchaseRow = {
  __typename?: 'UpdateInvoicePurchaseRow';
  invoicePurchaseRow?: Maybe<InvoicePurchaseRowType>;
};

export type UpdateLaboratoryCatalog = {
  __typename?: 'UpdateLaboratoryCatalog';
  laboratoryCatalog?: Maybe<LaboratoryCatalogType>;
};

export type UpdateLaboratorySalesPriceAvailability = {
  __typename?: 'UpdateLaboratorySalesPriceAvailability';
  laboratoryCatalogSalesPrice?: Maybe<LaboratoryCatalogSalesPriceType>;
};

export type UpdateLaboratorySupplier = {
  __typename?: 'UpdateLaboratorySupplier';
  laboratorySupplier?: Maybe<LaboratorySupplierType>;
};

export type UpdateLaboratoryTest = {
  __typename?: 'UpdateLaboratoryTest';
  laboratoryTest?: Maybe<LaboratoryTestType>;
};

export type UpdateMyPassword = {
  __typename?: 'UpdateMyPassword';
  id?: Maybe<Scalars['ID']['output']>;
};

export type UpdateNode = {
  __typename?: 'UpdateNode';
  node: NodeType;
};

export type UpdateNorrePackage = {
  __typename?: 'UpdateNorrePackage';
  norrePackage?: Maybe<NorrePackageType>;
};

export type UpdateOwnPersonalInformation = {
  __typename?: 'UpdateOwnPersonalInformation';
  user: UserType;
};

export type UpdatePartner = {
  __typename?: 'UpdatePartner';
  partner?: Maybe<PartnerType>;
};

export type UpdatePermissions = {
  __typename?: 'UpdatePermissions';
  profile?: Maybe<ProfileType>;
};

export type UpdateProcedure = {
  __typename?: 'UpdateProcedure';
  procedure?: Maybe<ProcedureType>;
};

export type UpdateProcedurePartnerPrice = {
  __typename?: 'UpdateProcedurePartnerPrice';
  procedurePartnerPrice?: Maybe<ProcedurePartnerPriceType>;
};

export type UpdateProfessionalGroup = {
  __typename?: 'UpdateProfessionalGroup';
  professionalGroup?: Maybe<ProfessionalGroupType>;
};

export type UpdateProfileName = {
  __typename?: 'UpdateProfileName';
  profile: ProfileType;
};

export type UpdateQuestion = {
  __typename?: 'UpdateQuestion';
  question: QuestionType;
};

export type UpdateSalesCatalog = {
  __typename?: 'UpdateSalesCatalog';
  salesCatalog?: Maybe<SalesCatalogType>;
};

export type UpdateSalesPrice = {
  __typename?: 'UpdateSalesPrice';
  salesPrice?: Maybe<SalesPriceType>;
};

export type UpdateSickLeaveCategory = {
  __typename?: 'UpdateSickLeaveCategory';
  sickLeaveCategory?: Maybe<SickLeaveCategoryType>;
};

export type UpdateSickLeaveNotice = {
  __typename?: 'UpdateSickLeaveNotice';
  sickLeaveNotice?: Maybe<SickLeaveNoticeType>;
};

export type UpdateSickLeavePeriodSetting = {
  __typename?: 'UpdateSickLeavePeriodSetting';
  sickLeavePeriodSetting?: Maybe<SickLeavePeriodSettingType>;
};

export type UpdateSickLeaveSelfReportReason = {
  __typename?: 'UpdateSickLeaveSelfReportReason';
  sickLeaveSelfReportReason?: Maybe<SickLeaveSelfReportReasonType>;
};

export type UpdateSickLeaveSettingsCustomer = {
  __typename?: 'UpdateSickLeaveSettingsCustomer';
  sickLeaveSettingsCustomer?: Maybe<SickLeaveSettingsCustomerType>;
};

export type UpdateSupplierPackage = {
  __typename?: 'UpdateSupplierPackage';
  supplierPackage?: Maybe<SupplierPackageType>;
};

export type UpdateTask = {
  __typename?: 'UpdateTask';
  task: TaskType;
};

export type UpdateTree = {
  __typename?: 'UpdateTree';
  tree?: Maybe<TreeType>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  user: UserType;
};

export type UpdateUserInsurances = {
  __typename?: 'UpdateUserInsurances';
  user: UserType;
};

export type UpdateUserRoles = {
  __typename?: 'UpdateUserRoles';
  user: UserType;
};

export type UpdateView = {
  __typename?: 'UpdateView';
  view: ViewType;
};

export type UserContactInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  homeTown?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberJob?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
};

export type UserContactPersonType = {
  __typename?: 'UserContactPersonType';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  user: UserType;
};

export type UserEmailPhoneNumberReturnType = {
  __typename?: 'UserEmailPhoneNumberReturnType';
  emails: Array<Maybe<Scalars['String']['output']>>;
  phoneNumbers: Array<Maybe<Scalars['String']['output']>>;
};

export type UserEmploymentInformationInput = {
  companyAddressId?: InputMaybe<Scalars['ID']['input']>;
  customerAddressId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  endReason?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UserEmploymentInformationType = {
  __typename?: 'UserEmploymentInformationType';
  companyAddress?: Maybe<CompanyAddressType>;
  customerAddress?: Maybe<CustomerAddressType>;
  endDate?: Maybe<Scalars['Date']['output']>;
  endReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  user: UserType;
};

export type UserFileUploadFieldsInputType = {
  accidentInsuranceInfo?: InputMaybe<CustomerUserAccidentInsuranceInfoInput>;
  additionalInfo?: InputMaybe<CustomerUserAdditionalInfo>;
  contactInfo?: InputMaybe<UserContactInfoInput>;
  employmentInfo?: InputMaybe<UserEmploymentInformationInput>;
  medicalInsuranceInfo?: InputMaybe<CustomerUserMedicalInsuranceInfoInput>;
  personalInfo?: InputMaybe<UserPersonalInfoInput>;
  profileInfo?: InputMaybe<ProfileInfoInput>;
};

export type UserHealthCheckInformationType = {
  __typename?: 'UserHealthCheckInformationType';
  nextCheckDate?: Maybe<Scalars['Date']['output']>;
  previousCheckUnknown: Scalars['Boolean']['output'];
  user: UserType;
};

export type UserInformationViewLogType = {
  __typename?: 'UserInformationViewLogType';
  id: Scalars['ID']['output'];
  otherReason: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  viewReason: CustomerInformationViewReasonType;
  viewedBy: UserType;
  viewedUser: UserType;
};

export type UserOrError = {
  __typename?: 'UserOrError';
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type UserOwnPersonalInformationInputType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactPerson?: InputMaybe<CustomerUserContactPersonInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homeTown?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberJob?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['String']['input']>;
};

export type UserPersonalInfoInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['String']['input']>;
};

export type UserRoleCompanyBaseInfo = {
  __typename?: 'UserRoleCompanyBaseInfo';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UserRoleCustomerBaseInfo = {
  __typename?: 'UserRoleCustomerBaseInfo';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UserSyncLogType = {
  __typename?: 'UserSyncLogType';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserType>;
  data: Scalars['String']['output'];
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: Scalars['String']['output'];
  response: Scalars['String']['output'];
  responseStatusCode: Scalars['String']['output'];
  system: UsersUserSyncLogSystemChoices;
  url: Scalars['String']['output'];
  user?: Maybe<UserType>;
};

export type UserType = {
  __typename?: 'UserType';
  acceptedSickLeaves: Array<SickLeaveNoticeType>;
  acknowledgments: Array<AcknowledgmentType>;
  address: Scalars['String']['output'];
  benefitsRestrictedUntil?: Maybe<Scalars['Date']['output']>;
  benefitsRestrictionCustomReason?: Maybe<Scalars['String']['output']>;
  benefitsRestrictionReason?: Maybe<UsersUserBenefitsRestrictionReasonChoices>;
  campaignSurveys: Array<CampaignSurveyType>;
  city: Scalars['String']['output'];
  closestSupervisor?: Maybe<UserType>;
  company?: Maybe<CompanyType>;
  contactPerson?: Maybe<UserContactPersonType>;
  contactPersons: Array<ContactPersonType>;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdCampaigns: Array<CampaignType>;
  currentEmploymentInfo?: Maybe<UserEmploymentInformationType>;
  customer?: Maybe<CustomerType>;
  dateJoined: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  department?: Maybe<DepartmentType>;
  directSubordinates: Array<UserType>;
  documentSet: Array<DocumentType>;
  documentversionSet: Array<DocumentVersionType>;
  email?: Maybe<Scalars['String']['output']>;
  employeeGroup?: Maybe<EmployeeGroupType>;
  employerName?: Maybe<Scalars['String']['output']>;
  employmentInformation: Array<UserEmploymentInformationType>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  hasExposure: Scalars['Boolean']['output'];
  healthCheckInformation?: Maybe<UserHealthCheckInformationType>;
  healthChecks: Array<HealthCheckType>;
  healthcareteams: Array<HealthcareTeamType>;
  homeTown: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idAcute: Scalars['String']['output'];
  insurances: Array<InsuranceType>;
  invoicepurchaseSet: Array<InvoicePurchaseType>;
  invoicepurchasecommentSet: Array<InvoicePurchaseCommentType>;
  isActive: Scalars['Boolean']['output'];
  isHealthcareWorker: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  isSupplier: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  kelaTwoRestrictionReason?: Maybe<UsersUserKelaTwoRestrictionReasonChoices>;
  laboratorytestSet: Array<LaboratoryTestType>;
  language: UsersUserLanguageChoices;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  nationalIdKnown: Scalars['Boolean']['output'];
  nickName?: Maybe<Scalars['String']['output']>;
  officeDescription?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  paymentcommitmentSet: Array<PaymentCommitmentType>;
  phoneNumber: Scalars['String']['output'];
  phoneNumberJob: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
  professionalGroup?: Maybe<ProfessionalGroupType>;
  responsibleDoctorCustomers: Array<CustomerType>;
  responsibleNurseCustomers: Array<CustomerType>;
  rolesCompany: Array<RoleCompanyType>;
  rolesCorporate: Array<RoleCorporateType>;
  rolesCustomer: Array<RoleCustomerType>;
  salescontractversionSet: Array<SalesContractVersionType>;
  sex?: Maybe<UsersUserSexChoices>;
  sickLeaves: Array<SickLeaveNoticeType>;
  supervisor: Scalars['Boolean']['output'];
  supervisorName?: Maybe<Scalars['String']['output']>;
  surveyAnswers: Array<TreeResultType>;
  tasksOwned: Array<TaskType>;
  tasksPatient: Array<TaskType>;
  titletemplates: Array<TitleTemplateType>;
  usersynclogSet: Array<UserSyncLogType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  views: Array<ViewType>;
};

/** An enumeration. */
export enum UsersUserBenefitsRestrictionReasonChoices {
  /** muu */
  Custom = 'CUSTOM',
  /** perhevapaa */
  FamilyLeave = 'FAMILY_LEAVE',
  /** lomautus */
  LayOff = 'LAY_OFF',
  /** koeaika */
  Probation = 'PROBATION',
  /** työsuhteen lyhytaikaisuus */
  ServiceTime = 'SERVICE_TIME',
  /** vuorotteluvapaa */
  WorkRotation = 'WORK_ROTATION'
}

/** An enumeration. */
export enum UsersUserKelaTwoRestrictionReasonChoices {
  /** perhevapaa */
  FamilyLeave = 'FAMILY_LEAVE',
  /** vuorotteluvapaa */
  WorkRotation = 'WORK_ROTATION'
}

/** An enumeration. */
export enum UsersUserLanguageChoices {
  /** Englanti */
  En = 'EN',
  /** Suomi */
  Fi = 'FI'
}

/** An enumeration. */
export enum UsersUserSexChoices {
  /** nainen */
  Female = 'FEMALE',
  /** mies */
  Male = 'MALE'
}

/** An enumeration. */
export enum UsersUserSyncLogSystemChoices {
  /** Acute */
  Acute = 'ACUTE'
}

export type ViewType = {
  __typename?: 'ViewType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  permissions: Array<PermissionType>;
  title: Scalars['String']['output'];
  users: Array<UserType>;
  viewType: ViewsViewViewTypeChoices;
};

/** An enumeration. */
export enum ViewViewTypeChoices {
  Corporateadmin = 'CORPORATEADMIN',
  Customeradmin = 'CUSTOMERADMIN',
  Healthcare = 'HEALTHCARE',
  Regionaladmin = 'REGIONALADMIN',
  Supplier = 'SUPPLIER'
}

/** An enumeration. */
export enum ViewsViewViewTypeChoices {
  /** Corporate admin */
  Corporateadmin = 'CORPORATEADMIN',
  /** Customer admin */
  Customeradmin = 'CUSTOMERADMIN',
  /** Healthcare */
  Healthcare = 'HEALTHCARE',
  /** Regional admin */
  Regionaladmin = 'REGIONALADMIN',
  /** Supplier */
  Supplier = 'SUPPLIER'
}
